import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
//PS_22
import { ThemeProvider } from '../components/ThemeContext';
import { Theme } from '../enums/enums';

function App() {

  return (
    <>
{/* PS_23 Bind Root div inside ThemeProvider with the value of "theme" from the session storage to "themetype" attribute    
    */}
      <ThemeProvider themeType={localStorage.getItem("theme") == "Dark" ? Theme.DARK : Theme.LIGHT}>
        <BrowserRouter>
          <AppRoutes></AppRoutes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
