import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import { RoleEnum } from '../../enums/enums';
import { PasswordInfo, TMSConfiguration } from '../../interface/Interface';
import { getUserInfo, retrieveTMSDetails } from '../../service/Api';
import Loader from '../Resuables/Loader';
import { SomethingWentWrong } from '../Resuables/SomethingWentWrong';
import {useNavigate} from 'react-router-dom'

const ProfilePasswordReset: React.FC<{ tmsId: string ,openToast:any}> = ({ tmsId, openToast }) => {
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState<string>('password');
    const [newPass, setShowNewPass] = useState<string>('password')
    const [oldPassError, setOldPass] = useState<string>('')
    const [loader, setLoader] = useState(false);
    let [UserPassword, setUserPassword] = useState<PasswordInfo>({
        oldPassword: '',
        newPassword: '',
    })
    let [newpassError, setNewPassError] = useState<boolean>(false)
    let [errorMessage, setErrorMessage] = useState<PasswordInfo>({
        oldPassword: '',
        newPassword: '',
    })
    const defaultTMSConfiguration: TMSConfiguration = {
        name: '',
        status: '',
        access_token_type: '',
        token:'',
        api_validity_utc: '',
        system_configuration: {
            preferred_vendor: '',
            preferred_tracking_method: '',
            preferred_communication_platform: '',
            tracking_event_notify_hours: 0,
            second_tier_notify_hours: 0,
            support_escalation_hours: 0,
            eld_check_interval_hours: 0,
            driver_app_check_interval_hours: 0,
            location_provider_check_interval: 0,
            location_provider_quiet_hrs_start_time: '',
            location_provider_quiet_hrs_end_time: '',
        },
        webhook_configuration: {
            auth_type: '',
            identifier: '',
            secret: '',
            token: '',
            refresh_token: '',
            token_expiry: '',
            token_endpoint: '',
            load_event_update_url: '',
            driver_message_update_url: '',
            test_connection_url: '',
        },
        users: 
            {
                user_name: '',
                user_type: '',
                email_address: '',
                phone_number: '',
                country_code: '1'
            },
            delay_reasons: [],

    };

    let [tmsConfiguration, setTMSConfiguration] = useState<TMSConfiguration>(defaultTMSConfiguration)
    const [openSomethingWentWrongPopup, setOpenSomethingWentWrongPopup] = useState(false);

    useEffect(() => {
        // PS_PR_1.115-Get userRole from accessToken      
            getUserinfo(tmsId); // Call getUserinfo without userId
    }, []);
    const getUserinfo = async (userId?: string | undefined) => {
        // PS_PR_1.111-PS_PR_1.123
        try {
            setLoader(true)
            const id = userId ? userId : ""
            const response = await retrieveTMSDetails(id);
            if (response.data.code === 200) {
                setTMSConfiguration(response.data.data);
                setLoader(false)

            }
            else {
                setLoader(false)

                setOpenSomethingWentWrongPopup(true);
                setTMSConfiguration(defaultTMSConfiguration)
            }
            setLoader(false)

        }

        catch {
            setLoader(false)

            setOpenSomethingWentWrongPopup(true);
            setTMSConfiguration(defaultTMSConfiguration)
        }
    }

    const handleInputChange = () => {
        setOldPass('')
        setNewPassError(false)
        // Validate old password is not empty
        if (UserPassword.oldPassword.trim() === '') {
            errorMessage.oldPassword = "Please enter your old password";
            setErrorMessage(prevState => ({
                ...prevState!,
                oldPassword: errorMessage.oldPassword
            }));
        } else {
            errorMessage.oldPassword = "";
            setErrorMessage(prevState => ({
                ...prevState!,
                oldPassword: errorMessage.oldPassword
            }));
        }
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
        // Validate new password is not empty
        if (UserPassword.newPassword.trim() === '') {
            errorMessage.newPassword = "Please enter your new password";
            setErrorMessage(prevState => ({
                ...prevState!,
                newPassword: errorMessage.newPassword
            }));
        } else if (!regex.test(UserPassword.newPassword.trim())) {
            // Validate new password against regex
            errorMessage.newPassword = "";
            setErrorMessage(prevState => ({
                ...prevState!,
                newPassword: errorMessage.newPassword
            }));
            newpassError = true
            setNewPassError(newpassError);
        }
        else {
            errorMessage.newPassword = "";
            setErrorMessage(prevState => ({
                ...prevState!,
                newPassword: errorMessage.newPassword
            }));
            newpassError = false
            setNewPassError(newpassError);
        }
    }
    const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
        ClientId: process.env.REACT_APP_CLIENT_ID || "",
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const handleSubmit = () => {
        // PS_PR_1.125-PS_PR_1.137
        handleInputChange()
        const hasError = Object.values(errorMessage).every(error => error == '');
        if (!hasError || newpassError) {
            return;
        }
        setLoader(true)
        // PS_PR_1.135-PS_PR_1.135
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: tmsConfiguration.users.email_address.trim(),
            Pool: userPool,
        });

        // Authenticate the user with the old password
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: tmsConfiguration.users.email_address,
            Password: UserPassword.oldPassword.trim(),
        });
        // PS_PR_1.136-PS_PR_1.144
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
                // User was authenticated, proceed with the password change
                cognitoUser.changePassword(UserPassword.oldPassword.trim(), UserPassword.newPassword.trim(), (error, result) => {
                    console.log(error)
                    if (error) {

                        if (error.message === "Incorrect username or password.") {
                            // Authentication failed

                            setOldPass('The old password entered is incorrect')
                            setLoader(false)

                        } else {
                            // Invoke the something went wrong popup
                            setOpenSomethingWentWrongPopup(true)
                            setLoader(false)

                        }
                    } else {
                        // Password updated successfully
                        //   setSuccessMessage(true);
                        // Clear the password fields
                        setOldPass('')

                        setUserPassword({
                            oldPassword: '',
                            newPassword: ''
                        });
                        openToast("The password is updated successfully")
                        setLoader(false)
                    }
                });
            },
            onFailure: (err) => {

                if (err.message === "Incorrect username or password.") {
                    // Authentication failed
                    setOldPass('The old password entered is incorrect')
                    setLoader(false)

                } else {
                    // Authentication failed
                    setOpenSomethingWentWrongPopup(true)
                    setLoader(false)

                }
            },
            // newPasswordRequired: (userAttributes, requiredAttributes) => {
            //   // User was forced to change their password
            //   setOpenSomethingWentWrongPopup(true)
            // },
        });
    }
    const handleCancel = () => {
        setUserPassword({
            oldPassword: '',
            newPassword: ''
        });
        setErrorMessage(
            {
                oldPassword: '',
                newPassword: '',
            }
        )
        navigate(-1); // Go back to the previous page
    }


    return (
        <>
            {
                loader && <Loader />
            }
            {
                openSomethingWentWrongPopup && <SomethingWentWrong onClose={() => setOpenSomethingWentWrongPopup(false)} />
            }
            <div
                className="tab-pane fade active show"
                id="pwdreset"
                role="tabpanel"
                aria-labelledby="pwdreset-tab"
            >
                <div className="row mb-3">
                    <h5 className="font-18 font-semibold pb-2">Password Reset</h5>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            className="font-semibold form-text-color font-14 pb-1"
                            htmlFor="Username"
                        >
                            Business Email Address <span className="required-color"> *</span>
                        </label>
                        <input
                            type="text"
                            disabled={true}
                            className="form-control custom-form-input primary-bg-color primary-border-color"
                            id="Username"
                            value={tmsConfiguration.users.email_address.trim()}
                            placeholder="Enter Business Email"
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            htmlFor="password"
                            className="form-label font-semibold form-text-color font-14 mb-1"
                        >
                            Old Password<span className="required-color"> *</span>
                        </label>
                        <div className="position-relative">
                            <input
                                type={showPass == 'password' ? 'password' : 'text'} className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                id="password"
                                placeholder="Enter Old Password"
                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                value={UserPassword?.oldPassword}
                                onChange={e => setUserPassword(prevState => ({
                                    ...prevState!,
                                    oldPassword: e.target.value.trim()
                                }))}
                            />
                            {showPass == 'password' ? (<span style={{ cursor: 'pointer' }} className="position-absolute custom-input-eye-postion custom-input-eye-color eye-close" onClick={() => { setShowPass('text'); }} />
                            ) : (<span style={{ cursor: 'pointer' }} className="position-absolute eye-open-icon custom-input-eye-color eye-open" onClick={() => { setShowPass('password'); }} />)}                 
                            {errorMessage.oldPassword && <div className="pt-1">
                                <label className="required-color font-12 font-regular">{errorMessage.oldPassword}</label>
                            </div>
                            }
                            {oldPassError && <div className="pt-1">
                                <label className="required-color font-12 font-regular">{oldPassError}</label>
                            </div>
                            }
                        </div>

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            htmlFor="password"
                            className="form-label font-semibold form-text-color font-14 mb-1"
                        >
                            New Password<span className="required-color"> *</span>
                        </label>
                        <div className="position-relative">
                            <input
                                type={newPass == 'password' ? 'password' : 'text'} className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                id="password"
                                placeholder="Enter New Password"
                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                value={UserPassword?.newPassword}
                                onChange={e => setUserPassword(prevState => ({
                                    ...prevState!,
                                    newPassword: e.target.value.trim()
                                }))}
                            />
                            {newPass == 'password' ? (<span style={{ cursor: 'pointer' }} className="position-absolute custom-input-eye-postion custom-input-eye-color eye-close" onClick={() => { setShowNewPass('text'); }} />
                            ) : (<span style={{ cursor: 'pointer' }} className="position-absolute eye-open-icon custom-input-eye-color eye-open" onClick={() => { setShowNewPass('password'); }} />)}
                            {newpassError && <ul className="ps-3">
                                <li className="required-color font-12 font-regular">Minimum length should be atleast 8 characters</li>
                                <li className="required-color font-12 font-regular">It should have lowercase letters</li>
                                <li className="required-color font-12 font-regular">It should have uppercase letters</li>
                                <li className="required-color font-12 font-regular">It should have symbols</li>
                                <li className="required-color font-12 font-regular">It should contain numbers</li>
                            </ul>}
                            {errorMessage.newPassword && <div className="pt-1">
                                <label className="required-color font-12 font-regular">{errorMessage.newPassword}</label>
                            </div>}
                        </div>
                    </div>
                    <div className="text-end mb-3 pe-2">
                        <button type="button" onClick={handleCancel} className="btn text-btn font-semibold me-3">
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn primary-btn font-semibold px-4" onClick={handleSubmit}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePasswordReset;