import { EquipmentDetails } from "../../../interface/LoadInterface";
const EquipmentDetail: React.FC<{ equipment: EquipmentDetails,loadId:number,openPopup:any}> = ({ equipment,openPopup }) => {

    //PS_13 Default ThirdPartyDetails object with empty strings
    const defaultDetails: EquipmentDetails = {
        trailer_id: 0,
        trailer_number: "",
        truck_id: 0,
        truck_number: "",
        trailer_type: ""
    };
    //PS_14 Check if nfiDetail is null or has the same structure as defaultDetails
    const hasValidDetails = equipment && JSON.stringify(equipment) !== JSON.stringify(defaultDetails);
    //PS_15 - 16
    return (
        <>
            <div className="col-12 col-md-6 mb-3">
                <div className="content-container list-details">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h3 className="font-18 font-semibold primary-text-color mb-0">Equipment
                            Details</h3>
                       {hasValidDetails &&( <button className="btn button-group-btn" onClick={openPopup}><img src="/images/edit-icon.svg"
                            alt="edit-icon"  className="history-icon" width="20px"
                            height="20px" /></button>)
    }
                    </div>
                    {hasValidDetails ?
                        <ul className="d-flex flex-wrap">
                            <li className="font-14 font-medium table-text-color">Truck #:<span
                                className="font-14 font-semibold primary-text-color ms-1">{equipment?.truck_number || "-"}</span></li>
                            <li className="font-14 font-medium table-text-color">Trailer #:<span
                                className="font-14 font-semibold primary-text-color ms-1"> {equipment?.trailer_number || "-"} </span></li>
                             <li className="font-14 font-medium table-text-color">Trailer Type:<span
                                className="font-14 font-semibold primary-text-color ms-1">  {equipment.trailer_type || "-"} </span></li>
                        </ul> : (
                            <div className="h-100px">
                                <div className="d-flex flex-row justify-content-center align-items-center h-inherit">
                                    <span className="no-records-found small me-2"></span>
                                    <p className="font-13 font-semibold color-black-v1 m-0">No Data Found</p>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}
export default EquipmentDetail;