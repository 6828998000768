import { ReportFilter, TrackingReportFilter } from "../interface/ReportsInterface";
import { client } from "./Client";


//PS_14 - PS_21
export const getTrackingReportDetails = async (tmsId: number, reportFilter: ReportFilter, trackingReportFilter: TrackingReportFilter) => {
    try {
        const response = await client(`loads/v1/tms/${tmsId}/reports/tracking?start_index=${reportFilter?.start_index}&page_size=${reportFilter?.page_size}&sort_type=${reportFilter?.sort_type}&search_data=${reportFilter?.search_data}&sort_column=${reportFilter?.sort_column}&from_date=${reportFilter?.from_date}&to_date=${reportFilter?.to_date}&carrier_id=${trackingReportFilter?.carrier_id}&tracking_provider=${trackingReportFilter?.tracking_provider}&tracking_status=${trackingReportFilter?.tracking_status}&customer_id=${trackingReportFilter?.customer_id}`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};

export const getAuditLogApi = async (tmsId: number, reportFilter: ReportFilter, etmAdmin: Boolean) => {
    try {
        let url = `loads/v1/tms/${tmsId}/reports/user-load-audits?start_index=${reportFilter?.start_index}&page_size=${reportFilter?.page_size}&sort_type=${reportFilter?.sort_type}&search_data=${reportFilter?.search_data}&sort_column=${reportFilter?.sort_column}&from_date=${reportFilter?.from_date}&to_date=${reportFilter?.to_date}&is_etm_admin=${etmAdmin}`
        const response = await client(url, { method: "GET" });

        return response;
    } catch (error) {
        console.error("Error in Get3plAuditLogApi:", error);
    }
}

//PS_14 - PS_21
export const getSummaryReportDetails = async (tmsId: number, reportFilter: ReportFilter) => {
    try {
        const response = await client(`loads/v1/tms/${tmsId}/reports/summary?start_index=${reportFilter?.start_index}&page_size=${reportFilter?.page_size}&sort_type=${reportFilter?.sort_type}&search_data=${reportFilter?.search_data}&sort_column=${reportFilter?.sort_column}&from_date=${reportFilter?.from_date}&to_date=${reportFilter?.to_date}`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};