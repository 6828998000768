import { useEffect, useState } from "react";
import { ChangeEquipmentRequest, EquipmentDetails, EquipmentErrorMessage } from "../../../interface/LoadInterface";
import { editEquipDetails } from "../../../service/Api";

const EditEquipmentDetails: React.FC<{ equipment: EquipmentDetails, loadId: number, onClose: any, onError: any, openLoader: any, closeLoader: any, openToast: any, getLoads: any }> = ({ equipment, openLoader, closeLoader, loadId, onClose, onError, openToast, getLoads }) => {
    const [equipDetail, setEquipDetail] = useState(equipment);
    let [errorMessage, setErrorMessage] = useState<EquipmentErrorMessage>({
        trailerNumber: '',
        trailerType: '',
        truckNumber: '',
    });
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setEquipDetail((prevState) => ({
            ...prevState,
            [name]: value.trimStart(),
        }));
    };

    /*PS_06 When the user clicks on "esc" button in the 
    popup for editing Equipment Details
    using eventListerner, when the key pressed is escape,
    onClose() function is called. */
    useEffect(() => {
        const handleEscape = (event: any) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        document.addEventListener("keydown", handleEscape);

        return () => {
            document.removeEventListener("keydown", handleEscape);
        };
    });
    //PS_18-PS_19 - handleInput change method for validation.
    const validateEquipment = () => {
        errorMessage = {
            trailerNumber: '',
            trailerType: '',
            truckNumber: '',
        };
        if (equipDetail?.trailer_number === '') {
            errorMessage.trailerNumber = "Please enter a value"
        }

        if (equipDetail?.trailer_type === '') {
            errorMessage.trailerType = "Please enter a value"
        }

        if (equipDetail?.truck_number == '') {
            errorMessage.truckNumber = "Please enter a value"
        }
        setErrorMessage(errorMessage);
    }
    //PS_17-18-handle save to submit the equipment
    const handleSave = async () => {
        //PS_19-PS_19
        validateEquipment()
        //PS_20-check the error message contains a value
        const hasError = Object.values(errorMessage).every(error => error == '');
        if (!hasError) {
            // If there are error messages, do not proceed with the API call
            return;
        }
        try {
            openLoader()
            const tmsId: number = Number(localStorage.getItem("tmsId") || 0);
            //PS_21
            const editEquipmentDetailsRequest: ChangeEquipmentRequest = {
                truck_number: equipDetail.truck_number,
                trailer_number: equipDetail.trailer_number,
                trailer_type: equipDetail.trailer_type,
            }
            onClose()
            const response = await editEquipDetails(tmsId, loadId, editEquipmentDetailsRequest);
            if (response.data.code === 200) {
                closeLoader()
                openToast("Equipment Details Updated");
                getLoads()
            } else {
                onClose()
                onError()
            }
        } catch (error) {
            onClose()
            closeLoader()
            onError()
        } finally {
            closeLoader()
        }
    };

    const handleClosePopup = () => {
        onClose()
    };
    return (
        <>
            <div
                className="modal fade show"
                id="equipmentdetails"
                tabIndex={-1} aria-labelledby="exampleModalLabel" style={{
                    backgroundColor: "rgba(0, 0, 0, .5)",
                    display: "block"
                }}>
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content secondary-bg-color p-2 ">
                        <div className="modal-header border-0">
                            <h1 className="modal-title fs-5 secondary-header" id="exampleModalLabel">
                                Equipment Details
                            </h1>
                            <button
                                type="button"
                                className="btn-close close-icon"
                                onClick={handleClosePopup}
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body pt-2">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <label className="font-semibold custom-label pb-2" htmlFor="truckNumber">
                                        Truck # <span className="required-color"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form-input primary-bg-color primary-border-color"
                                        id="truckNumber"
                                        name="truck_number"
                                        placeholder="Enter Truck Number"
                                        onChange={handleChange}
                                        onKeyDown={e => { if (e.key === "Enter") handleSave() }}
                                        value={equipDetail.truck_number}
                                        maxLength={15}
                                    />
                                    <label className="required-color font-12 font-regular">{errorMessage.truckNumber}</label>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label className="font-semibold custom-label pb-2" htmlFor="trailerNumber">
                                        Trailer # <span className="required-color"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form-input primary-bg-color primary-border-color"
                                        id="trailerNumber"
                                        name="trailer_number"
                                        placeholder="Enter Trailer Number"
                                        onKeyDown={e => { if (e.key === "Enter") handleSave() }}
                                        onChange={handleChange}
                                        value={equipDetail.trailer_number}
                                        maxLength={15}
                                    />
                                    <label className="required-color font-12 font-regular">{errorMessage.trailerNumber}</label>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label className="font-semibold custom-label pb-2" htmlFor="trailerType">
                                        Trailer Type <span className="required-color"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form-input primary-bg-color primary-border-color"
                                        id="trailerType"
                                        name="trailer_type"
                                        onKeyDown={e => { if (e.key === "Enter") handleSave() }}
                                        placeholder="Enter Trailer Type"
                                        onChange={handleChange}
                                        value={equipDetail.trailer_type}
                                        maxLength={12}
                                    />
                                    <label className="required-color font-12 font-regular">{errorMessage.trailerType}</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 pt-0">
                            <button
                                type="button"
                                className="btn text-btn font-14 font-medium px-4"
                                onClick={handleClosePopup}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn primary-btn font-14 font-medium px-4"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditEquipmentDetails;
