import React from "react";
import { useNavigate } from "react-router-dom";
import { BreadCrumbsProps, NavigationItem } from "../../interface/Interface";

const BreadCrumbs: React.FC<BreadCrumbsProps> = (props) => {
    const [breadCrumbs, setBreadcrumbs] = React.useState<NavigationItem[]>([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        setBreadcrumbs(props.data);
    }, [props.data]);

    const titleClick = (breadcrumb: NavigationItem) => {
        if (breadcrumb.state) {
            navigate(breadcrumb.path, { state: breadcrumb.state });
        } else {
            window.location.href = breadcrumb.path;
        }
    };
    return (
        <ul className="breadcrumb-item mb-4 ps-0">
            {breadCrumbs.map((breadcrumb, index) => {
                return breadcrumb.isActive ? (
                    <li><a className="pe-0" onClick={() => { titleClick(breadcrumb) }}>{breadcrumb.title}</a></li>
                ) : (
                    <li key={index} className="pe-2">{breadcrumb.title}</li>
                );
            })}
        </ul>
    );
};
export default BreadCrumbs;