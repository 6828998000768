import { useNavigate, useLocation } from 'react-router';


 //PC_FP_24-Reset successful screen
export const ResetSuccessful = () => {

    const location = useLocation();
    const navigate = useNavigate();

    let navigateTo: string;
    if (location.state && location.state.from && location.state.from.pathname) {
        navigateTo = location.state.from.pathname;
    } else {
        navigateTo = "/";
    }

    //PC_FP_24-Bind the reset successfull fields and navigate to sign in page
    return (
        <div className="row justify-content-center login-bg-hig align-items-center">
            <div className="col-md-9 col-sm-12  text-center">
                <span className=" reset-success-icon logo mt-5 mb-3"></span>
                <p className="text-center font-20 font-regular primary-text-color mt-3 mb-lg-0 mb-4">Your password reset has been done successfully.
                    So please <a href="#" className="text-decoration-none link-color" onClick={() => navigate(navigateTo)}>sign in.</a></p>
            </div>
        </div>
    );
}