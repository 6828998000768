import { StopLocations } from "../../../interface/LoadInterface";
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import MapContainer from "./MapContainer";

/**
 * PS-1 to 12
 * Marker component returns Map with Locations 
 * of the stops Marked in it.
 * If the Map creds took longer period of time then isLoads is shown. 
 */

const Marker: React.FC<{ stops: StopLocations[] }> = ({ stops }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
        libraries: ["places"]
    })

    if (!isLoaded) return <div>Loading...</div>

    return (
        <>
            {
                <div className="row">
                    <div className="col-md-12 mb-0 mb-lg-3">
                        <div className="content-container">
                            <MapContainer data={stops} ></MapContainer>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Marker;