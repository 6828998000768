import { useEffect, useState, } from 'react';
import { AuthenticationMethod, AuthenticationTypeDisplaytext, CommunicationPlatform, CommunicationPlatformDisplaytext, ConnectionStatus, DriverApplicationHours, EldDisconnectionHours, LocationProviderDisconnectionHours, NotificationDetail, RoleEnum, SecondTierTrackingProviders, SupportEscalationHours, TokenType, TrackingMethod, TrackingMethodDisplaytext, TrackingProvider } from '../../enums/enums';
import { TMSConfiguration, TMSErrorConfiguration, DelayReasons } from '../../interface/Interface';
import { ConnectionTest, retrieveTMSDetails, updateTMSDetails } from '../../service/Api';
import Loader from '../Resuables/Loader';
import { SomethingWentWrong } from '../Resuables/SomethingWentWrong';
import { useNavigate } from 'react-router-dom'
import { decryptData, encryptData, encryptUserId, formatTimeString, getEndOfDay, getStartOfDay, initializeTooltips, parseTimeString, parseToken } from '../../utils/utils';
import ReactDatePicker from 'react-datepicker';
import { TmsTestConnectionRequest } from '../../interface/TmsCreationInterface';

const TMSConfigurationDetails: React.FC<{ tmsId: string, openToast: any }> = ({ tmsId, openToast }) => {
    const navigate = useNavigate();

    const [loader, setLoader] = useState<boolean>(false)
    const [exception, setException] = useState<boolean>(false)
    const defaultTMSConfiguration: TMSConfiguration = {
        name: '',
        status: '',
        connection_status: ConnectionStatus.OFFLINE,
        access_token_type: '',
        token: '',
        api_validity_utc: '',
        system_configuration: {
            preferred_vendor: '',
            preferred_tracking_method: '',
            preferred_communication_platform: '',
            tracking_event_notify_hours: 0,
            second_tier_notify_hours: 0,
            support_escalation_hours: 0,
            eld_check_interval_hours: 0,
            driver_app_check_interval_hours: 0,
            location_provider_check_interval: 0,
            location_provider_quiet_hrs_start_time: '',
            location_provider_quiet_hrs_end_time: '',
        },
        webhook_configuration: {
            auth_type: '',
            identifier: '',
            secret: '',
            token: '',
            refresh_token: '',
            token_expiry: '',
            token_endpoint: '',
            load_event_update_url: '',
            driver_message_update_url: '',
            test_connection_url: '',
        },
        users:
        {
            user_name: '',
            user_type: '',
            email_address: '',
            phone_number: '',
            country_code: '1'
        },
        delay_reasons: [],

    };
    const defaultTMSErrorConfiguration: TMSErrorConfiguration = {
        name: '',
        status: '',
        access_token_type: '',
        api_validity_utc: '',
        system_configuration: {
            preferred_vendor: '',
            preferred_tracking_method: '',
            preferred_communication_platform: '',
            tracking_event_notify_hours: '',
            second_tier_notify_hours: '',
            support_escalation_hours: '',
            eld_check_interval_hours: '',
            driver_app_check_interval_hours: '',
            location_provider_check_interval: '',
            location_provider_quiet_hrs_start_time: '',
            location_provider_quiet_hrs_end_time: '',
        },
        webhook_configuration: {
            auth_type: '',
            identifier: '',
            secret: '',
            token: '',
            refresh_token: '',
            token_expiry: '',
            token_endpoint: '',
            load_event_update_url: '',
            driver_message_update_url: '',
            test_connection_url: '',
        },
        users:
        {
            user_name: '',
            user_type: '',
            email_address: '',
            phone_number: '',
            country_code: ''
        }
        ,
        delay_reasons: {
            error_code: '',
            reason: ''
        }
    };
    let [tmsConfiguration, setTMSConfiguration] = useState<TMSConfiguration>(defaultTMSConfiguration);
    let [tmsErrorDetails, setTmsErrorDetails] = useState<TMSErrorConfiguration>(defaultTMSErrorConfiguration)
    const [showSecret, setShowSecret] = useState<boolean>(false);
    const impersonate = localStorage.getItem('isImpersonate');
    const token = localStorage.getItem('accessToken');
    const payload = parseToken(token || "");
    const authenticationMethods = Object.values(AuthenticationMethod);
    const visibilityProviders = Object.values(TrackingProvider);
    const trackingMethods = Object.values(TrackingMethod);
    const communicationPlatforms = Object.values(CommunicationPlatform);
    const notificationDetailHrs = Object.values(NotificationDetail).filter(value => typeof value === 'number');
    const secondTierTrackingProviderHrs = Object.values(SecondTierTrackingProviders).filter(value => typeof value === 'number');
    const supportEscalationHrs = Object.values(SupportEscalationHours).filter(value => typeof value === 'number');
    const eldDisconnectionHrs = Object.values(EldDisconnectionHours).filter(value => typeof value === 'number');
    const driverApplicationHrs = Object.values(DriverApplicationHours).filter(value => typeof value === 'number');
    const locationProviderDisconnectHrs = Object.values(LocationProviderDisconnectionHours).filter(value => typeof value === 'number');
    const [offlineConnectionStatus, setOfflineConnectionStatus] = useState<boolean>(false);
    const [delayReason, setDelayReason] = useState<DelayReasons>({ error_code: '', reason: '' });
    const [editDelayReasonError, setEditDelayReasonError] = useState<DelayReasons>({ error_code: '', reason: '' });
    const sectionFieldMap = {
        'accountInformationSec': ['name', 'users[0].user_name', 'users[0].email_address', 'users[0].phone_number', 'status'],
        'apiKeyValiditySec': ['api_validity_utc'],
        'configurationSec': ['system_configuration.preferred_vendor', 'system_configuration.preferred_tracking_method', 'system_configuration.preferred_communication_platform'],
        'authenticationSec': ['webhook_configuration.auth_type', 'webhook_configuration.load_event_update_url', 'webhook_configuration.driver_message_update_url', 'webhook_configuration.test_connection_url', 'webhook_configuration.identifier', 'webhook_configuration.secret', 'webhook_configuration.token_endpoint'],
        'trackingTimelineEscalationSec': ['system_configuration.tracking_event_notify_hours', 'system_configuration.second_tier_notify_hours', 'system_configuration.support_escalation_hours', 'system_configuration.location_provider_quiet_hrs_start_time', 'system_configuration.location_provider_quiet_hrs_end_time'],
        'trackingDisconnectionEscalationSec': ['system_configuration.eld_check_interval_hours', 'system_configuration.driver_app_check_interval_hours', 'system_configuration.location_provider_check_interval']
    };
    const [currentReason, setCurrentReason] = useState<number | undefined>();
    const [delayReasonAtTimeOfEdit, setDelayReasonAtTimeOfEdit] = useState<DelayReasons>({ error_code: '', reason: '' });

    useEffect(() => {
        getUserOrganizationData(tmsId)

        const tooltipTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        )
        const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl: Element) => {
            return new (window as any).bootstrap.Tooltip(tooltipTriggerEl)
        })

        return () => {
            tooltipList.forEach((tooltip: any) => tooltip.dispose())
        }
    }, []);

    const onTimeChange = (timeField: string, date: Date | null) => {
        if (date) {
            const formattedTime = formatTimeString(date);
            if (formattedTime) {
                if (timeField === 'location_provider_quiet_hrs_end_time') {
                    const startTime = parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_start_time);
                    if (startTime && date < startTime) {
                        handleTimeChange(timeField, ''); // Clear the end time if it's earlier than the start time
                        return;
                    }
                }
                handleTimeChange(timeField, formattedTime);
            }
        }
    };

    const handleTimeChange = (name: string, value: string) => {
        // Update state with the new valid time
        setTMSConfiguration((prevState) => ({
            ...prevState,
            system_configuration: {
                ...prevState.system_configuration,
                [name]: value, // Parse and set to 0 if parsing fails
            }
        }))
    };


    const handleTMSCancel = () => {
        setTMSConfiguration(defaultTMSConfiguration);
        setTmsErrorDetails(defaultTMSErrorConfiguration);
        setDelayReason({ error_code: '', reason: '' })
        navigate(-1);
    };

    const handleTMSInputChange = () => {

        tmsErrorDetails = defaultTMSErrorConfiguration;
        if (!tmsConfiguration.webhook_configuration.identifier) {
            tmsErrorDetails.webhook_configuration.identifier = 'Enter a value'
        }
        if (!tmsConfiguration.webhook_configuration.secret) {
            tmsErrorDetails.webhook_configuration.secret = 'Enter a value'
        }
        if (tmsConfiguration.webhook_configuration.auth_type === authenticationMethods.find(authenticationMethod => authenticationMethod === AuthenticationMethod.OAUTH_AUTHENTICATION)) {
            if (!tmsConfiguration.webhook_configuration.token_endpoint) {
                tmsErrorDetails.webhook_configuration.token_endpoint = 'Enter a value'
            }
            else if (!tmsConfiguration.webhook_configuration.token_endpoint.startsWith('https://')) {
                tmsErrorDetails.webhook_configuration.token_endpoint = 'Please enter a valid URL'
            }
        }
        if (!tmsConfiguration.webhook_configuration.auth_type) {
            tmsErrorDetails.webhook_configuration.auth_type = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.preferred_communication_platform) {
            tmsErrorDetails.system_configuration.preferred_communication_platform = 'Select a value'
        }
        if (!tmsConfiguration.webhook_configuration.test_connection_url) {
            tmsErrorDetails.webhook_configuration.test_connection_url = 'Enter a value'
        } else if (!tmsConfiguration.webhook_configuration.test_connection_url.startsWith('https://')) {
            tmsErrorDetails.webhook_configuration.test_connection_url = 'Please enter a valid URL'
        }
        if (!tmsConfiguration.system_configuration.driver_app_check_interval_hours) {
            tmsErrorDetails.system_configuration.driver_app_check_interval_hours = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.eld_check_interval_hours) {
            tmsErrorDetails.system_configuration.eld_check_interval_hours = 'Select a value'
        }
        if (!tmsConfiguration.webhook_configuration.load_event_update_url) {
            tmsErrorDetails.webhook_configuration.load_event_update_url = 'Enter a value'
        } else if (!tmsConfiguration.webhook_configuration.load_event_update_url.startsWith('https://')) {
            tmsErrorDetails.webhook_configuration.load_event_update_url = 'Please enter a valid URL'
        }
        if (!tmsConfiguration.system_configuration.location_provider_check_interval) {
            tmsErrorDetails.system_configuration.location_provider_check_interval = 'Select a value'
        }
        if (!tmsConfiguration.webhook_configuration.driver_message_update_url) {
            tmsErrorDetails.webhook_configuration.driver_message_update_url = 'Enter a value'
        } else if (!tmsConfiguration.webhook_configuration.driver_message_update_url.startsWith('https://')) {
            tmsErrorDetails.webhook_configuration.driver_message_update_url = 'Please enter a valid URL'
        }
        if (!tmsConfiguration.system_configuration.tracking_event_notify_hours) {
            tmsErrorDetails.system_configuration.tracking_event_notify_hours = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.preferred_vendor) {
            tmsErrorDetails.system_configuration.preferred_vendor = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.second_tier_notify_hours) {
            tmsErrorDetails.system_configuration.second_tier_notify_hours = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.support_escalation_hours) {
            tmsErrorDetails.system_configuration.support_escalation_hours = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.location_provider_quiet_hrs_start_time) {
            tmsErrorDetails.system_configuration.location_provider_quiet_hrs_start_time = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.location_provider_quiet_hrs_end_time) {
            tmsErrorDetails.system_configuration.location_provider_quiet_hrs_end_time = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.preferred_tracking_method) {
            tmsErrorDetails.system_configuration.preferred_tracking_method = 'Select a value'
        }
        setTmsErrorDetails({
            ...tmsErrorDetails
        });
        // PS_FP_1.24-PS_FP_1.25
    };

    const findFirstErrorSection = () => {
        for (const [sectionId, fields] of Object.entries(sectionFieldMap)) {
            if (fields.some(field => tmsErrorDetails[field as keyof TMSErrorConfiguration] !== '')) {
                return sectionId;
            }
        }
        return null;
    };

    const getUserOrganizationData = async (userId?: string | undefined) => {
        try {
            setLoader(true)
            const id = userId ? userId : ""
            const response: any = await retrieveTMSDetails(id)
            if (response.data.code == 200) {
                const fetchedTMSConfig: TMSConfiguration = response.data.data;
                const decryptedData = decryptData(fetchedTMSConfig.webhook_configuration.secret)
                setTMSConfiguration({
                    ...fetchedTMSConfig,
                    webhook_configuration: {
                        ...fetchedTMSConfig.webhook_configuration,
                        secret: decryptedData || "",
                    }
                })
            }
            else {
                setException(true)
            }
            setLoader(false)
            initializeTooltips()

        }
        catch (error) {
            console.log("'The retrieve error", error)
            setException(true)
            setLoader(false)
        }
    }
    const hasAnyNonEmptyString = (obj: any): boolean => {
        if (typeof obj === 'string') {
            return obj !== '';
        }
        if (Array.isArray(obj)) {
            return obj.some(item => hasAnyNonEmptyString(item));
        }
        if (typeof obj === 'object' && obj !== null) {
            return Object.values(obj).some(value => hasAnyNonEmptyString(value));
        }
        return false;
    };
    const handleTMSSubmit = async () => {
        handleTMSInputChange()
        const hasAnyError = hasAnyNonEmptyString(tmsErrorDetails);
        if (hasAnyError) {
            const firstErrorSection = findFirstErrorSection();
            if (firstErrorSection) {
                const element = document.getElementById(firstErrorSection);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
            return;
        }
        const encryptedFormData = encryptData(tmsConfiguration.webhook_configuration.secret);
        const { api_client_id, api_client_secret, ...filteredTMSConfiguration } = tmsConfiguration;
        const OrganizationPayload = { ...filteredTMSConfiguration };
        OrganizationPayload.webhook_configuration.identifier = tmsConfiguration.webhook_configuration.identifier.trim()
        OrganizationPayload.webhook_configuration.secret = encryptedFormData || ""
        try {
            setLoader(true)
            let userRole: string = ''
            let isImpersonate: boolean = false
            if (impersonate == 'true' && payload.userRole == RoleEnum.ETM_ADMIN) {
                userRole = RoleEnum.ETM_ADMIN
                isImpersonate = true
            } else if (payload.userRole == RoleEnum.THIRD_PARTY_ADMIN || payload.userRole == RoleEnum.THIRD_PARTY_STAFF) {
                userRole = RoleEnum.THIRD_PARTY_ADMIN
                isImpersonate = false
            }
            const response: any = await updateTMSDetails(isImpersonate, userRole, OrganizationPayload);
            // PS_FP_1.29-PS_FP_1.33 checkfor the response
            if (response.data.code == 200) {
                await getUserOrganizationData(tmsId ? tmsId : "0")
                openToast("Configuration updated successfully")
            }
            else {
                setException(true)
            }
            setLoader(false)
        } catch (error) {
            setException(true)
            console.log("the error logging", error)
            setLoader(false)
        }
    };

    const handleEndpointBlur = async (connectionTestURl: string) => {
        // Handling onBlur event for Endpoint URL Validation
        // PS_FP_1.19-PS_FP_1.21
        if (!connectionTestURl) {
            return; // Exit the function if the URL is empty
        }
        if (!connectionTestURl.startsWith('https://')) {
            return
        }
        try {
            setLoader(true)

            const payload: TmsTestConnectionRequest = {
                identifier: tmsConfiguration.webhook_configuration.identifier,
                secret: encryptUserId(tmsConfiguration.webhook_configuration.secret),
                token_endpoint_url: tmsConfiguration.webhook_configuration.token_endpoint,
                test_connection_url: connectionTestURl,
                authentication_type: tmsConfiguration.webhook_configuration.auth_type,
            }

            // PS_FP_1.22-PS_FP_1.24
            const response = await ConnectionTest(payload);
            if (response.status == 200) {
                setOfflineConnectionStatus(false)
                setTMSConfiguration((prevState) => ({
                    ...prevState,
                    connection_status: ConnectionStatus.ONLINE
                }))


            } else {
                setOfflineConnectionStatus(true)
                setTMSConfiguration((prevState) => ({
                    ...prevState,
                    connection_status: ConnectionStatus.OFFLINE
                }))
            }
            setLoader(false)

        }
        catch (error) {
            setOfflineConnectionStatus(true)

        }
        setLoader(false)
    };

    const validateDelayReason = (): boolean => {
        let newErrors = { error_code: '', reason: '' };
        let validDelayReason = true;

        if (!delayReason.error_code.trim()) {
            newErrors.error_code = 'Please enter reason code';
            validDelayReason = false;
        } else {
            // Check if the reason code already exists
            const isDuplicate = tmsConfiguration.delay_reasons?.some(
                existingReason => existingReason.error_code.toLowerCase() === delayReason.error_code.trim().toLowerCase()
            );

            if (isDuplicate) {
                newErrors.error_code = 'This reason code already exists';
                validDelayReason = false;
            }
        }

        if (!delayReason.reason.trim()) {
            newErrors.reason = 'Please enter reason for delay';
            validDelayReason = false;
        }

        setTmsErrorDetails(prevErrors => ({
            ...prevErrors,
            delay_reasons: newErrors
        }));

        return validDelayReason;
    };

    const validateEditDelayReason = (reason: DelayReasons, index: number): boolean => {
        let newErrors = { error_code: '', reason: '' };
        let validDelayReason = true;

        if (!reason.error_code.trim()) {
            newErrors.error_code = 'Please enter reason code';
            validDelayReason = false;
        } else {
            // Check if the reason code already exists, excluding the current index
            const isDuplicate = tmsConfiguration.delay_reasons?.some(
                (existingReason, i) =>
                    i !== index &&
                    existingReason.error_code.toLowerCase() === reason.error_code.trim().toLowerCase()
            );

            if (isDuplicate) {
                newErrors.error_code = 'This reason code already exists';
                validDelayReason = false;
            }
        }

        if (!reason.reason.trim()) {
            newErrors.reason = 'Please enter reason for delay';
            validDelayReason = false;
        }

        setEditDelayReasonError(newErrors);
        return validDelayReason;
    };

    const handleAddReason = () => {
        const validDelayReason = validateDelayReason();
        if (!validDelayReason) {
            return;
        }

        setTMSConfiguration(prevConfig => {
            const updatedDelayReasons = [...(prevConfig.delay_reasons || []), delayReason];
            return {
                ...prevConfig,
                delay_reasons: updatedDelayReasons
            };
        });

        // Reset the delayReason state
        setDelayReason({ error_code: '', reason: '' });

        // Clear any existing error messages
        setTmsErrorDetails(prevErrors => ({
            ...prevErrors,
            delay_reasons: { error_code: '', reason: '' }
        }));
    };

    const handleEditReason = (index: number) => {
        if (tmsConfiguration.delay_reasons && tmsConfiguration.delay_reasons[index]) {
            const editReasonDetails = { ...tmsConfiguration.delay_reasons[index] };
            setDelayReasonAtTimeOfEdit(editReasonDetails);
            setCurrentReason(index);
        }
    }

    const handleDeleteReason = (index: number) => {
        setTMSConfiguration(prevConfig => {
            if (prevConfig.delay_reasons && prevConfig.delay_reasons[index]) {
                // Create a new array without the deleted item
                const updatedDelayReasons = prevConfig.delay_reasons.filter((_, i) => i !== index);

                // Return a new configuration object with the updated delay_reasons
                return {
                    ...prevConfig,
                    delay_reasons: updatedDelayReasons
                };
            }
            // If the index doesn't exist, return the previous configuration unchanged
            return prevConfig;
        });
    };

    const handleConfirmEditReason = () => {
        if (currentReason !== undefined && tmsConfiguration.delay_reasons) {
            const validDelayReason = validateEditDelayReason(delayReasonAtTimeOfEdit, currentReason);
            if (validDelayReason) {
                const updatedDelayReasons = [...tmsConfiguration.delay_reasons];
                updatedDelayReasons[currentReason] = delayReasonAtTimeOfEdit;

                setTMSConfiguration(prev => ({
                    ...prev,
                    delay_reasons: updatedDelayReasons
                }));

                setCurrentReason(undefined);
                setDelayReasonAtTimeOfEdit({ error_code: '', reason: '' });
                setEditDelayReasonError({ error_code: '', reason: '' });
            }
        }
    };

    const handleCancelEditReason = () => {
        if (currentReason !== undefined && tmsConfiguration.delay_reasons) {
            // Revert to the original values
            const originalReason = tmsConfiguration.delay_reasons[currentReason];

            // Update the tmsConfiguration state immutably
            setTMSConfiguration(prev => ({
                ...prev,
                delay_reasons: prev.delay_reasons?.map((reason, index) =>
                    index === currentReason ? originalReason : reason
                )
            }));

            // Reset the editing states
            setCurrentReason(undefined);
            setDelayReasonAtTimeOfEdit({ error_code: '', reason: '' });
            setEditDelayReasonError({ error_code: '', reason: '' });
        }
    };



    return (
        <>
            {
                loader && <Loader />
            }
            {
                exception && <SomethingWentWrong onClose={() => setException(false)} />
            }
            {offlineConnectionStatus && (
                <div
                    className="modal fade show"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "block", paddingLeft: 0, backgroundColor: "rgba(0, 0, 0, .5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content secondary-bg-color">
                            <div className="modal-body pt-4">
                                <div className="row text-center justify-content-center">
                                    <img
                                        src="images/alert-icon.svg"
                                        alt="alert-icon"
                                        className="alert-icon p-0"
                                    />
                                    <h1 className="primary-header mt-3" id="exampleModalLabel">
                                        Alert
                                    </h1>
                                    <p className="form-text-color font-16 font-regular">
                                        Connection test failed. Please verify the provided URL and system configuration.                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center pb-4 pt-0 border-0">
                                <button
                                    onClick={() => setOfflineConnectionStatus(false)}
                                    type="button"
                                    className="btn primary-btn font-semibold px-4"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className="tab-pane fade active show"
                id="configuration"
                role="tabpanel"
                aria-labelledby="configuration-tab"
            >
                <div className="row mb-3">
                    <h5 id="configurationSec" className="font-18 font-semibold pb-2">Configuration</h5>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            className="font-semibold form-text-color font-14 pb-1"
                            htmlFor="PreferredVendor"
                        >
                            Preferred Tracking Provider <span className="required-color"> *</span>
                        </label>
                        <select style={{ cursor: 'pointer' }}
                            className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                            aria-label="Default select example"
                            value={tmsConfiguration.system_configuration.preferred_vendor}
                            onChange={(e) =>
                                setTMSConfiguration((prevState) => ({
                                    ...prevState,
                                    system_configuration: {
                                        ...prevState.system_configuration,
                                        preferred_vendor: e.target.value || '', // Parse and set to 0 if parsing fails
                                    },
                                }))
                            }
                        >
                            <option >Select</option>
                            {visibilityProviders.filter(provider => provider !==
                                TrackingProvider.TEXTLOCATE
                            ).map(provider => (<option key={provider} value={provider}>
                                {provider}
                            </option>
                            ))}
                        </select>
                        <div className="pt-1">
                            <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.preferred_vendor}</label>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            className="font-semibold form-text-color font-14 pb-1"
                            htmlFor="TrackingMethod"
                        >
                            Tracking Method
                        </label>
                        <div className="d-flex align-items-center gap-4 py-2">
                            {trackingMethods.map((method, index) => (
                                <div key={`${index}`} className="form-check d-flex align-items-center gap-2 p-0">
                                    <input
                                        className="form-check-input primary-bg-color m-0 form-checked primary-border-color"
                                        type="radio"
                                        style={{ cursor: 'pointer' }}
                                        name="TrackingRadio"
                                        id={`${method}`}
                                        checked={tmsConfiguration.system_configuration?.preferred_tracking_method === method}
                                        defaultChecked={method === TrackingMethod.TRUCK}
                                        onChange={() =>
                                            setTMSConfiguration((prevState) => ({
                                                ...prevState,
                                                system_configuration: {
                                                    ...prevState.system_configuration,
                                                    preferred_tracking_method: method, // Set tracking method ID to the selected method's ID
                                                    // Other configuration properties remain unchanged
                                                },
                                            }))
                                        }
                                    />
                                    <label
                                        className="form-check-label   font-regular form-text-color font-14"
                                        htmlFor={`${method}`}
                                    >
                                        {TrackingMethodDisplaytext[method] || method}

                                    </label>

                                </div>
                            ))}
                        </div>
                        <div className="pt-1">
                            <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.preferred_tracking_method}</label>

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                        <label
                            className="font-semibold form-text-color font-14 pb-1"
                            htmlFor="CommunicationPlatform"
                        >
                            Communication Platform
                        </label>
                        <div className="py-2">
                            <div className="py-2">
                                {communicationPlatforms
                                    .filter((platform) => [CommunicationPlatform.EMAIL, CommunicationPlatform.TEAMS, CommunicationPlatform.SLACK].includes(platform))
                                    .map((platform: any) => (
                                        <>
                                            {platform === CommunicationPlatform.EMAIL && (
                                                <div className="form-check form-check-inline me-3 me-md-4 align-middle">
                                                    <input
                                                        className="form-check-input primary-bg-color form-checkbox primary-border-color"
                                                        type="checkbox"
                                                        id={`${platform}`}
                                                        defaultChecked={platform === CommunicationPlatform.EMAIL}
                                                        onChange={(e: any) =>
                                                            setTMSConfiguration((prevState: any) => ({
                                                                ...prevState,
                                                                system_configuration: {
                                                                    ...prevState.system_configuration,
                                                                    preferred_communication_platform: e.target.checked ? platform : '',
                                                                }
                                                            }))
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label font-regular form-text-color font-14"
                                                        htmlFor={`${platform}`}
                                                    >
                                                        {CommunicationPlatformDisplaytext[platform] || platform}
                                                    </label>
                                                </div>
                                            )}
                                            {platform === CommunicationPlatform.SLACK && (
                                                <div
                                                    className="form-check form-check-inline me-3 me-md-4 align-middle"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Coming Soon" data-bs-original-title="" aria-label="Coming Soon"
                                                >
                                                    <input
                                                        className="form-check-input primary-bg-color form-checkbox primary-border-color"
                                                        type="checkbox"
                                                        id={`${platform}`}
                                                        disabled
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Coming Soon" data-bs-original-title="" aria-label="Coming Soon"
                                                    />
                                                    <label
                                                        className=" form-check-label font-regular form-text-color font-14"
                                                        htmlFor={`${platform}`}
                                                    >
                                                        {CommunicationPlatformDisplaytext[platform] || platform}
                                                    </label>
                                                </div>
                                            )}
                                            {platform === CommunicationPlatform.TEAMS && (
                                                <div
                                                    className="form-check form-check-inline me-3 me-md-4 align-middle"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title="Coming Soon"
                                                >
                                                    <input
                                                        className="form-check-input primary-bg-color form-checkbox primary-border-color"
                                                        type="checkbox"
                                                        id={`${platform}`}
                                                        disabled
                                                    />
                                                    <label
                                                        className="form-check-label font-regular form-text-color font-14"
                                                        htmlFor={`${platform}`}
                                                    >
                                                        {CommunicationPlatformDisplaytext[platform] || platform}
                                                    </label>
                                                </div>
                                            )}
                                        </>
                                    ))}
                            </div>


                        </div>
                        <div className="pt-1">
                            <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.preferred_tracking_method}</label>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <h5 id="authenticationSec" className="font-18 font-semibold pb-4 m-0">
                            Authentication
                        </h5>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-1"
                                htmlFor="PreferredVendor"
                            >
                                Authentication Method
                            </label>
                            <select
                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                aria-label="Default select example"
                                style={{ cursor: 'pointer' }}
                                value={tmsConfiguration.webhook_configuration.auth_type}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState: any) => {
                                        const selectedMethodId = e.target.value || '';
                                        const selectedMethod = authenticationMethods.find(method => method === selectedMethodId);
                                        const authentication = selectedMethod ? selectedMethod : ''; // Default to empty string if method not found

                                        return {
                                            ...prevState,
                                            webhook_configuration: {
                                                ...prevState.webhook_configuration,
                                                auth_type: authentication,
                                            },
                                        };
                                    })
                                }
                            >
                                {authenticationMethods.map((method) => (
                                    <option key={method} value={method}>
                                        {AuthenticationTypeDisplaytext[method] || method}
                                    </option>
                                ))}
                            </select>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.auth_type}</label>
                            </div>
                        </div>
                        {/* PS_FP_1.10-authenticationMethod ternary check */}
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label className="font-semibold form-text-color font-14 pb-1" htmlFor="Username">
                                {tmsConfiguration.webhook_configuration.auth_type == AuthenticationMethod.OAUTH_AUTHENTICATION ? "Client ID" : "Username"}<span className="required-color"> *</span>
                            </label>
                            <input
                                type="text"
                                className="form-control custom-form-input primary-bg-color primary-border-color"
                                id="clientId"
                                placeholder={tmsConfiguration.webhook_configuration.auth_type == AuthenticationMethod.OAUTH_AUTHENTICATION ? "Enter Client ID" : "Enter Username"}
                                onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                name="clientId"
                                onChange={(e) => {
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        webhook_configuration: {
                                            ...prevState.webhook_configuration,
                                            identifier: e.target.value.trimStart(), // Set to empty string if unchecked
                                            // Other configuration properties remain unchanged
                                        },
                                    }))
                                }}
                                value={tmsConfiguration.webhook_configuration.identifier}
                            />
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.identifier}</label>
                            </div>
                        </div><div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                htmlFor="password"
                                className="form-label font-semibold form-text-color font-14 mb-1"
                            >
                                {tmsConfiguration.webhook_configuration.auth_type == AuthenticationMethod.OAUTH_AUTHENTICATION ? "Client Secret" : "Password"}<span className="required-color"> *</span>
                            </label>
                            <div className="position-relative">
                                <input
                                    type={showSecret ? 'text' : 'password'} className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                    id="clientSecret"
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    placeholder={tmsConfiguration.webhook_configuration.auth_type == AuthenticationMethod.OAUTH_AUTHENTICATION ? "Enter Client Secret" : "Enter Password"}
                                    name="clientSecret"
                                    onChange={(e) => {
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            webhook_configuration: {
                                                ...prevState.webhook_configuration,
                                                secret: e.target.value.trimStart(), // Set to empty string if unchecked
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }}
                                    value={tmsConfiguration.webhook_configuration.secret}
                                />
                                {!showSecret ? (<span style={{ cursor: 'pointer' }} className="position-absolute custom-input-eye-postion custom-input-eye-color eye-close" onClick={() => { setShowSecret(true); }} />
                                ) : (<span style={{ cursor: 'pointer' }} className="position-absolute eye-open-icon custom-input-eye-color eye-open" onClick={() => { setShowSecret(false); }} />)}
                            </div>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.secret}</label>
                            </div>
                        </div>
                        {tmsConfiguration.webhook_configuration.auth_type === authenticationMethods.find(authenticationMethod => authenticationMethod === AuthenticationMethod.OAUTH_AUTHENTICATION) ? (
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="TokenEndpointUrl"
                                >
                                    Token Endpoint URL
                                </label>

                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-original-title="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                    aria-label="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                />
                                <span className="required-color"> *</span>
                                <input
                                    type="url"
                                    className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                    id="EventUpdateURL"
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    placeholder="Enter Event Update URL"
                                    value={tmsConfiguration.webhook_configuration.token_endpoint}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            webhook_configuration: {
                                                ...prevState.webhook_configuration,
                                                token_endpoint: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.token_endpoint}</label>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-1"
                                htmlFor="EventUpdateURL"
                            >
                                Event Update URL
                            </label>
                            <img
                                className="ms-2 tooltip-img"
                                src="images/tooltip-icon.svg"
                                alt="tooltip-icon"
                                data-bs-toggle="tooltip" // Enable Bootstrap tooltip
                                data-bs-placement="top" // Set tooltip placement
                                title="Specify the Webhook URL where event updates on loads should be sent." // Set tooltip message
                                aria-label="Tooltip icon"
                                aria-describedby="tooltip89429" // Optional: Provide an ID if you want to associate it with a specific element
                            />
                            <span className="required-color"> *</span>
                            <input
                                type="url"
                                className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                id="EventUpdateURL"
                                placeholder="Enter Event Update URL"
                                onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                value={tmsConfiguration.webhook_configuration.load_event_update_url}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        webhook_configuration: {
                                            ...prevState.webhook_configuration,
                                            load_event_update_url: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                            // Other configuration properties remain unchanged
                                        },
                                    }))
                                }
                            />
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.load_event_update_url}</label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-1"
                                htmlFor="EventUpdateURL"
                            >
                                Message Update URL
                            </label>
                            <img
                                className="ms-2 tooltip-img"
                                src="images/tooltip-icon.svg"
                                alt="tooltip-icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Specify the Webhook URL where message updates from the driver should be sent."
                                aria-label="Specify the Webhook URL where message updates from the driver should be sent."
                            />
                            <span className="required-color"> *</span>
                            <input
                                type="url"
                                className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                id="EventUpdateURL"
                                placeholder="Enter Event Update URL"
                                value={tmsConfiguration.webhook_configuration.driver_message_update_url}
                                onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        webhook_configuration: {
                                            ...prevState.webhook_configuration,
                                            driver_message_update_url: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                            // Other configuration properties remain unchanged
                                        },
                                    }))
                                }
                            />
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.driver_message_update_url}</label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-1"
                                htmlFor="ConnectionTestURL"
                            >
                                Connection Test URL
                            </label>
                            <img
                                className="ms-2 tooltip-img"
                                src="images/tooltip-icon.svg"
                                alt="tooltip-icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Specify the URL that the system will access to perform a connection test or health check."
                                aria-label="Specify the URL that the system will access to perform a connection test or health check."
                            />
                            <span className="required-color"> *</span>
                            <input
                                type="url"
                                className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                id="ConnectionTestURL"
                                placeholder="Enter Connection Test URL"
                                onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                value={tmsConfiguration.webhook_configuration.test_connection_url}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        webhook_configuration: {
                                            ...prevState.webhook_configuration,
                                            test_connection_url: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                            // Other configuration properties remain unchanged
                                        },
                                    }))
                                }
                                onBlur={() => handleEndpointBlur(tmsConfiguration.webhook_configuration.test_connection_url)}
                            />
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.test_connection_url}</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <h5 id="trackingTimelineEscalationSec" className="font-18 font-semibold pb-4 m-0">
                            Tracking Timeline Escalation
                        </h5>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-1"
                                htmlFor="NotificationDetail"
                            >
                                Notification Detail Hrs
                            </label>
                            <img
                                className="ms-2 tooltip-img"
                                src="images/tooltip-icon.svg"
                                alt="tooltip-icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Specify the number of hours before pick-up to notify if tracking details are unavailable."
                                aria-label="Specify the number of hours before pick-up to notify if tracking details are unavailable."
                            />
                            <span className="required-color"> *</span>
                            <select
                                style={{ cursor: 'pointer' }}
                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                aria-label="Default select example"
                                value={tmsConfiguration.system_configuration.tracking_event_notify_hours}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        system_configuration: {
                                            ...prevState.system_configuration,
                                            tracking_event_notify_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                        },
                                    }))
                                }
                            >
                                <option>Select</option>
                                {notificationDetailHrs.map((hour) => (
                                    <option value={`${hour}`}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.tracking_event_notify_hours}</label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-1"
                                htmlFor="SecondTierTracking"
                            >
                                Second Tier Tracking Provider Hrs
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                    aria-label="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                />{" "}
                                <span className="required-color"> *</span>
                            </label>
                            <select
                                style={{ cursor: 'pointer' }}
                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                aria-label="Default select example"
                                value={tmsConfiguration.system_configuration.second_tier_notify_hours}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        system_configuration: {
                                            ...prevState.system_configuration,
                                            second_tier_notify_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                        }
                                    }))
                                }
                            >
                                <option>Select</option>
                                {secondTierTrackingProviderHrs.map((hour, index) => (
                                    <option key={index} value={`${hour}`}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.second_tier_notify_hours}</label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-1"
                                htmlFor="SupportEscalation"
                            >
                                Support Escalation Hrs
                            </label>
                            <img
                                className="ms-2 tooltip-img"
                                src="images/tooltip-icon.svg"
                                alt="tooltip-icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Specify the number of hours after
                              the pickup time to escalate to support staff if updates from tracking providers are not received."
                                aria-label="Specify the number of hours after
                              the pickup time to escalate to support staff if updates from tracking providers are not received."
                            />
                            <span className="required-color"> *</span>
                            <select
                                style={{ cursor: 'pointer' }}
                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                aria-label="Default select example"
                                value={tmsConfiguration.system_configuration.support_escalation_hours}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        system_configuration: {
                                            ...prevState.system_configuration,
                                            support_escalation_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                        }
                                    }))
                                }
                            >
                                <option>Select</option>
                                {supportEscalationHrs.map((hour, index) => (
                                    <option key={index} value={`${hour}`}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.support_escalation_hours}</label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-2"
                                htmlFor="TextLocate"
                            >
                                Text Locate Quiet Hrs
                            </label>
                            <img
                                className="ms-2 tooltip-img"
                                src="images/tooltip-icon.svg"
                                alt="tooltip-icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Specify the quiet hours for drivers by defining the start and end times during which bulk messages won't be sent."
                                aria-label="Specify the quiet hours for drivers by defining the start and end times during which bulk messages won't be sent."
                            />
                            <span className="required-color"> *</span>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 mb-4 pe-2">
                                    <label
                                        className="font-semibold form-text-color font-14 pb-1"
                                        htmlFor="StartTime"
                                    >
                                        Start Time
                                    </label>
                                    <div className="position-relative">
                                        <ReactDatePicker
                                            selected={parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_start_time)}
                                            onChange={(date: Date) => onTimeChange('location_provider_quiet_hrs_start_time', date)}
                                            // maxTime={organization3plDetails.configuration.textLocateEndTimeId ? new Date(organization3plDetails.configuration.textLocateEndTimeId) : undefined} // Set maxTime dynamically
                                            minTime={getStartOfDay()}  // Always have a start of day time
                                            maxTime={parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_end_time) ?? getEndOfDay()}  // Conditional start time or start of day
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            id="StartTime"
                                            timeFormat="HH:mm"
                                            placeholderText="--:--"
                                            className='form-control custom-form-input primary-bg-color primary-border-color'
                                        />
                                        <label htmlFor="StartTime">

                                            <img
                                                className="position-absolute custom-input-icon"
                                                src="images/clock-icon.svg"
                                                alt="clock-icon"
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </label>
                                    </div>
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.location_provider_quiet_hrs_start_time}</label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 mb-4 pe-2">
                                    <label
                                        className="font-semibold form-text-color font-14 pb-1"
                                        htmlFor="EndTime"
                                    >
                                        End Time
                                    </label>
                                    <div className="position-relative">
                                        <ReactDatePicker
                                            selected={parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_end_time)}
                                            onChange={(date: Date) => onTimeChange('location_provider_quiet_hrs_end_time', date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            minTime={parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_end_time) ?? getStartOfDay()}
                                            maxTime={getEndOfDay()}  // Always have an end of day time
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            id="EndTime"
                                            timeFormat="HH:mm"
                                            placeholderText="--:--"
                                            className='form-control custom-form-input primary-bg-color primary-border-color'
                                        />
                                        <label htmlFor="EndTime">
                                            <img
                                                className="position-absolute custom-input-icon"
                                                src="images/clock-icon.svg"
                                                alt="clock-icon"
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </label>
                                        <div className="pt-1">
                                            <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.location_provider_quiet_hrs_end_time}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <h5 id="trackingDisconnectionEscalationSec" className="font-18 font-semibold">
                            Tracking Disconnection Escalation
                        </h5>
                        <p className="font-regular font-14 helper-text">
                            Specify the interval for escalating the source when tracking is
                            disconnected
                        </p>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="font-semibold form-text-color font-14 pb-1"
                                htmlFor="ELDHrs"
                            >
                                ELD Hrs <span className="required-color"> *</span>
                            </label>
                            <select
                                style={{ cursor: 'pointer' }}
                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                aria-label="Default select example"
                                value={tmsConfiguration.system_configuration.eld_check_interval_hours}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        system_configuration: {
                                            ...prevState.system_configuration,
                                            eld_check_interval_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                        }
                                    }))
                                }
                            >
                                <option>Select</option>
                                {eldDisconnectionHrs.map((hour, index) => (
                                    <option key={index} value={`${hour}`}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.eld_check_interval_hours}</label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="  font-semibold form-text-color font-14 pb-1"
                                htmlFor="DriverApplicationHrs"
                            >
                                Driver Application Hrs <span className="required-color"> *</span>
                            </label>
                            <select
                                style={{ cursor: 'pointer' }}
                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                aria-label="Default select example"
                                value={tmsConfiguration.system_configuration.driver_app_check_interval_hours}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        system_configuration: {
                                            ...prevState.system_configuration,
                                            driver_app_check_interval_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                        }
                                    }))
                                }
                            >
                                <option>Select</option>
                                {driverApplicationHrs.map((hour, index) => (
                                    <option key={index} value={`${hour}`}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.driver_app_check_interval_hours}</label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                            <label
                                className="  font-semibold form-text-color font-14 pb-1"
                                htmlFor="LocationProviderHrs"
                            >
                                Location Provider Hrs <span className="required-color"> *</span>
                            </label>
                            <select
                                style={{ cursor: 'pointer' }}
                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                aria-label="Default select example"
                                value={tmsConfiguration.system_configuration.location_provider_check_interval}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        system_configuration: {
                                            ...prevState.system_configuration,
                                            location_provider_check_interval: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                        }
                                    }))
                                }
                            >
                                <option>Select</option>
                                {locationProviderDisconnectHrs.map((hour, index) => (
                                    <option key={index} value={`${hour}`}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.location_provider_check_interval}</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <h5 className="font-18 font-semibold">Reason for Delay</h5>
                        <p className="font-regular font-14 helper-text">
                            Specify the reason codes and their corresponding descriptions to be selected
                            in the event of a delay.
                        </p>
                        <div className="table-responsive theme-table mb-4 px-0 custom-table-scroll">
                            <table className="table table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Reason Code</th>
                                        <th className="text-nowrap">Reason for Delay</th>
                                        <th className="text-nowrap text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-nowrap">
                                            <input
                                                type="text"
                                                className="form-control custom-form-input primary-bg-color primary-border-color"
                                                id="Username"
                                                placeholder="Enter Reason Code"
                                                value={delayReason.error_code}
                                                onKeyDown={e => { if (e.key === "Enter") handleAddReason() }}
                                                maxLength={32}
                                                onChange={(e) => {
                                                    const value = e.target.value.trimStart();
                                                    setDelayReason(prevState => ({
                                                        ...prevState,
                                                        error_code: value,
                                                    }));
                                                    // Clear error message when user starts typing
                                                    if (value) {
                                                        setTmsErrorDetails(prevErrors => ({
                                                            ...prevErrors,
                                                            delay_reasons: {
                                                                ...prevErrors.delay_reasons,
                                                                error_code: ''
                                                            }
                                                        }));
                                                    }
                                                }}
                                            />
                                            <div className="pt-1">
                                                <label className="required-color font-12 font-regular"> {tmsErrorDetails.delay_reasons?.error_code ? tmsErrorDetails.delay_reasons?.error_code : ""}</label>
                                            </div>

                                        </td>
                                        <td className="text-nowrap">
                                            <input
                                                type="text"
                                                className="form-control custom-form-input primary-bg-color primary-border-color"
                                                id="Username"
                                                placeholder="Enter Reason for Delay"
                                                value={delayReason.reason}
                                                onKeyDown={e => { if (e.key === "Enter") handleAddReason() }}
                                                maxLength={64}
                                                onChange={(e) => {
                                                    const value = e.target.value.trimStart();
                                                    setDelayReason(prevState => ({
                                                        ...prevState,
                                                        reason: value,
                                                    }));
                                                    // Clear error message when user starts typing
                                                    if (value) {
                                                        setTmsErrorDetails(prevErrors => ({
                                                            ...prevErrors,
                                                            delay_reasons: {
                                                                ...prevErrors.delay_reasons,
                                                                reason: ''
                                                            }
                                                        }));
                                                    }
                                                }}
                                            />
                                            <div className="pt-1">
                                                <label className="required-color font-12 font-regular"> {tmsErrorDetails.delay_reasons?.reason ? tmsErrorDetails.delay_reasons?.reason : ""}</label>
                                            </div>

                                        </td>
                                        <td className="text-center">
                                            <button type="button" className="border-0 m-0 px-3 bg-transparent" onClick={handleAddReason}>
                                                <img src="images/red-plus-icon.svg" alt="Add" title="Add" />
                                            </button>
                                        </td>
                                    </tr>
                                    {tmsConfiguration.delay_reasons?.map((reason, index) => (
                                        <tr key={index}>
                                            {currentReason === index ? (
                                                <>
                                                    <td className="text-nowrap">
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form-input primary-bg-color primary-border-color"
                                                            id="reasonCode"
                                                            placeholder="Enter Reason Code"
                                                            onKeyDown={e => { if (e.key === "Enter") handleConfirmEditReason() }}
                                                            value={delayReasonAtTimeOfEdit.error_code}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                setDelayReasonAtTimeOfEdit(prev => ({
                                                                    ...prev,
                                                                    error_code: value
                                                                }));
                                                            }}
                                                        />
                                                        <div className="pt-1">
                                                            <label className="required-color font-12 font-regular">
                                                                {editDelayReasonError.error_code}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form-input primary-bg-color primary-border-color"
                                                            id="reasonDesc"
                                                            placeholder="Enter Reason for Delay"
                                                            onKeyDown={e => { if (e.key === "Enter") handleConfirmEditReason() }}
                                                            value={delayReasonAtTimeOfEdit.reason}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                setDelayReasonAtTimeOfEdit(prev => ({
                                                                    ...prev,
                                                                    reason: value.trimStart()
                                                                }));
                                                            }}
                                                        />
                                                        <div className="pt-1">
                                                            <label className="required-color font-12 font-regular">
                                                                {editDelayReasonError.reason}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td className="text-center d-flex justify-content-center">
                                                        <button
                                                            type="button"
                                                            className="border-0 m-0 border-end px-3 bg-transparent"
                                                            onClick={() => handleConfirmEditReason()}
                                                        >
                                                            <img
                                                                src="images/tick-icon 1.svg"
                                                                alt="Tick"
                                                                title="Tick"
                                                            />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="border-0 m-0 bg-transparent px-3"
                                                            onClick={() => handleCancelEditReason()}
                                                        >
                                                            <img
                                                                src="images/close-icon 1.svg"
                                                                alt="Cancel"
                                                                title="Cancel"
                                                            />
                                                        </button>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className="text-nowrap">{reason.error_code}</td>
                                                    <td className="text-nowrap">{reason.reason}</td>
                                                    <td className="text-center d-flex justify-content-center">
                                                        <button
                                                            type="button"
                                                            className="border-0 m-0 border-end px-3 bg-transparent"
                                                            onClick={() => handleEditReason(index)}
                                                        >
                                                            <img
                                                                src="images/edit-icon.svg"
                                                                alt="Edit"
                                                                title="Edit"
                                                            />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="border-0 m-0 bg-transparent px-3"
                                                            onClick={() => handleDeleteReason(index)}
                                                        >
                                                            <img
                                                                src="images/delete-icon.svg"
                                                                alt="Delete"
                                                                title="Delete"
                                                            />
                                                        </button>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-end mb-3 pe-2">
                            <button type="button" className="btn text-btn font-semibold me-3" onClick={handleTMSCancel}>
                                Cancel
                            </button>
                            <button type="button" className="btn primary-btn font-semibold px-4" onClick={handleTMSSubmit}>
                                Save
                            </button>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default TMSConfigurationDetails;