import { TimeLine } from "../../../interface/LoadInterface";
import { getTimestampToDate } from "../../../utils/Formatting";

const TrackingEventLogs: React.FC<{ logs: TimeLine[] }> = ({ logs }) => {
    function bindEventLogs() {
        // PS_20 - 21
        // Ensure logs is defined and then map over it
        return logs?.length > 0
            ?
            [...logs]?.reverse()?.map((log, index) => (
                <tr key={index}> {/* Use the index as a key for each row */}
                    <td data-bs-toggle="tooltip" data-bs-placement="top" title={log?.event_creation_time ? `${getTimestampToDate(log?.event_creation_time, 5)}` : "-"}>
                        <span>{log?.event_creation_time ? getTimestampToDate(log?.event_creation_time, 18) : "-"}</span>
                    </td>
                    <td>{log?.event_name ? log?.event_name : "-"}</td>
                </tr>)) :
            <>
                <tr>
                    <td colSpan={2}>
                        <div className="d-flex w-100 justify-content-center">
                            <div className="text-center py-3 mt-3">
                                <span className="no-records-found"></span>
                                <h5 className="font-20 font-semibold mt-2 color-black-v1">
                                    No Data Found</h5>
                            </div>
                        </div>
                    </td>
                </tr>
            </>
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-container">
                        <h3 className="font-18 font-semibold primary-text-color mb-4">Tracking Timeline Log</h3>
                        <div className="table-responsive theme-table less-space-table">
                            <table className="table table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>
                                            Date
                                            <img className="ms-2 tooltip-img"
                                                src="/images/tooltip-icon.svg" alt="tooltip-icon"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Hover the date to see the year" />
                                        </th>
                                        <th className="text-nowrap">Event</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Call the bindEventLogs function here to insert table rows */}
                                    {bindEventLogs()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrackingEventLogs;