import React, { useEffect, useRef, useState } from 'react';
import { DriverDetail, DriverDetailsProps } from "../../../interface/LoadInterface";
import { Messages } from './Messages';

const DriverDetails: React.FC<DriverDetailsProps> = ({
    driver, openDriver, loadId, tmsLoadNumber, onError, openPopup, vendorLoadId, closeNewMessage, alertMessage
}) => {

    // Default ThirdPartyDetails object with empty strings
    const defaultDetails: DriverDetail = {
        driver_id: 0,
        driver_name: "",
        driver_number: "",
        driver_number_ext: ""
    };

    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null); // Create a ref for your popup element

    // Check if driverDetail is null or has the same structure as defaultDetails
    const hasValidDetails = driver && (JSON.stringify(driver) !== JSON.stringify(defaultDetails));

    const closePopup = () => setIsMessageOpen(false);

    useEffect(() => {
        // PS_16-Event listener function that checks if click is outside the popup
        const handleOutsideClick = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                // If clicked outside, close the popup
                closePopup();
            }
        };

        if (isMessageOpen) {
            // PS_17 Attach the event listener if the popup is open
            document.addEventListener('mousedown', handleOutsideClick);
        }

        // PS_17Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isMessageOpen]); // PS_16 Re-run only if `isPopupOpen` state changes
    return (
        <div className="col-12 col-md-6 mb-3">
            <div className="content-container driver-details h-100">
                <h3 className="font-18 font-semibold mb-4 primary-text-color">Driver Details</h3>
                {hasValidDetails ? (
                    <div className="icons-container">
                        <span>
                            <h5 className="font-14 font-semibold primary-text-color mb-2">{driver?.driver_name}</h5>
                            <p className="font-12 font-semibold table-text-color mb-2">
                                {driver?.driver_number_ext ? `(${driver?.driver_number_ext})` : ""} {driver?.driver_number}
                            </p>
                        </span>
                        <span className="icon-continer-size">
                            {vendorLoadId !== "" && (
                                <div ref={popupRef} className="dropdown d-flex justify-content-end">
                                    <button
                                        id="end-point-config"
                                        className="btn cust-message-popover button-group-btn chat-btn px-4 border-radius-3 d-flex justify-content-center align-items-center w-25"
                                        type="button"
                                        aria-expanded="true"
                                        onClick={() => {
                                            setIsMessageOpen(!isMessageOpen);
                                            closeNewMessage();
                                        }}
                                    >
                                        {!isMessageOpen && vendorLoadId !== "" && (
                                            <img src="/images/chat-icon.svg" className="chat-icon" alt="chat-icon" />
                                        )}
                                        {isMessageOpen && vendorLoadId !== "" && (
                                            <img src="/images/cross-chat-icon.svg" alt="Cross-icon" className="cross-icon" />
                                        )}
                                        <span
                                            id="popoverButton"
                                            className="btn btn-popover p-0 button-group-btn chat-btn d-flex justify-content-center align-items-center"
                                            data-bs-placement="top"
                                            title=""
                                            data-bs-content="You have a new message waiting for you."
                                            data-bs-original-title="New Message"
                                            aria-describedby="popover970449"
                                        ></span>
                                    </button>
                                    <div
                                        className="popover cust-msg-popover bs-popover-end"
                                        role="tooltip"
                                        id="popover970449"
                                    >
                                        <div
                                            className="popover-arrow cust-popover"
                                            style={{ position: 'absolute', top: '0px', transform: 'translate3d(0px, 25.6px, 0px)' }}
                                        ></div>
                                        <h3 className="popover-header">New Message</h3>
                                        <div className="popover-body">You have a new message waiting for you.</div>
                                    </div>
                                    <ul
                                        className={isMessageOpen ? "dropdown-menu chat-drpdown-menu p-0 top-position secondary-bg-color show" : "dropdown-menu chat-drpdown-menu p-0 top-position secondary-bg-color"}
                                        data-popper-placement="top-end"
                                    >
                                        {isMessageOpen && (
                                            <Messages
                                                driverId={driver.driver_id}
                                                tmsLoadNumber={tmsLoadNumber}
                                                driverName={driver.driver_name}
                                                loadId={loadId}
                                                onError={onError}
                                                onClose={closePopup}
                                                alertMessage={(error: string) => {
                                                    alertMessage(error);
                                                    closePopup();
                                                }} />
                                        )}
                                    </ul>
                                </div>
                            )}
                            <button className="btn button-group-btn" onClick={() => openDriver()}>
                                <img src="/images/history-icon.svg" alt="history-icon" className="history-icon" width="20px" height="20px" />
                            </button>
                            <button className="btn button-group-btn" onClick={() => openPopup()}>
                                <img src="/images/edit-icon.svg" alt="edit-icon" className="history-icon" width="20px" height="20px" />
                            </button>
                        </span>
                    </div>
                ) : (
                    <div className="h-100px">
                        <div className="d-flex flex-row justify-content-center align-items-center h-inherit">
                            <span className="no-recordsc-found small me-2"></span>
                            <p className="font-13 font-semibold color-black-v1 m-0">No Data Found</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DriverDetails;