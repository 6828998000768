import moment, { now } from 'moment';

// Function to format time from timestamp to expected format
export function getTimestampToDate(timestamp: string, type: number) {
    // Create a moment object from the input timestamp
    const date = moment(timestamp);

    // Check if the timestamp is valid
    if (!date.isValid()) {
        return null; // Return null if the timestamp is invalid
    }

    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');

    // Switch statement to return formatted date based on type
    switch (type) {
        // MMM DD Format: Abbreviated month name followed by the day of the month
        case 1:
            return date.format('MMM DD');
        // YYYY-MM-DD Format: Full year, followed by the month (two digits), and the day (two digits)
        case 2:
            return date.format('YYYY-MM-DD');
        // MMM DD, YYYY Format: Abbreviated month name followed by the day of the month, and the full year
        case 3:
            return date.format('MMM DD, YYYY');
        // DD-MMM-YYYY Format: Day of the month followed by an abbreviated month name, and the full year
        case 4:
            return date.format('DD-MMM-YYYY');
        // MM-DD-YYYY Format: Month (two digits), followed by the day (two digits), and the full year
        case 5:
            return date.format('MM-DD-YYYY');
        // HH:mm Format: Hour (two digits) in 24-hour format, followed by minutes (two digits)
        case 6:
            return date.format('HH:mm');
        // MMM DD Format: Abbreviated month name followed by the day of the month
        case 7:
            return date.format('DD/MM');
        case 8:
            return date.format('YYYY');
        // YYYY-MM-DD HH:mm:ssZ Format: Full datetime with timezone
        case 9:
            return date.format('YYYY-MM-DD HH:mm:ssZ');
        // Return null for unknown type
        // MM/DD Format: Month (two digits) followed by the day (two digits)
        case 10:
            return date.format('MM/DD');
        // MM/DD HH:mm Format: Month (two digits), followed by the day (two digits), and time in 24-hour format
        case 11:
            return date.format('MM/DD HH:mm');
        case 12:
            const localDateValue = date.local();
            const now = moment();
            const duration = moment.duration(localDateValue.diff(now));
            const minutes = Math.abs(duration.asMinutes());
            const hours = Math.abs(duration.asHours());
            if (minutes < 60) {
                return `${Math.floor(minutes)} min${minutes !== 1 ? 's' : ''}`; // Singular/plural for minute
            } else {
                return `${Math.floor(hours)} hour${hours !== 1 ? 's' : ''}`; // Singular/plural for hour
            }       
        case 13:
            return date.format('DD-MM-YYYY');
        // Return null for unknown type
        case 14:
            return date.format('hh:mm A');
        case 15:
            // Today or Yesterday
            if (date.isSame(today, 'day')) {
                return 'Today';
            } else if (date.isSame(yesterday, 'day')) {
                return 'Yesterday';
            } else {
                return date.format('MMM DD'); // Fall back to MMM DD for other dates
            }
        case 16:
            const f = moment.utc(date).toDate();
            return moment(f).format('HH:mm')

        case 17:
            const r = moment.utc(date).toDate();
            return moment(r).format('MM/DD')
        case 18:
            // Get the user's local timezone
            const localDate = date.local()
            return localDate.format('MMM D, YYYY - HH:mm');
        default:
            return null;
    }
}

//PSFD-01_07
export function formatDuration(durationInSeconds: number) 
{
    const days = Math.floor(durationInSeconds / (24 * 60 * 60));
    const hours = Math.floor((durationInSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((durationInSeconds % (60 * 60)) / 60);

    if (days > 0) {
        return `${days}d ${hours}h ${minutes}m`;
    } else if (hours > 0) {
        return `${hours}h ${minutes}m`;
    } else {
        return `${minutes}m`;
    }
}

export const getTimeZoneAbbreviation = (timezone: string): string => {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
        timeZone: timezone,
        timeZoneName: 'short',
    };

    // Format the date according to the given timezone
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    // Extract the time zone abbreviation (e.g., CST, CDT, EST, BST)
    const timeZoneAbbreviation = formattedDate.split(' ').pop() || '';

    return timeZoneAbbreviation;
};


export function calculateTimeDifference(etaUtc: string): number {
    const now = new Date();
    const eta = new Date(etaUtc);
    return Math.floor((eta.getTime() - now.getTime()) / 1000); // difference in seconds
}