import React from 'react';
import { RequireAuthProps } from '../../interface/Interface';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { RoleEnum } from '../../enums/enums';

//To validate role 
export const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
    const location = useLocation();

    const navigate = (route: string) => <Navigate to={route} state={{ from: location }} replace />
    const accessToken = localStorage.getItem("accessToken") || ""
    let role = ""
    if (accessToken) {
        let decodedToken = jwtDecode(accessToken) as { [key: string]: any };
        var groups = decodedToken["cognito:groups"];
        if (groups) {
            role = groups[0]
        }
    }

    return (
        accessToken!="" ?
            allowedRoles.includes(role)
                ? <Outlet />
                : role === RoleEnum.ETM_ADMIN ? navigate("/tms-details") : navigate("/loadMetrics")
            : navigate("/")
    );
}
