import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RoleEnum, Theme } from '../enums/enums';
import { CompanyOptions } from '../interface/Interface';
import { getCompanies, getUserDetailsAgainstCompany, retrieveUserDetails, updateUserTheme } from '../service/Api';
import AlertComponent from './Resuables/AlertComponent';
//PS_22
import Loader from './Resuables/Loader';
import { SomethingWentWrong } from './Resuables/SomethingWentWrong';
import { useTheme } from './ThemeContext';

export const HeaderComponent = () => {

  const [company, setCompany] = useState<CompanyOptions[]>([]);
  let [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [openSomethingWentWrongPopup, setOpenSomethingWentWrongPopup] = useState(false);
  //PC_RP_09-PC_RP_10-Hooks for navigation
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken") || "";
  let decodedToken = jwtDecode(accessToken) as { [key: string]: any };
  let groups = decodedToken["cognito:groups"];

  const { changeTheme } = useTheme();

  //PC-43 switch theme method is invoked on click of the sun or moon image.
  const switchTheme = (theme: Theme) => {
    const newTheme = theme;
    if (changeTheme) {
      changeTheme(newTheme);
    }
  }

  useEffect(() => {
    //SQ_5
    switchTheme(localStorage.getItem("theme") == Theme.DARK ? Theme.DARK : Theme.LIGHT)
    //PS_05
    if (localStorage.getItem("isImpersonate") == "true" || groups[0] == RoleEnum.ETM_STAFF) {
      //SQ_H_1.7
      getCompany()
    }
  }, [])
  //SQ_H_1.7 - 1.15
  const getCompany = async () => {
    try {
      let response = await getCompanies();

      if (response?.data?.code === 200) {
        setCompany(response.data.data);

        let tmsIdFromLocalStorage = localStorage.getItem("tmsId");

        let selectedCompanyId = (tmsIdFromLocalStorage && tmsIdFromLocalStorage !== "0")
          ? tmsIdFromLocalStorage
          : response.data.data[0]?.tms_id;

        if (!tmsIdFromLocalStorage || tmsIdFromLocalStorage === "0") {
          localStorage.setItem("tmsId", selectedCompanyId)
          window.location.reload()
        }

        setSelectedCompanyId(selectedCompanyId);
      }
    }
    catch (error) {
      setCompany([])
      console.log(error);
    }
  }

  const handleLogout = () => {
    try {
      let accessToken = localStorage.getItem("accessToken")
      if (accessToken) {

        const decodedToken = jwtDecode(accessToken) as { [key: string]: any };
        const userGroups = decodedToken["cognito:groups"];

        let emailID = localStorage.getItem("emailId")
        if (emailID) {
          const poolData = {
            UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
            ClientId: process.env.REACT_APP_CLIENT_ID || "",
          };
          const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
          const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: emailID,
            Pool: userPool,
          });

          cognitoUser.signOut();
          localStorage.clear()
          navigate(userGroups && userGroups[0] === RoleEnum.ETM_ADMIN ? "/admin" : "/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updateTheme(themeType: string) {
    try {
      await updateUserTheme(themeType);
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompanyChange = async (event: any) => {
    selectedCompanyId = event.target.value;
    setSelectedCompanyId(selectedCompanyId);
    await getUserInfo(selectedCompanyId);
    localStorage.setItem("tmsId", selectedCompanyId);
    if (/^\/loadmetrics\/loads\/\d+$/.test(location?.pathname)) {
      navigate("/loadmetrics")
      return
    }
    window.location.reload()
  };

  //PS_6 invokde the getuserInfo
  const getUserInfo = async (tmsId: string) => {
    try {
      const response = await getUserDetailsAgainstCompany(tmsId);
      // PS_07-Store the user information in session storage
      if (response.data.code == 200) {
        localStorage.setItem("tmsId", response?.data?.data?.tms_id);
        localStorage.setItem('username', response?.data?.data?.user_name);
        localStorage.setItem('tmsname', response?.data?.data?.tms_name);
        localStorage.setItem("emailId", response?.data?.data?.email_id)
      }
    } catch (error) {
      console.log(error)
    }
  };
  const exitImpersonate = async () => {
    //PS_07exit impersonate
    setLoader(true)
    try {
      const response = await retrieveUserDetails();
      // Store the user information in session storage
      if (response.data.code == 200) {
        localStorage.setItem("tmsId", response?.data?.data?.tms_id)
        localStorage.setItem("tmsname", response?.data?.data?.tms_name)
        localStorage.setItem("username", response?.data?.data?.user_name)
        localStorage.setItem("emailId", response?.data?.data?.email_id)
        localStorage.removeItem("isImpersonate")
        navigate('/tms-details')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoader(false)
    }
  }
  //PS_14
  return (
    <>
      {
        loader && <Loader />
      }
      {
        openSomethingWentWrongPopup && <SomethingWentWrong onClose={() => setOpenSomethingWentWrongPopup(false)} />
      }
      <div className='headerstatic'>
        {
          <nav className="navbar navbar-expand-md body py-2 shadow-sm">
            <div className="container-fluid" >
              <a className="navbar-brand ms-2 d-flex text-decoration-none align-items-center primary-text-color"
                href={groups[0] === RoleEnum.ETM_ADMIN ? "/tms-details" : "/loadmetrics"}> <span className="Nav-logo logo"></span><label className="font-16 ms-2 font-bold">ETM</label></a>
              <div className="navbar-collapse collapse" id="navbarsExample04" >
                <ul className="navbar-nav me-auto mb-2 mb-md-0 gap-md-4 ms-md-4">
                  {groups[0] === RoleEnum.ETM_ADMIN && localStorage.getItem("isImpersonate") != "true" ?
                    <li className="nav-item">
                      <a className={window.location.pathname.includes("/tms-details") || window.location.pathname.includes("/tms-creation") ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/tms-details">3PL</a>
                    </li> : <></>}

                  {groups[0] === RoleEnum.THIRD_PARTY_STAFF || groups[0] === RoleEnum.ETM_STAFF || groups[0] === RoleEnum.THIRD_PARTY_ADMIN || localStorage.getItem("isImpersonate") == "true" ?
                    <li className="nav-item">
                      <a className={window.location.pathname.includes("/loadmetrics") ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/loadmetrics">Loads</a>
                    </li> : <></>}

                  {groups[0] === RoleEnum.THIRD_PARTY_STAFF || groups[0] === RoleEnum.THIRD_PARTY_ADMIN || localStorage.getItem("isImpersonate") == "true" ?
                    <li className="nav-item">
                      <a className={window.location.pathname.includes("/carrier-information") ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/carrier-information">Carriers</a>
                    </li> : <></>}
                  <li className="nav-item">
                    <a className={window.location.pathname.includes("/reports") ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/reports">Reports</a>
                  </li>
                  {groups[0] === RoleEnum.ETM_ADMIN || groups[0] === RoleEnum.THIRD_PARTY_ADMIN ?
                    <li className="nav-item">
                      <a className={window.location.pathname.includes("/manageusers") ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/manageusers">Manage Users</a>
                    </li> : <></>}
                </ul>
                <ul className="list-unstyled mb-0 d-block d-md-flex align-items-center">
                  <li className="d-md-block d-none">
                    <label htmlFor="switch-toggle" className="darktheme-switch primary-btn me-3">
                      <input type="checkbox" className="d-none" id="switch-toggle" checked={localStorage.getItem("theme") == Theme.DARK} />
                      <div className="icon icon--sun">
                        <img className="theme-outline p-1" src="/images/sun-icon.svg" alt="sun-icon" onClick={async () => { await updateTheme(Theme.LIGHT); switchTheme(Theme.LIGHT) }} />
                      </div>
                      <div className="icon icon--moon">
                        <img className="theme-outline p-1" src="/images/moon-icon.svg" alt="moon-icon" onClick={async () => { await updateTheme(Theme.DARK); switchTheme(Theme.DARK) }} />
                      </div>
                    </label>
                  </li>
                  {(localStorage.getItem("isImpersonate") == "true" || groups[0] == RoleEnum.ETM_STAFF) ?
                    <li>
                      <div className="form-floating">
                        <select className="form-select custom-form-input custom-header-filter select-filter custom-select-arrow font-12" id="floatingSelect" aria-label="Floating label select example" value={selectedCompanyId}
                          onChange={(e) => handleCompanyChange(e)}>
                          {company.map((companyItem, index) => (
                            <option key={companyItem.tms_id} value={companyItem.tms_id}>
                              {companyItem.name}
                            </option>
                          ))}
                        </select>
                        <label className="font-11 top-nav-label-color pt-3 pb-0 font-regular text-nowrp" htmlFor="floatingSelect">Company Name</label>
                      </div>
                    </li> : <></>}
                  <li>
                    <div className="btn-group ms-0 ms-md-3 ">
                      <button type="button" className="d-flex align-items-center border-0 bg-transparent shadow-none py-2 primary-text-color font-14 font-medium dd-arrow-rotate" data-bs-toggle="dropdown" aria-expanded="false">
                        {localStorage.getItem("username")}
                        <img src="/images/profile-arrow.svg" alt="accordion-arrow" className="dd-arrow ms-2" data-bs-toggle="collapse" data-bs-target="#ReportAcc1" aria-expanded="false" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-md-end primary-bg-color p-3 shadow-sm">
                        <li className="text-center">
                          <p className="primary-text-color mb-1 font-14 font-medium">{localStorage.getItem("username")}</p>
                          <p className="font-12 font-regular mb-0 top-nav-label-color m-0 mb-2">{localStorage.getItem("emailId")}</p>
                        </li>
                        <li className="nav-border">
                          <button className="btn dropdown-item text-center my-2 link-color link-btn" onClick={() => { navigate('/profile') }} type="button">Profile</button>
                          <button className="btn dropdown-item text-center my-2 link-color link-btn" onClick={() => { window.open(`${process.env.REACT_APP_BASE_ELB_URL}docs`, '_blank'); }} type="button">API Documentation</button>
                        </li>
                        {localStorage.getItem("isImpersonate") != "true" ? (<li><button className="btn dropdown-item text-center mt-2 link-btn logout-btn" type="button" onClick={handleLogout}>
                          <img src="/images/logout-icon.svg" className="logout-icon-color me-2" />
                          Logout</button></li>) : (

                          <li><button className="btn dropdown-item text-center mt-2 link-btn logout-btn" type="button" onClick={exitImpersonate}>
                            <img src="/images/logout-icon.svg" className="logout-icon-color me-2" />
                            End Impersonation</button></li>)}
                      </ul>
                    </div>
                    {
                      //PS_39
                      (localStorage.getItem("isAPIKeyValid") == "false") ?
                        <div>
                          <AlertComponent errHeading={'Alert'}
                            err={`Your software license has been expired, so please contact
          the administrator to renew your license for to continue
          using the application without interruption.`}
                            onClose={handleLogout}></AlertComponent>
                        </div> : (localStorage.getItem("isCompanyActive") == "false") ? <div>
                          <AlertComponent errHeading={'Alert'}
                            err={`Sorry, you are unauthorized to access this application`}
                            onClose={handleLogout}></AlertComponent>
                        </div> : <></>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        }
      </div>
    </>
  );
}