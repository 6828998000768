import React from "react";
import { AlertProp } from "../../interface/Interface";

const AlertComponent = (props: AlertProp) => {
    const renderErrorMessage = (message: string) => {
        return message.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index < message.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };
    return (
        <div aria-hidden="true"
            className="modal fade show"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-modal="true"
            role="dialog"
            style={{ display: "block", paddingLeft: 0, backgroundColor: "rgba(0, 0, 0, .5)" }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content secondary-bg-color">
                    <div className="modal-body pt-4">
                        <div className="row text-center justify-content-center">
                            <img
                                src="/images/alert-icon.svg"
                                alt="alert-icon"
                                className="alert-icon p-0"
                            />
                            <h1 className="primary-header mt-3" id="exampleModalLabel">{props.errHeading}</h1>
                            <p className="form-text-color font-16 font-regular">{renderErrorMessage(props.err)}</p>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center pb-4 pt-0 border-0">
                        <button type="button" className="btn primary-btn font-semibold px-4" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlertComponent