import React from "react";
import { CustomValue } from "../../../interface/LoadInterface";
//PS_10 - 11
const CustomValues: React.FC<{ customValues: CustomValue[] }> = ({ customValues }) => {
    return (
        <div className="col-12 col-md-6 mb-3">
            <div className="content-container scroll-list-details h-100">
                <h3 className="font-18 font-semibold primary-text-color mb-3">Custom Values</h3>
                {customValues && customValues.length > 0 ? (
                    <ul className="h-100px">
                        {customValues?.map((value, index) => (
                            <li key={index} className="font-14 font-medium table-text-color mb-3">
                                {value?.custom_key ? value?.custom_key : "-"}:<span className="font-14 font-semibold primary-text-color ms-1">{value?.custom_value ? value?.custom_value : "-"} </span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="h-100px">
                        <div className="d-flex flex-row justify-content-center align-items-center h-inherit">
                            <span className="no-records-found small me-2"></span>
                            <p className="font-13 font-semibold color-black-v1 m-0">No Data Found</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CustomValues;