import React, { useState, useEffect } from 'react';
import { Event, MessagesProps } from "../../../interface/LoadInterface";
import { getDriverMessagesAPI, sendMessageAPI, updateDriverMessageReadStatus } from '../../../service/Api';
import { getTimestampToDate } from '../../../utils/Formatting';
import { DriverMessage } from '../../../interface/Interface';

export const Messages: React.FC<MessagesProps> = ({ loadId, tmsLoadNumber, driverId, driverName, onError, onClose, alertMessage }) => {
    const [events, setEvents] = useState<Event[]>([]);
    const [loader, setLoader] = useState(false);
    const [textMessage, setTextMessage] = useState("");
    const tmsId: string = (localStorage.getItem("tmsId") || "0");

    useEffect(() => {
        fetchEvents();
        updateMessageReadStatus()
    }, []);


    /*PS_03 using useEffect, declare a function handleEscapeKey
    with event as paramter, if event.key is escape, then set state the value
    of setConfirmDeleteOpen to false. and to check for Escape key, use document.addEventListener */
    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);


    //PS_21-PS_23
    const fetchEvents = async () => {
        setLoader(true);
        try {
            const response = await getDriverMessagesAPI(tmsId, loadId);
            if (response?.data?.code === 200) {
                setEvents(response?.data?.data);
            } else if (response?.data?.code === 500) {
                onError();
            }
        } catch (error) {
            console.error('Error fetching events:', error);
            onError();
            onClose();
        } finally {
            setLoader(false);
        }
    };

    const updateMessageReadStatus = async () => {
        try {
            const response = await updateDriverMessageReadStatus(tmsId, loadId);
            if (response?.data?.code !== 200) {
                console.error('Error in service updating the driver message status:', response);
            }
        } catch (error) {
            console.error('Error in execution of updating the driver message status:', error);
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const newValue = e.target.value.trimStart(); // Trim leading/trailing spaces


        setTextMessage(newValue);

    }
    const handleSubmit = () => {
        let request: DriverMessage = {
            etm_load_id: loadId,
            tms_load_number: tmsLoadNumber,
            message: textMessage,
        }
        sendMessage(request);
    }

    async function sendMessage(request: DriverMessage) {
        setTextMessage("");
        try {
            setLoader(true);
            let result = await sendMessageAPI(request);
            if (result?.data?.code == 200) {
                fetchEvents();
            } else if ((result?.data?.code == 400 || result?.data?.code == 422)) {
                alertMessage(result?.data?.errors[0]?.message)
            } else {
                onError();
                onClose();
            }
        } catch (error) {
            console.log("Error while sending message:", error);
            onError();
            onClose();
        } finally {
            setLoader(false);
        }
    }

    const getInitials = (name: string) => {
        const parts = name?.split(' ');
        return parts?.map(part => part?.charAt(0)?.toUpperCase()).join('');
    };


    const bindEvents = () => {
        let currentDate: string = '';
        return (
            <div className="chat-container p-3">
                {events.map((event, index) => {
                    const eventDate = getTimestampToDate(event.event_time, 15);
                    const showDateLabel = eventDate !== currentDate;
                    currentDate = eventDate || '';
                    const [sender, receiver] = event?.participant?.split(' to ');
                    const is3PLReceiver = receiver?.includes("ETM");
                    return (
                        <React.Fragment key={index}>
                            {showDateLabel && (
                                <div className="d-flex justify-content-center mb-3">
                                    <label className="font-10 font-regular primary-text-color">{eventDate}</label>
                                </div>
                            )}
                            {event.event_type === 'driver_change' ? (
                                <div className="text-center my-2">
                                    <p className="font-medium font-10 primary-text-color">
                                        {(() => {
                                            const [fromDriver, toDriver] = event.participant.split(' to ');
                                            return (
                                                <>
                                                    <span className="driver-profile me-2">{getInitials(fromDriver)}</span>
                                                    {fromDriver}
                                                    <span className="px-2"><img src="/images/grey-right-arrow.svg" alt="Arrow" /></span>
                                                    <span className="driver-profile me-2">{getInitials(toDriver)}</span>
                                                    {toDriver}
                                                </>
                                            );
                                        })()}
                                    </p>
                                </div>
                            ) : (
                                <div className={is3PLReceiver ? 'd-flex justify-content-start' : 'd-flex justify-content-end'}>
                                    <div className={is3PLReceiver ? 'pe-2 incoming-chat-wrapper' : 'pe-2 outgoing-chat-wrapper'}>
                                        <div className="text-start chat-text position-relative d-flex gap-2 font-12 primary-text-color font-regular">
                                            {event.event_detail}
                                        </div>
                                        <div className={is3PLReceiver ? "text-start" : "text-end"}>
                                            <span className="font-10 font-regular primary-text-color">{getTimestampToDate(event.event_time, 14)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            <h4 className="p-3 font-medium font-14 mb-0 primary-border-bottom primary-text-color">Message to Driver</h4>
            {loader ?
                <div className="d-flex justify-content-center w-100 py-5">
                    <div className="spinner-border Loader align-center " role="status">
                        <span className="visually-hidden"></span>
                    </div> </div> : bindEvents()}
            <div className="p-3">
                <div className="message-footer p-2 m-3 d-flex justify-content-center align-items-center">
                    <input
                        type="text"
                        className="form-control message-input border-0 primary-text-color"
                        id="text"
                        placeholder="Write your message here..."
                        aria-describedby="text"
                        value={textMessage}
                        onChange={handleOnChange}
                        onKeyDown={e => { if (e.key === "Enter" && textMessage) handleSubmit() }}
                    />
                    <button type="button" className="border-0 bg-transparent" disabled={!textMessage} onClick={handleSubmit}>
                        <img src="/images/send-icon.svg" alt="Send" className="send-icon" />
                    </button>
                </div>
            </div>
        </div>
    );
};
