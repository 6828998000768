import React, { useEffect, useState } from 'react';
import { DriverDetails, DriverHistoryProps } from '../../../interface/LoadInterface';
import { ReportFilter } from '../../../interface/ReportsInterface';
import { getDrivers } from '../../../service/Api';
import { getTimestampToDate } from '../../../utils/Formatting';
import { initializeTooltips } from '../../../utils/utils';
import Loader from '../../Resuables/Loader';
import NoDataFound from '../../Resuables/NoDataFound';

const DriverHistory: React.FC<DriverHistoryProps> = ({ loadId, onClose, onError }) => {
  const [driverDetails, setDriverDetails] = useState<DriverDetails[]>([]);
  const [loader, setLoader] = useState(false);
  const tmsId: number = Number(localStorage.getItem("tmsId") || 0);

  const [filterRequest, setFilterRequest] = useState<ReportFilter>({
    search_data: '',
    from_date: '',
    to_date: '',
    sort_column: '',
    sort_type: '',
    start_index: 1,
    page_size: 10
  });

  useEffect(() => {
    initializeTooltips();
  }, []);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  useEffect(() => {
    getDriverDetails();
  }, [tmsId, loadId, filterRequest.sort_column, filterRequest.sort_type, onError]);

  const getDriverDetails = async () => {
    setLoader(true);
    try {
      const response = await getDrivers(tmsId, loadId, filterRequest);
      if (response.data.code === 200) {
        setDriverDetails(response.data.data);
        setLoader(false);
        initializeTooltips();
      } else {
        onError();
        setLoader(false);
        setDriverDetails([]);
      }
    } catch (error) {
      setLoader(false);
      onError();
      setDriverDetails([]);
    }
  };

  const handleSortClick = (column: string) => {
    setFilterRequest((prevState) => {
      if (prevState.sort_column === column) {
        // If the same column is clicked again, toggle the sort order
        return {
          ...prevState,
          sort_column: column,
          sort_type: prevState.sort_type === 'asc' ? 'desc' : 'asc',
        };
      } else {
        // If a different column is clicked, reset the sort order to 'asc'
        return {
          ...prevState,
          sort_column: column,
          sort_type: 'asc',
        };
      }
    });
  };

  const renderSortArrow = (column: string) => {
    if (filterRequest.sort_column === column) {
      return filterRequest.sort_type === 'asc' ? (
        <img src="/images/sort-dark-up-arrow (1).svg" alt="sort-arrow-up" className="cust-sort-arrow-color" />
      ) : (
        <img src="/images/sort-down-arrow-white.svg" alt="sort-arrow-down" className="cust-sort-arrow-color" />
      );
    }
    return <img src="/images/sort-down-arrow-white.svg" alt="sort-arrow-down" className="cust-sort-arrow-color" />;
  };

  return (
    <>
      {loader && <Loader />}
      <div className="modal-dialog modal-dialog-centered modal-table-poup">
        <div className="modal-content secondary-bg-color p-2 px-3">
          {/* Modal header */}
          <div className="modal-header border-0">
            <h1 className="modal-title fs-5 font-20 secondary-header font-18 font-semibold primary-text-color" id="exampleModalLabel">
              Driver History
            </h1>
            <button type="button" className="btn-close close-icon" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
          </div>
          {/* Modal body */}
          <div className="modal-body pt-2">
            <div className="row">
              <div className="table-responsive theme-table p-0 custom-table-scroll">
                <table className="table table-borderless mb-0 primary-shadow">
                  <thead className="sticky-top">
                    <tr>
                      <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('driver_name')}>
                        Driver Name<span className="ms-1">{renderSortArrow('driver_name')}</span>
                      </th>
                      <th className="text-nowrap">Phone #</th>
                      <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('is_active')}>
                        Status<span className="ms-1">{renderSortArrow('is_active')}</span>
                      </th>
                      <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('added_by')}>
                        Added By
                        <img className="tooltip-img ms-1" src="/images/tooltip-icon.svg" alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Hover the name to see the role" />
                        <span className="ms-1">{renderSortArrow('added_by')}</span>
                      </th>
                      <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('added_on')}>
                        Added On
                        <img className="tooltip-img ms-1" src="/images/tooltip-icon.svg" alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Hover the date to see the year" />
                        <span className="ms-1">{renderSortArrow('added_on')}</span>
                      </th>
                      <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('removed_by')}>
                        Removed By
                        <img className="tooltip-img ms-1" src="/images/tooltip-icon.svg" alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Hover the name to see the role" />
                        <span className="ms-1">{renderSortArrow('removed_by')}</span>
                      </th>
                      <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('removed_on')}>
                        Removed On
                        <img className="tooltip-img ms-1" src="/images/tooltip-icon.svg" alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Hover the date to see the year" />
                        <span className="ms-1">{renderSortArrow('removed_on')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {driverDetails.length > 0 ? (
                      driverDetails.map((driver, index) => (
                        <tr key={`${driver.driver_id}-${index}`}>
                          <td className="text-nowrap">{driver.driver_name}</td>
                          <td className="text-nowrap">{driver.phone_number.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')}</td>
                          <td className="text-nowrap">
                            <span className="d-flex align-items-center">
                              <span className={`me-2 ${driver.is_active ? 'green-legend' : 'red-legend'}`} />
                              <span>{driver.is_active ? 'Active' : 'Inactive'}</span>
                            </span>
                          </td>
                          <td className="text-nowrap cursor-pointer">
                            <span data-bs-toggle="tooltip" data-bs-placement="top" title={driver.added_by_user_role}>
                              {driver.added_by}
                            </span>
                          </td>
                          <td className="text-nowrap cursor-pointer">
                            {driver.added_on ?
                              <span data-bs-toggle="tooltip" data-bs-placement="top" title={getTimestampToDate(driver.added_on, 5) || ''}>
                                {`${getTimestampToDate(driver.added_on, 10)} ${getTimestampToDate(driver.added_on, 6)}`}
                              </span>
                              : "-"
                            }
                          </td>
                          <td className="text-nowrap cursor-pointer">
                            {driver.removed_by && !driver.is_active ?
                              <span data-bs-toggle="tooltip" data-bs-placement="top" title={driver.removed_by_user_role}>
                                {driver.removed_by}
                              </span> : "-"}
                          </td>
                          <td className="text-nowrap cursor-pointer">
                            {driver.removed_on && !driver.is_active ?
                              <span data-bs-toggle="tooltip" data-bs-placement="top" title={getTimestampToDate(driver.removed_on, 5) || ''}>
                                {`${getTimestampToDate(driver.removed_on, 10)} ${getTimestampToDate(driver.removed_on, 6)}`}
                              </span>
                              : "-"
                            }
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoDataFound />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0 pt-0"></div>
        </div>
      </div>
    </>
  );
};

export default DriverHistory;