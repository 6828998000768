function NoResultsFound() {
    return (
        <>
            <tr>
                <td colSpan={8} >
                    <div className="d-flex w-100 justify-content-center">
                        <div className="text-center my-5 py-5">
                            <span className="no-results-found"></span>
                            <h5 className="font-20 font-semibold mt-2 color-black-v1">No Results Found</h5>
                            <p className="font-18 font-medium placeholder-color">Please try again with another keyword.</p>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default NoResultsFound;