//Enum to store roles
export enum Theme {
  DARK = "DARK",
  LIGHT = "LIGHT"
}

export enum RoleEnum {
  ETM_ADMIN = "ETM-Admin",
  ETM_STAFF = "ETM-Staff",
  THIRD_PARTY_ADMIN = "Third-Party-Admin",
  THIRD_PARTY_STAFF = "Third-Party-Logistics-Staff",
}

export enum InviteStatus {
  ACCEPTED = "ACCEPTED",
  INVITED = "INVITED"
}

export enum EventsStatus {
  ArriverdAtCustomer = "Arrived at Customer",
  ArrivedAtConsignee = "Arrived at Consignee",
  ArrivedAtStop = "Arrived at Stop",
  MarkAsLoaded = "Mark as Loaded",
  MarkAsDelivered = "Mark as Delivered",
  InTransit = "In Transit",
  Delivered = "Delivered",
  Note = "Note",
  NotStartedYet = "Not Yet Started",
  NeedStaffAttention = "Need Staff Attention",
  Cancel = "Cancelled",
  DepartedStop = "Departed Stop"
}

export enum eventType {
  Equipment = "EQUIPMENT_UPDATE",
  driverChange = "DRIVER_CHANGED",
  trackingEvent = "TRACKING_EVENT",
  InformationEvent = "INFORMATION_EVENT",
  LocationEvent = "LOCATION_EVENT",
  event = "EVENT"
}

export enum MoveType {
  TL = "TL",
  LTL = "LTL"
}

export enum TokenType {
  EXPIRED = "TEMPORARY",
  NONEXPIRED = "PERMANENT"


}

export enum StopType {
  PICK = "PICK",
  DROP = "DROP"
}

export enum TrackingProvider {
  TEXTLOCATE = "TextLocate",
  TRUCKERTOOLS = "TruckerTools",
  MACROPOINT = "MacroPoint"
}

export enum TrackingMethod {
  TRUCK = "TRUCK",
  TRAILER = "TRAILER"

}

export enum CommunicationPlatform {
  EMAIL = "EMAIL",
  SLACK = "SLACK",
  TEAMS = "TEAMS"

}

export enum NotificationDetail {
  FIVE_HOURS = 5,
  SIX_HOURS = 6,
  SEVEN_HOURS = 7,
  EIGHT_HOURS = 8
}

export enum SecondTierTrackingProviders {
  ONE_HOUR = 1,
  TWO_HOURS = 2,
  THREE_HOURS = 3,
  FOUR_HOURS = 4
}

export enum SupportEscalationHours {
  ONE_HOUR = 1,
  TWO_HOURS = 2,
  THREE_HOURS = 3,
  FOUR_HOURS = 4,
  FIVE_HOURS = 5,
}

export enum EldDisconnectionHours {
  ONE_HOUR = 1,
  TWO_HOURS = 2,
  THREE_HOURS = 3,
  FOUR_HOURS = 4,
}

export enum DriverApplicationHours {
  ONE_HOUR = 1,
  TWO_HOURS = 2,
  THREE_HOURS = 3,
  FOUR_HOURS = 4,
  FIVE_HOURS = 5,
}

export enum LocationProviderDisconnectionHours {
  FIVE_HOURS = 5,
  SIX_HOURS = 6,
  SEVEN_HOURS = 7,
}

export enum AuthenticationMethod {
  BASIC_AUTHENTICATION = 'BASIC',
  OAUTH_AUTHENTICATION = 'OAUTH',

}

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  EXPIRES_SOON = 'EXPIRES_SOON',

}

export enum ConnectionStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',

}

export enum TrackingStatus {
  ELD = "ELD",
  DRIVERAPP = "Driver Application",
  MANUAL = "Manual",
  NOTSTARTED = "Not Yet Started"
}

export const AuthenticationTypeDisplaytext: Record<string, string> = {
  [AuthenticationMethod.BASIC_AUTHENTICATION]: "Basic Authentication",
  [AuthenticationMethod.OAUTH_AUTHENTICATION]: "OAuth 2.0 Authentication"
};

export const ConnectionStatusDisplaytext: Record<string, string> = {
  [ConnectionStatus.ONLINE]: "Online",
  [ConnectionStatus.OFFLINE]: "Offline"
};

export const CompanyStatusDisplaytext: Record<string, string> = {
  [CompanyStatus.ACTIVE]: "Active",
  [CompanyStatus.INACTIVE]: "Inactive"
};

export const InvitationStatusDisplayText: Record<string, string> = {
  [InviteStatus.ACCEPTED]: "Accepted",
  [InviteStatus.INVITED]: "Invited"
};

export const TrackingMethodDisplaytext: Record<string, string> = {
  [TrackingMethod.TRUCK]: "Truck",
  [TrackingMethod.TRAILER]: "Trailer"
};


export const CommunicationPlatformDisplaytext: Record<string, string> = {
  [CommunicationPlatform.EMAIL]: "Email",
  [CommunicationPlatform.SLACK]: "Slack",
  [CommunicationPlatform.TEAMS]: "Teams"
};

export const TrackingVendorCodes: Record<string, string> = {
  [TrackingProvider.MACROPOINT]: "MACROPOINT",
  [TrackingProvider.TRUCKERTOOLS]: "TRUCKERTOOLS",
  [TrackingProvider.TEXTLOCATE]: "TEXTLOCATE"
};

export const TrackingEventCodes : Record<string, string> ={
  [EventsStatus.NotStartedYet]:"ETM001",
  [EventsStatus.ArriverdAtCustomer]:"ETM002",
  [EventsStatus.MarkAsLoaded]:"ETM003",
  [EventsStatus.InTransit]:"ETM004",
  [EventsStatus.ArrivedAtStop]:"ETM005",
  [EventsStatus.ArrivedAtConsignee]:"ETM006",
  [EventsStatus.Delivered]:"ETM007",
  [EventsStatus.NeedStaffAttention]:"ETM008",
  [EventsStatus.Cancel]:"ETM009",
  [EventsStatus.DepartedStop]:"ETM010",
}