import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CancelTracking,
  Events,
  LoadInfo,
  Stop,
  LoadFilter,
  DropDownOptions,
} from "../../interface/LoadInterface";
import {
  cancelTrackingAPI,
  getCarrierOptions,
  getCustomerOptions,
  getLoadDetails,
  getStatus,
  getTrackingMethods,
} from "../../service/Api";
import { getTimestampToDate, formatDuration } from "../../utils/Formatting";
import BreadCrumbs from "../Resuables/BreadCrumbs";
import Loader from "../Resuables/Loader";
import NoDataFound from "../Resuables/NoDataFound";
import NoResultsFound from "../Resuables/NoResultsFound";
import PaginationComponent from "../Resuables/PaginationComponent";
import { SomethingWentWrong } from "../Resuables/SomethingWentWrong";
import TimeFrame from "../Resuables/TimeFrame";
//PS_NV_03
import Select, { ActionMeta } from "react-select";
import { EventsStatus, eventType } from "../../enums/enums";
import ConfirmationPopUp from "../Resuables/ConfirmationPopUp";
import EventPopup from "../Resuables/EventPopUp";
import { SuccessToast } from "../Resuables/SuccessToast";
import { getmoveTypeDescription, initializeTooltips } from "../../utils/utils";
import { ReportFilter } from "../../interface/ReportsInterface";
import { ReactSelectOptions } from "../../interface/Interface";

function LoadInformation() {
  const navigate = useNavigate();
  const location = useLocation();

  //PS_01 - PS_06
  //To set intital value for LoadsFilters object
  let loadFilterEmptyValues: LoadFilter = {
    load_status_code: "",
    tracking_status_code: "",
    carrier_id: "",
    customer_id: "",
    from_origin: "",
    to_origin: "",
    from_destination: "",
    to_destination: "",
  }
  let loadFilterObj: LoadFilter = {
    load_status_code: location?.state?.load_status_code || "",
    tracking_status_code: location?.state?.tracking_status_code || "",
    carrier_id: location?.state?.carrier_id || "",
    customer_id: location?.state?.customer_id || "",
    from_origin: location?.state?.from_origin || "",
    to_origin: location?.state?.to_origin || "",
    from_destination: location?.state?.from_destination || "",
    to_destination: location?.state?.to_destination || "",
  };

  let reportFilterObj: ReportFilter = {
    start_index: location?.state?.start_index || 1,
    page_size: location?.state?.page_size || 10,
    from_date: location?.state?.from_date || "",
    to_date: location?.state?.to_date || "",
    search_data: location?.state?.search_data || "",
    sort_type: location?.state?.sort_type || "asc",
    sort_column: location?.state?.sort_column || "next_stop_arrival_time",
  };

  const breadcrumbData = [
    { title: "Load Metrics", isActive: true, path: "/loadmetrics" },
    { title: "Loads", isActive: false, path: "" },
  ];
  const tmsId: number = Number(localStorage.getItem("tmsId") || 0);
  //To store Filter values
  const [loadFilter, setLoadFilter] = useState<LoadFilter>(loadFilterObj);
  const [reportFilter, setReportFilter] =
    useState<ReportFilter>(reportFilterObj);

  //To store filter values temporarliy
  const [tempSearch, setTempSearch] = useState<string>(String(location.state?.search_data || ''));
  const [tempAdvancedFilter, setTempAdvancedFilter] = useState<LoadFilter>(loadFilterObj);

  //To store response from API
  const [loadDetails, setLoadDetails] = useState<LoadInfo[]>([]);

  //To show and hide loaders
  const [loader, setLoader] = useState<Boolean>(false);
  //To show and hide error popup
  const [errorPopUp, setErrorPopup] = useState<Boolean>(false);
  //To show total count of loads that matches the filter
  const [totalCount, setTotalCount] = useState<number>(0);
  const [noDataFound, setNoDataFound] = useState<Boolean>(false);
  const [noResultsFound, setNoResultsFound] = useState<Boolean>(false);
  //PS_AC_02
  const [showAccordion, setShowAccordion] = useState<Boolean[]>([]);

  //PS_DD_02 Declaring state variables to store dropdown values.
  const [statusDropDowns, setStatusDropDown] = useState<DropDownOptions[]>([]);
  const [trackingStatusDropDown, setTrackingStatusDropDown] = useState<DropDownOptions[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<ReactSelectOptions[]>([]);
  const [shipperOptions, setShipperOptions] = useState<ReactSelectOptions[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState<Events>({
    loadId: 0,
    tmsId: 0,
    trackingeventId: 0,
    eventType: "",
    event: "",
    eventCode: "",
    inTime: "",
    outTime: "",
    notes: "",
    isManual: true,
    reasonCode: "",
    reasonForDelay: "",
    addressLine1: "",
    city: "",
    state: "",
    onTime: true,
  });
  const [eventPopup, setEventPopup] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState("");
  const [cancelTrackingPayload, SetCancelTrackingPayload] = useState<CancelTracking>({ etm_load_id: 0, tms_load_number: "" });
  const [confirmPopUp, setConfirmPopUp] = useState<Boolean>(false);
  const [timeFrameKey, setTimeFrameKey] = useState(location?.state?.key);

  //PS_DD_05 All the dropDowns functions are called in this function.
  useEffect(() => {
    Promise.all([
      getStatusOptions(),
      getCarriers(),
      getCustomers(),
      getTrackingStatus(),
      initializeTooltips()
    ]);
  }, []);

  useEffect(() => {
    getLoad()
  }, [loadFilter, reportFilter]);

  useEffect(() => {
    if (toastMessage != "") {
      const timer = setTimeout(() => {
        setToastMessage(""); // Hide the toast message after 10 seconds
      }, 3000);

      return () => clearTimeout(timer); // Clear the timer on component unmount or when openToastMessage changes
    }
  }, [toastMessage]);

  //PS_08 - 21 function to getLoads
  async function getLoad() {
    //PS_09
    setLoader(true);
    try {
      //PS_10
      let result = await getLoadDetails(tmsId, reportFilter, loadFilter);
      navigate(location.pathname, {
        state: {
          ...location.state,
          from_date: reportFilter.from_date,
          to_date: reportFilter.to_date,
          key: timeFrameKey,
          search_data: reportFilter.search_data,
          from_destination: loadFilter.from_destination,
          to_destination: loadFilter.to_destination,
          from_origin: loadFilter.from_origin,
          to_origin: loadFilter.to_origin,
          sort_type: reportFilter.sort_type,
          sort_column: reportFilter.sort_column,
          tracking_status_code: loadFilter.tracking_status_code,
          load_status_code: loadFilter.load_status_code,
          carrier_id: loadFilter.carrier_id,
          customer_id: loadFilter.customer_id,
          start_index: reportFilter.start_index,
          page_size: reportFilter.page_size,
        },
        replace: true, // This replaces the current entry in the history stack
      });

      if (result?.data?.code == 200) {
        setLoadDetails(result?.data?.data);
        setTotalCount(result?.data?.record_count);
        setNoDataFound(false);
        setNoResultsFound(false);
      } else if (result?.data?.code == 404) {
        if (
          JSON.stringify(loadFilter) === JSON.stringify(loadFilterObj) &&
          JSON.stringify(reportFilter) === JSON.stringify(reportFilterObj)
        ) {
          setNoResultsFound(false);
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
          setNoResultsFound(true);
        }
        setLoadDetails([]);
        setTotalCount(0);
      } else {
        setNoDataFound(true);
        setLoadDetails([]);
        setTotalCount(0);
        setErrorPopup(true);
      }
    } catch (error) {
      console.log(error)
      setErrorPopup(true);
    } finally {
      setLoader(false);
      setShowAccordion([]);
      initializeTooltips();
    }
  }
  // Function to calculate the hours difference between the current time and the provided base time
  function hoursDifference(baseTime: string): string {
    // PS_D_02: Declare currentTime and assign a new instance of Date
    const currentTime = new Date();

    // PS_D_03: Parse `baseTime` into a Date object as `baseTimeDate`
    const baseTimeDate = new Date(baseTime);

    // PS_D_04: Get milliseconds of `currentTime` using `getTime`
    const currentTimeMillis = currentTime.getTime();

    // PS_D_05: Get milliseconds of `baseTimeDate` using `getTime`
    const baseTimeMillis = baseTimeDate.getTime();

    // PS_D_06: Calculate difference in milliseconds
    const timeDifferenceMillis = currentTimeMillis - baseTimeMillis;

    // PS_D_07: Convert `timeDifferenceMillis` to hours as `hoursDiff`
    const secondDiff = timeDifferenceMillis / 1000; // Milliseconds to seconds

    // PS_D_08: Return `hoursDiff` in React component, rounded to two decimal places for readability
    // Determine status
    let status = "";
    if (secondDiff < 0) {
      // Use Math.abs() to get the positive value
      status = `Scheduled in ${formatDuration(Math.abs(secondDiff))}`;
    } else if (secondDiff > 0) {
      // Use Math.abs() to ensure it's positive
      status = `Delayed by ${formatDuration(Math.abs(secondDiff))}`;
    } else {
      status = "On time";
    }
    return status;
  }
  const handleSortClick = (column: string) => {
    setReportFilter((prevState) => {
      if (prevState.sort_column === column) {
        // If the same column is clicked again, toggle the sort order
        return {
          ...prevState,
          sort_column: column,
          sort_type: prevState.sort_type === "asc" ? "desc" : "asc",
          start_index: 1,
        };
      } else {
        // If a different column is clicked, reset the sort order to 'asc'
        return {
          ...prevState,
          sort_column: column,
          sort_type: "asc",
          start_index: 1,
        };
      }
    });
  };
  const renderSortArrow = (column: string) => {
    if (reportFilter.sort_column === column) {
      return reportFilter.sort_type === "asc" ? (
        <img
          src="/images/sort-arrow-up.svg"
          alt="sort-arrow-up"
          className="cust-sort-arrow-color"
        />
      ) : (
        <img
          src="/images/sort-down-arrow-white.svg"
          alt="sort-arrow-down"
          className="cust-sort-arrow-color"
        />
      );
    }
    return (
      <img
        src="/images/sort-down-arrow-white.svg"
        alt="sort-arrow-down"
        className="cust-sort-arrow-color"
      />
    );
  };
  const handleEventPop = (Id: number, event: string, eventCode: string) => {
    const stopDetails = loadDetails.find(
      (loadDetails) => loadDetails.load_id == Id
    );
    const events: Events = {
      loadId: stopDetails?.load_id || 0,
      tmsId: tmsId,
      eventType: eventType.trackingEvent,
      event: event,
      eventCode: eventCode,
      stopId: stopDetails?.current_stop.id,
      inTime: event === EventsStatus.Note ? new Date().toISOString() : "",
      outTime: "",
      notes: "",
      reasonCode: "",
      reasonForDelay: "",
      addressLine1: "",
      addressLine2: "",
      isManual: true,
      city: "",
      state: "",
      onTime: true,
    };
    setEventDetails(events);
    setEventPopup(true);
  };

  async function cancelTracking(loadId: number, loadNumber: string) {
    try {
      setLoader(true);
      setConfirmPopUp(false);
      let cancelTracking: CancelTracking = {
        etm_load_id: loadId,
        tms_load_number: loadNumber,
      };
      let response = await cancelTrackingAPI(cancelTracking);
      if (response?.data?.code == 200) {
        setToastMessage("Tracking has been cancelled Successfully");
        getLoad();
      } else {
        setErrorPopup(true);
      }
    } catch (error) {
      console.log(error, "Error in CancelTracking");
      setErrorPopup(true);
    } finally {
      setLoader(false);
    }
  }

  const formatStopAddress = (stop: Stop | undefined): string => {
    if (!stop) return "-";

    const { city, country } = stop;
    if (!city && !country) return "-";

    return [city, country].filter(Boolean).join(", ") || "-";
  };

  //PS_BL_01 - PS_BL_07
  //PS_AC_01 - PS_AC_04
  //PS_NV_01 - PS_NV_02
  function bindLoads() {
    return loadDetails?.map((loads, index) => (
      <>
        <tr className="border-0 ">
          <td className="pe-0">
            <span
              className="d-flex align-items-center gap-3"
              onClick={() => {
                const newShowAccordion = [...showAccordion]; // Create a new array
                newShowAccordion[index] = !newShowAccordion[index]; // Update the specific index
                setShowAccordion(newShowAccordion);
              }}
            >
              <img
                src="/images/accordion-arrow.svg"
                alt="accordion-arrow"
                className={
                  showAccordion[index]
                    ? "cursor-pointer table-accordion-arrow"
                    : "cursor-pointer table-accordion-arrow collapsed"
                }
                data-bs-toggle="collapse"
                data-bs-target={`#loadacc${index}`}
              />
            </span>
          </td>
          <td className="text-nowrap ps-2">
            <a
              className="text-decoration-none link-color cursor-pointer"
              href={`/loadmetrics/loads/${loads?.load_id}`}
              onClick={() => {
                navigate(`/loadmetrics/loads/${loads?.load_id}`, {
                  state: location.state,
                });
              }}
            >
              {loads?.tms_load_number || "-"}
            </a>
          </td>
          <td className="text-nowrap">
            <p className="mb-0 primary-text-color font-medium font-14">
              {loads?.status || "-"}
            </p>
            <p className="mb-0 font-regular font-12 color-grey-v1">
              {loads?.tracking_description || ""}
            </p>
          </td>
          <td className="text-nowrap primary-text-color font-regular font-14">
            <span>{loads?.tracking_status || "-"}</span>
            {/* {loads.truckId ? <img src="/images/ELD-icon.svg" title="Truck" alt="ELD" className="ms-2 tracking-status-eld-img" />
                            : loads.trailerId ? <img src="/images/ELD-container.svg" title="Trailer" alt="ELD" className="ms-2 tracking-status-eld-img" /> : <></>} */}
          </td>
          <td className="text-nowrap">
            <p
              className="mb-0 primary-text-color font-medium font-14"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-original-title={
                loads?.origin_stop?.time
                  ? `${getTimestampToDate(loads?.origin_stop?.time, 5)}`
                  : ""
              }
            >
              {formatStopAddress(loads?.origin_stop)}
            </p>
            <p
              className="mb-0 font-regular font-12 color-grey-v1"
              title={
                loads?.origin_stop?.time
                  ? `${getTimestampToDate(loads?.origin_stop?.time, 5)}`
                  : ""
              }
            >
              {getTimestampToDate(loads?.origin_stop?.time, 10) || ""}
            </p>
          </td>
          <td className="text-nowrap">
            <p
              className="mb-0 primary-text-color font-medium font-14"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-original-title={
                loads?.destination_stop?.time
                  ? `${getTimestampToDate(
                    loads?.destination_stop.time,
                    5
                  )}`
                  : ""
              }
            >
              {formatStopAddress(loads?.destination_stop)}
            </p>
            <p className="mb-0 font-regular font-12 color-grey-v1">
              {loads?.destination_stop?.time
                ? getTimestampToDate(loads?.destination_stop.time, 10)
                : ""}
            </p>
          </td>
          <td className="text-nowrap">
            <p className="mb-0 primary-text-color font-regular font-14">
              {loads?.next_stop_arrival_time
                ? getTimestampToDate(loads?.next_stop_arrival_time, 6)
                : ""}
            </p>
            <p
              className={`mb-0 font-regular font-12 ${loads?.next_stop_arrival_time
                ? hoursDifference(loads?.next_stop_arrival_time).includes(
                  "Delayed"
                )
                  ? "red-legend-color"
                  : "green-legend-color"
                : "-"
                }`}
            >
              {loads?.next_stop_arrival_time
                ? hoursDifference(loads?.next_stop_arrival_time)
                : "-"}
            </p>
          </td>
          <td className="text-nowrap text-center">
            <div className="dropdown d-flex justify-content-center">
              <button
                id="end-point-config"
                className="btn kebab-btn border-radius-3 d-flex justify-content-center w-25"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <img src="/images/kebab-icon.svg" alt="kebab-icon" />
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end border-0 shadow-sm p-2 secondary-bg-color"
                data-popper-placement="top-end"
                style={{
                  position: "absolute",
                  inset: "auto auto 0px 0px !important",
                  margin: "0px",
                  transform: "translate(0px, -41.7743px)",
                }}
              >
                {statusDropDowns
                  .filter(
                    (stop) =>
                      ![
                        "Cancelled",
                        "Mark as Delivered",
                        "Need Staff Attention",
                        "Not Yet Started",
                        "Departed Stop",
                      ].includes(stop.display_text)
                  )
                  .map((stop, index) => (
                    <li
                      key={index}
                      className="font-14 font-medium color-black"
                      onClick={() =>
                        handleEventPop(loads.load_id, stop.display_text, stop.code)
                      }
                    >
                      <a className="dropdown-item py-2 custom-dropdown-li">
                        {stop.display_text}
                      </a>
                    </li>
                  ))}
                <li className="font-14 font-medium color-black">
                  <a
                    className="dropdown-item py-2 custom-dropdown-li"
                    onClick={() => handleEventPop(loads.load_id, EventsStatus.Note, "")}
                  >
                    Add Note
                  </a>
                </li>
                <li className="font-14 font-medium">
                  <a
                    className="dropdown-item py-2 custom-dropdown-li  red-legend-color"
                    onClick={() => {
                      setConfirmPopUp(true);
                      SetCancelTrackingPayload({
                        etm_load_id: loads?.load_id,
                        tms_load_number: loads?.tms_load_number,
                      });
                    }}
                  >
                    Cancel Tracking
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td
            colSpan={8}
            className={
              showAccordion[index]
                ? "collapse show pt-0 px-5"
                : "collapse pt-0 px-5"
            }
            data-testid={`#loadacc${index}`}
            id={`#loadacc${index}`}
          >
            <div className="table-responsive theme-table shadow-sm">
              <table className="table table-borderless mb-0">
                <thead>
                  <tr>
                    <th className="text-nowrap">Driver</th>
                    <th className="text-nowrap">Carrier</th>
                    <th className="text-nowrap">Customer</th>
                    <th className="text-nowrap">Tracking Provider</th>
                    <th className="text-nowrap">Move Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nowrap">
                      <p className="mb-0 primary-text-color font-medium font-14">
                        {loads?.driver?.name || "-"}
                      </p>
                      <p className="mb-0 font-regular font-12 color-grey-v1">
                        {loads?.driver?.number && loads?.driver?.number
                          ? `${loads?.driver.country_code}-${loads?.driver.number}`
                          : ""}
                      </p>
                    </td>
                    <td className="text-nowrap">
                      <p className="mb-0 primary-text-color font-medium font-14">
                        {loads?.carrier?.name || "-"}
                      </p>
                      <p className="mb-0 font-regular font-12 color-grey-v1">
                        {loads?.carrier?.email ? loads?.carrier.email : ""}
                      </p>
                    </td>
                    <td className="text-nowrap">
                      <p className="mb-0 primary-text-color font-medium font-14">
                        {loads?.customer?.name || "-"}
                      </p>
                      <p className="mb-0 font-regular font-12 color-grey-v1">
                        {loads?.customer?.email ? loads?.customer.email : ""}
                      </p>
                    </td>
                    <td className="text-nowrap">
                      {loads?.tracking_provider || "-"}
                    </td>
                    <td className="text-nowrap">
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-original-title={
                          loads?.move_type
                            ? `${getmoveTypeDescription(
                              loads?.move_type.toUpperCase()
                            )}`
                            : ""
                        }
                      >
                        {loads?.move_type || "-"}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </>
    ));
  }

  //PS_13-16
  const handlePageClick = (data: { selected: number }) => {
    // Update loadFilter state
    setReportFilter((prevState) => ({
      ...prevState,
      start_index: (data.selected * reportFilter.page_size) + 1,
    }));
  };
  //PS_04 - 05 Common onchange for all the filters
  const advancedFilterOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setReportFilter((prevState) => ({
      ...prevState,
      start_index: 1,
      [name]: value.trim(),
    }));
  };

  const assignSearchValue = (searchvalue: string) => {
    setReportFilter((prevState) => ({
      ...prevState,
      start_index: 1,
      search_data: searchvalue.trim(),
    }));
  }

  //PS_DD_05 - 19 Get status dropdown
  async function getStatusOptions() {
    try {
      let result = await getStatus();
      if (result?.data?.code == 200) {
        setStatusDropDown(result?.data?.data);
      } else {
        setStatusDropDown([]);
      }
    } catch (error) {
      console.log("Error while feching status Dropdown :", error);
    }
  }
  //PS_GC_01 - 06 Get Carriers for the particular company.
  async function getCarriers() {
    try {
      //PS_GC_01
      let result = await getCarrierOptions(tmsId);
      if (result?.data?.code == 200) {
        const options = result?.data?.data.map((option: { display_text: any; code: any; }) => ({
          label: option.display_text,
          value: option.code,
        }));
        setCarrierOptions(options);
        if (loadFilter.carrier_id) {
          const initialCarrier = result.data.data.find(
            (option: DropDownOptions) => option.code === loadFilter.carrier_id
          );
        }
      } else {
        setCarrierOptions([]);
      }
    } catch (error) {
      console.log("Error while feching Carriers Dropdown :", error);
    }
  }
  //PS_GC_01 - 06 GetCustomers for the particular company
  async function getCustomers() {
    try {
      //PS_GC_01
      let result = await getCustomerOptions(tmsId);
      if (result?.data?.code == 200) {
        //PS_GC_03 and PS_GC_04 If the statusCode is 200, assign the value to the variable customerData,
        //and if loadFilter.customerId value is present, then using find method, find the value and assign to
        //initialCustomer and if initialCustomer value is present, set state the value for customers.
        const customerData: DropDownOptions[] = result.data.data;
        const options = customerData.map((option: { display_text: any; code: any; }) => ({
          label: option.display_text,
          value: option.code + '-' + option.display_text,
        }));
        setShipperOptions(options);
        if (loadFilter.customer_id) {
          const initialCustomer = customerData.find(
            (option: DropDownOptions) => option.code === loadFilter.customer_id
          );
        }
      } else {
        setShipperOptions([]);
      }
    } catch (error) {
      console.log("Error while feching Customers Dropdown :", error);
    }
  }
  //PS_DD_05 - 19 GetTrackingStatus dropDown, which is static for all the companies.
  async function getTrackingStatus() {
    try {
      let result = await getTrackingMethods();
      if (result?.data?.code == 200) {
        setTrackingStatusDropDown(result?.data?.data);
      } else {
        setTrackingStatusDropDown([]);
      }
    } catch (error) {
      console.log("Error while feching TrackingStatus Dropdown :", error);
      setErrorPopup(true);
    }
  }
  function bindStatus() {
    return statusDropDowns
      .filter((value) => value.display_text !== "Departed from Stop")
      .map((value, index) => (
        <>
          <option key={index} value={`${value.code}`}>
            {value.display_text}
          </option>
        </>
      ));
  }
  function bindTrackingStatus() {
    return trackingStatusDropDown.map((value, index) => (
      <>
        <option key={index} value={`${value.code}`}>
          {value.display_text}
        </option>
      </>
    ));
  }

  //SQ_EX_2.16 - 2.17  PS_17 - 18
  const timeFrameOnChange = (
    startDate: Date | "",
    endDate: Date | "",
    key: string | ""
  ) => {
    setReportFilter((prevState) => ({
      ...prevState,
      from_date:
        startDate === ""
          ? ""
          : getTimestampToDate(startDate.toISOString(), 2) || "",
      to_date:
        endDate === ""
          ? ""
          : getTimestampToDate(endDate.toISOString(), 2) || "",
      start_index: 1
    }));
    setTimeFrameKey(key);
  };

  //PS_NV_06
  const handleCarrier = (
    selectedOptions: ReactSelectOptions | null,
    actionMeta: ActionMeta<ReactSelectOptions>
  ) => {
    // Assuming setLoadFilter exists in your component
    // Update any other state based on selected options
    setTempAdvancedFilter((prevData) => ({
      ...prevData,
      carrier_id: String(selectedOptions?.value || ""),
    }));
  };
  //PS_NV_07
  const handleCustomer = (selectedOptions: any) => {

    // Assuming setLoadFilter exists in your component
    // Update any other state based on selected options
    setTempAdvancedFilter((prevData) => ({
      ...prevData,
      customer_id: String(selectedOptions?.value || ""),
    }));
  };
  const openToast = (messages: string) => {
    setToastMessage(messages);
  };
  const onError = () => {
    setErrorPopup(true);
  };
  const onClose = () => {
    setEventPopup(false);
  };

  return (
    <>
      {confirmPopUp && (
        <ConfirmationPopUp
          heading="Cancel Tracking"
          content="Are you sure, you want to cancel tracking?"
          onClose={() => {
            setConfirmPopUp(false);
          }}
          oKClick={() => {
            cancelTracking(
              cancelTrackingPayload?.etm_load_id,
              cancelTrackingPayload?.tms_load_number
            );
          }}
        ></ConfirmationPopUp>
      )}
      {loader && <Loader />}
      {errorPopUp ? (
        <SomethingWentWrong onClose={() => setErrorPopup(false)} />
      ) : null}
      {eventPopup && (
        <EventPopup
          events={eventDetails}
          openLoader={() => setLoader(true)}
          updateEvent={false}
          closeLoader={() => setLoader(false)}
          onClose={onClose}
          openToast={openToast}
          getLoads={getLoad}
          onError={onError}
        />
      )}
      {toastMessage && SuccessToast(toastMessage)}

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 fixedhd ">
            <div className="px-2 py-4 p-md-4 mb-3 border-0 rounded-3">
              <BreadCrumbs data={breadcrumbData} />
              <div className="d-lg-flex align-items-center justify-content-between mb-4 ">
                <h5 className="color-black-v1 font-22 mb-3 mb-lg-0 font-bold text-nowrap">
                  Loads
                </h5>

                <div className="d-flex gap-3 flex-wrap position-relative">
                  <div className="form-floating w-130px">
                    <select
                      className="form-select custom-form-input select-filter custom-select-arrow font-12 cursor-pointer"
                      //PS_04 Onchange advancedFilter Onchange is called
                      id="floatingSelect"
                      value={String(reportFilter.page_size)}
                      name="page_size"
                      aria-label="Floating label select example"
                      onChange={advancedFilterOnChange}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                    <label
                      className="font-11 top-nav-label-color pt-3 pb-0 font-regular text-nowrp"
                      htmlFor="floatingSelect"
                    >
                      Show Records
                    </label>
                  </div>
                  <div className="form-floating w-130px  ">
                    <select
                      onChange={(e) => {
                        setLoadFilter({ ...loadFilter, tracking_status_code: e.target.value });
                        setReportFilter({ ...reportFilter, start_index: 1 })
                      }}
                      //PS_04 Onchange advancedFilter Onchange is called
                      className="form-select custom-form-input select-filter custom-select-arrow font-12 cursor-pointer"
                      value={loadFilter.tracking_status_code}
                      id="trackingStatus"
                      aria-label="Floating label select example"
                      name="tracking_status"
                    >
                      <option value={""} selected>
                        All
                      </option>
                      {bindTrackingStatus()}
                    </select>
                    <label
                      className="font-11 top-nav-label-color pt-3 pb-0 font-regular text-nowrp"
                      htmlFor="trackingStatus"
                    >
                      Tracking Status
                    </label>
                  </div>
                  {
                    <TimeFrame
                      onChange={timeFrameOnChange}
                      selectedValue={timeFrameKey}
                    />
                  }
                  <div className="d-flex gap-3 flex-row">
                    <div className="input-group input-search">
                      <input
                        type="search"
                        value={tempSearch}
                        name="search"
                        //PSAF_15 Onchange advancedFilter Onchange is called
                        onChange={(e) => {
                          setTempSearch(e.target.value);
                        }}
                        className="form-control border-0 font-14 font-regular primary-bg-color"
                        placeholder="Search"
                        onKeyDown={(e) => {
                          //PSAF_04
                          if (e.key === "Enter") {
                            assignSearchValue(tempSearch)
                          }
                        }}
                      />
                      <button
                        className="btn border-0 search-input-btn primary-bg-color"
                        type="button"
                        onClick={() => assignSearchValue(tempSearch)}>
                        <img
                          src="/images/search-icon.svg"
                          alt="search" />
                      </button>
                    </div>

                    <div className="dropdown">
                      <button
                        className={
                          isFilterOpen
                            ? "btn filter-btn border-0 custom-border-box-shadow primary-bg-color show"
                            : "btn filter-btn border-0 custom-border-box-shadow primary-bg-color"
                        }
                        onClick={() => {
                          setIsFilterOpen(!isFilterOpen);
                        }}
                      >
                        <img src="/images/filter-icon.svg" alt="filter-icon" />
                      </button>

                      <div
                        className={
                          isFilterOpen
                            ? "dropdown-menu dropdown-menu-end border-0 shadow p-3 custom-filter-width primary-bg-color show"
                            : "dropdown-menu dropdown-menu-end border-0 shadow p-3 custom-filter-width primary-bg-color"
                        }
                        aria-labelledby="dropdownMenuLink"
                      >
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <h5 className="font-bold font-20 mb-0 custom-header-color">
                            Advanced Filter
                          </h5>
                          <span>
                            <img
                              className="cursor-pointer close-img"
                              src="/images/cross-icon-black.svg"
                              alt="close"
                              onClick={() => {
                                setIsFilterOpen(!isFilterOpen);
                              }}
                            />
                          </span>
                        </div>

                        <div className="row">
                          <div className="col-sm-12 col-md-12 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color "
                              htmlFor="TrackingType"
                            >
                              Status
                            </label>
                            <select
                              //PSAF_09
                              onChange={(e) => {
                                setTempAdvancedFilter((prevState) => ({
                                  ...prevState,
                                  load_status_code: e.target.value,
                                }));
                              }}
                              id="TrackingType"
                              className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                              value={tempAdvancedFilter.load_status_code}
                              aria-label="Default select example"
                              name="trackingType"
                            >
                              <option value={""}>Select</option>
                              {bindStatus()}
                            </select>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color "
                              htmlFor="carrierList"
                            >
                              Carrier Name
                            </label>
                            {/*/PS_NV_05*/}
                            <Select
                              className="dropdown custom-form-input"
                              name="carrier"
                              maxMenuHeight={150}
                              placeholder="Select"
                              value={carrierOptions && tempAdvancedFilter.carrier_id
                                ? carrierOptions.find(option => option.value === tempAdvancedFilter.carrier_id) || null
                                : null
                              }
                              options={carrierOptions || []}
                              onChange={handleCarrier}
                              isClearable
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color"
                            >
                              Customer Name
                            </label>
                            <Select
                              className="dropdown custom-form-input"
                              name="customer"
                              maxMenuHeight={150}
                              placeholder="Select"
                              value={shipperOptions && tempAdvancedFilter.customer_id
                                ? shipperOptions.find(option => option.value === tempAdvancedFilter.customer_id) || null
                                : null
                              }
                              options={shipperOptions || []}
                              onChange={handleCustomer}
                              isClearable
                            />
                          </div>
                          <p className="form-label font-16 font-semibold label-color">
                            Origin
                          </p>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color "
                              htmlFor="date"
                            >
                              From
                            </label>
                            <div className="position-relative">
                              <input
                                //PSAF_09
                                onChange={(e) => {
                                  setTempAdvancedFilter((prevState) => ({
                                    ...prevState,
                                    from_origin: e.target.value,
                                  }));
                                }}
                                type="date"
                                className="form-control custom-form-input helper-text"
                                id="date"
                                value={tempAdvancedFilter.from_origin}
                                aria-describedby="date"
                                name="originFromDate"
                              />
                              <img
                                className="position-absolute custom-input-icon"
                                src="/images/calander-icon.svg"
                                alt="calander-icon"
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color "
                              htmlFor="todate"
                            >
                              To
                            </label>
                            <div className="position-relative">
                              <input
                                //PSAF_09
                                onChange={(e) => {
                                  setTempAdvancedFilter((prevState) => ({
                                    ...prevState,
                                    to_origin: e.target.value,
                                  }));
                                }}
                                type="date"
                                className="form-control custom-form-input helper-text"
                                id="todate"
                                value={tempAdvancedFilter.to_origin}
                                aria-describedby="date"
                                name="originToDate"
                              />
                              <img
                                className="position-absolute custom-input-icon"
                                src="/images/calander-icon.svg"
                                alt="calander-icon"
                              />
                            </div>
                          </div>
                          <p className="form-label font-16 font-semibold label-color ">
                            Destination
                          </p>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color "
                              htmlFor="destinationFromDate"
                            >
                              From
                            </label>
                            <div className="position-relative">
                              <input
                                //PSAF_09
                                onChange={(e) => {
                                  setTempAdvancedFilter((prevState) => ({
                                    ...prevState,
                                    from_destination: e.target.value,
                                  }));
                                }}
                                type="date"
                                name="destinationFromDate"
                                className="form-control custom-form-input helper-text"
                                id="destinationFromDate"
                                value={tempAdvancedFilter.from_destination}
                                aria-describedby="date"
                              />
                              <img
                                className="position-absolute custom-input-icon"
                                src="/images/calander-icon.svg"
                                alt="calander-icon"
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label
                              className="form-label font-14 font-semibold label-color "
                              htmlFor="destinationToDate"
                            >
                              To
                            </label>
                            <div className="position-relative">
                              <input
                                //PSAF_09
                                onChange={(e) => {
                                  setTempAdvancedFilter((prevState) => ({
                                    ...prevState,
                                    to_destination: e.target.value,
                                  }));
                                }}
                                type="date"
                                name="destinationToDate"
                                className="form-control custom-form-input helper-text"
                                id="destinationToDate"
                                value={tempAdvancedFilter.to_destination}
                                aria-describedby="date"
                              />
                              <img
                                className="position-absolute custom-input-icon"
                                src="/images/calander-icon.svg"
                                alt="calander-icon"
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center py-3">
                              <button
                                className="btn text-nowrap w-md-100 font-medium color-black-v1 font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4"
                                onClick={() => {
                                  //PSAF_13
                                  setLoadFilter(loadFilterEmptyValues);
                                  setTempAdvancedFilter(loadFilterEmptyValues);
                                  setTempSearch("")
                                  setReportFilter({
                                    start_index: 1,
                                    search_data: "",
                                    sort_column: "",
                                    sort_type: "",
                                    from_date: reportFilter.from_date,
                                    to_date: reportFilter.to_date,
                                    page_size: 10,
                                  });
                                  setIsFilterOpen(!isFilterOpen);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={
                                  !tempAdvancedFilter.load_status_code &&
                                  !tempAdvancedFilter.carrier_id &&
                                  !tempAdvancedFilter.customer_id &&
                                  !tempAdvancedFilter.from_origin &&
                                  !tempAdvancedFilter.to_origin &&
                                  !tempAdvancedFilter.from_destination &&
                                  !tempAdvancedFilter.to_destination
                                }
                                className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                                onClick={() => {
                                  //PSAF_11
                                  setReportFilter({ ...reportFilter, start_index: 1 })
                                  setLoadFilter(tempAdvancedFilter);
                                  setIsFilterOpen(!isFilterOpen);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive theme-table overflow-visible">
                <table className="table table-borderless mb-0">
                  <thead>
                    <tr>
                      <th className="pe-0"></th>
                      <th className="text-nowrap ps-2">
                        Load ID
                        <span
                          onClick={() => handleSortClick("tms_load_number")}
                          className="ms-1 cursor-pointer"
                          data-testid="sort-tms_load_number"
                        >
                          {renderSortArrow("tms_load_number")}
                        </span>
                      </th>
                      <th className="text-nowrap">
                        Status
                        <span
                          onClick={() => handleSortClick("status")}
                          className="ms-1 cursor-pointer"
                          data-testid="sort-status"
                        >
                          {renderSortArrow("status")}
                        </span>
                      </th>
                      <th className="text-nowrap">
                        Tracking Status
                        <span
                          onClick={() => handleSortClick("tracking_status")}
                          className="ms-1 cursor-pointer"
                          data-testid="sort-tracking_status"
                        >
                          {renderSortArrow("tracking_status")}
                        </span>
                      </th>
                      <th className="text-nowrap">
                        Origin
                        <img
                          className="ms-2 tooltip-img"
                          src="/images/tooltip-icon.svg"
                          alt="tooltip-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Hover the date to see the year"
                        />
                        <span
                          onClick={() => handleSortClick("origin_stop_time")}
                          className="ms-1 cursor-pointer"
                          data-testid="sort-origin_stop_time"
                        >
                          {renderSortArrow("origin_stop_time")}
                        </span>
                      </th>
                      <th className="text-nowrap">
                        Destination
                        <img
                          className="ms-2 tooltip-img"
                          src="/images/tooltip-icon.svg"
                          alt="tooltip-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Hover the date to see the year"
                        />
                        <span
                          onClick={() => handleSortClick("destination_stop_time")}
                          className="ms-1 cursor-pointer"
                          data-testid="sort-destination_stop_time"
                        >
                          {renderSortArrow("destination_stop_time")}
                        </span>
                      </th>
                      <th className="text-nowrap">
                        Next Stop
                        <span
                          onClick={() => handleSortClick("next_stop_arrival_time")}
                          className="ms-1 cursor-pointer"
                          data-testid="sort-next_stop_arrival_time"
                        >
                          {renderSortArrow("next_stop_arrival_time")}
                        </span>
                      </th>
                      <th className="text-nowrap text-center">Actions </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bindLoads()}
                    {noDataFound ? <NoDataFound /> : <></>}
                    {noResultsFound ? <NoResultsFound /> : <></>}
                  </tbody>
                </table>
              </div>
              <div className="mt-3 d-md-flex justify-content-between">
                <p className="mb-0 font-14 font-regular color-grey-v1 ">
                  # of Records :
                  <span className="color-black-v1 font-semibold primary-header-v1 font-18">
                    {Math.min((reportFilter.start_index - 1) + Number(reportFilter.page_size), totalCount)}{" "}
                  </span>
                  out of
                  <span className="color-black-v1 font-semibold primary-header-v1 font-18">{` ${totalCount}`}</span>
                </p>
                {totalCount > reportFilter.page_size ? (
                  <PaginationComponent
                    currentPage={(reportFilter.start_index - 1) / reportFilter.page_size}
                    viewCount={reportFilter.page_size}
                    totalCount={totalCount}
                    onPageChange={handlePageClick}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoadInformation;
