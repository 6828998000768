

import { Buffer } from 'buffer/';
import CryptoJS from 'crypto-js';
import { InviteStatus, RoleEnum } from "../enums/enums";
import { validateUserKey, insertLoginAudits, retrieveUserDetails, updateInvitationStatus } from "../service/Api";
import { moveTypeDescriptions } from '../Constant';


export const encryptData = (secret: string) => {
    // Check if clientSecret is present
    if (secret) {
        const encryptedMessage = encryptUserId(secret)
        return encryptedMessage;
    }
}
export const decryptData = (secret: string) => {
    // Check if clientSecret is present
    if (secret) {
        const decryptedMessage = decryptUserId(secret)
        return decryptedMessage;
    }
}

export function parseToken(accessToken: string): { userRole: string; userId: string } {

    const tokenParts = accessToken.split('.');
    const encodedPayload = tokenParts[1];
    const decodedPayload = atob(encodedPayload);
    const payloadObject = JSON.parse(decodedPayload);
    return {
        userRole: payloadObject['cognito:groups'][0],
        userId: payloadObject.sub,
    };
}

export const isValidEmail = (email: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[a-zA-Z0-9.-]+$/;
    return emailRegex.test(email);
};


export async function postLoginAPIs(groups: any) {
    try {
        if (groups) {
            if (groups[0] !== RoleEnum.ETM_ADMIN && groups[0] != RoleEnum.ETM_STAFF) {
                //PC_SI_42-PC_SI_46-Method to check API key and config complete check
                let apiKeyResponse = await validateUserKey();
                if (apiKeyResponse?.data?.code == 200) {
                    localStorage.setItem("isAPIKeyValid", apiKeyResponse?.data?.data?.api_valid_till)
                    localStorage.setItem("isConfigurationComplete", apiKeyResponse?.data?.data?.is_configuration_complete)
                    localStorage.setItem("isCompanyActive", apiKeyResponse?.data?.data?.status)
                }
            }
        }
    } catch (error) {
        console.log(error);
    }

    try {
        //PC_SI_30-PC_SI_41-Method to retrieve user details 
        let response = await retrieveUserDetails();
        if (response?.status == 200) {
            localStorage.setItem("theme", response.data.data.theme_type);
            localStorage.setItem("tmsId", response.data.data.tms_id.toString());
            localStorage.setItem("tmsname", response.data.data.tms_name);
            localStorage.setItem("username", response.data.data.user_name);
            localStorage.setItem("emailId", response.data.data.email_id);
        }

    } catch (error) {
        console.log(error);
    }
    try {
        //PC_SI_46-PC_SI_50-Method to insert logs
        let logResponse = await insertLoginAudits();
        if (logResponse?.data.code != 201) {
            console.log(logResponse);
        }
    } catch (error) {
        console.log(error);
    }
}

export const encryptUserId = (userId: string): string => {
    const buffer = Buffer.from(userId, 'utf8');
    const encoded = buffer.toString('base64');
    return encoded;
};
export const decryptUserId = (encoded: string): string => {
    const buffer = Buffer.from(encoded, 'base64');
    const decoded = buffer.toString('utf8');
    return decoded;
};

export const combineDateTimeToTimestamp = (dateTimeString: string): string => {
    const [dateString, timeString] = dateTimeString.split(' ');
    const [year, month, day] = dateString.split('-');
    const timeParts = timeString.split(':');
    const [hours, minutes, seconds = '00'] = timeParts;
    const combinedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return combinedDateTime;
};
export const convertToUTC = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    return date.toISOString();
}

export const userRole = (role: string) => {
    if (role) {
        switch (role) {
            case RoleEnum.THIRD_PARTY_ADMIN: case RoleEnum.ETM_ADMIN:
                return "Admin"
            case RoleEnum.THIRD_PARTY_STAFF: case RoleEnum.ETM_STAFF:
                return "Support Staff"
            default:
                return "-"; // Handle unexpected roles or no role
        }
    } else {
        return null; // Handle missing role
    }
}

// PSH_02 - 03
// To View the move type's Extended form with moveType's abbrevation passed as parameter. 
//moveTypeDescriptions contains the full form as constants.
export const getmoveTypeDescription = (moveType: string): string => {
    return moveTypeDescriptions[moveType] || "-";
}


export const parseTimeString = (timeString?: string): Date | undefined => {
    if (!timeString) return undefined;
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    return date;
};

// Function to get the start of the day
export const getStartOfDay = (): Date => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
};

// Function to get the end of the day
export const getEndOfDay = (): Date => {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
};

// Utility function to format Date object to "HH:mm" string
export const formatTimeString = (date?: Date): string => {
    if (!date) return '';
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const initializeTooltips = () => {
    const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    const tooltipList = tooltipTriggerList.map((tooltipTriggerEl: Element) => {
        return new (window as any).bootstrap.Tooltip(tooltipTriggerEl);
    });
    return () => {
        tooltipList.forEach((tooltip: any) => tooltip.dispose())
    }
};

export function globalPhoneValidator(phoneNumber: string): boolean {
    const defaultCountryCode = "+1"; // Default country code for USA

    // Step 1: Sanitize input (remove spaces, dashes, dots, and parentheses)
    const sanitized = phoneNumber.replace(/[()\s\-\.]/g, "");

    // Step 2: Handle cases starting with '1' but without '+'
    let finalPhoneNumber = sanitized;
    if (finalPhoneNumber.startsWith("1") && !finalPhoneNumber.startsWith("+")) {
        finalPhoneNumber = "+1" + finalPhoneNumber.substring(1);
    }

    // Step 3: Add default country code if it does not start with '+'
    if (!finalPhoneNumber.startsWith("+")) {
        finalPhoneNumber = defaultCountryCode + finalPhoneNumber;
    }

    // Step 4: Validate the sanitized phone number using E.164 regex
    const e164Regex = /^\+?[1-9]\d{10,14}$/;

    // Return whether the phone number matches the E.164 format
    return e164Regex.test(finalPhoneNumber);
}

