//PS_FP_1.1-PS_FP_1.1 import statments
import React, { useEffect, useState } from 'react';
import { EventsStatus, eventType, TrackingEventCodes, TrackingProvider } from '../../../enums/enums';
import { EventResponse, Events, TrackingEvent, TrackingEventProps } from "../../../interface/LoadInterface";
import { changeVisiblityProviders, deleteTrackingEvent } from '../../../service/Api';
import EventPopup from '../../Resuables/EventPopUp';
import Loader from '../../Resuables/Loader';
import { getTimestampToDate } from '../../../utils/Formatting';
import { initializeTooltips } from '../../../utils/utils';
//PS_FP_1.7-PS_FP_1.9 invoke the component and initialize the prop values.
const TrackingEvents: React.FC<TrackingEventProps> = ({ trackingEvents, getLoads, onError, alertMessage, vendorId, openToast, vendorLoadId, loadId, openLoader, closeLoader }) => {
    //PS_FP_1.2-PS_FP_1.3,PS_FP_1.4,PS_FP_1.5 variable declaration
    const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [eventIdToDelete, setEventIdToDelete] = useState<number | null>(null);
    const [selectedVendorId, setSelectedVendorId] = useState<string>('');
    const visibilityProviders = Object.values(TrackingProvider);
    const [loader, setLoader] = useState(false);
    const [eventPopup, setEventPopup] = useState<boolean>(false);
    const tmsId = localStorage.getItem('tmsId');
    const [ddclick, setDDclick] = useState(false);
    const [eventDetails, setEventDetails] = useState<Events>({
        loadId: 0,
        tmsId: 0,
        trackingeventId: 0,
        eventType: '',
        event: '',
        eventCode: '',
        inTime: '',
        outTime: '',
        notes: '',
        isManual: true,
        reasonCode: "",
        reasonForDelay: '',
        addressLine1: '',
        city: '',
        state: '',
        onTime: true
    });

    useEffect(() => {
        setSelectedVendorId(vendorId);
    }, [vendorId]);

    //PS_FP_1.6 useffect intialize
    useEffect(() => {
        initializeTooltips();
    }, []);
    /*PS_FP_1.7 using useEffect, declare a function handleEscapeKey
    with event as paramter, if event.key is escape, then set state the value
    of setConfirmDeleteOpen to false. and to check for Escape key, use document.addEventListener */
    const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setConfirmDeleteOpen(false);
        }
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }

    const handleVendorSelection = (vendorId: string) => {
        // Use the setter function to update the state
        setSelectedVendorId(vendorId || '');
    };

    const changeVisiblityProvider = async (providerId: string) => {
        //PS_FP_1.61
        setLoader(true)
        try {
            const trackingProvider = visibilityProviders.find((provider) => provider === providerId);

            const visiblityProviderChange = {
                name: trackingProvider || '',
                loadId: loadId,
                tmsId: tmsId
            }
            //PS_FP_1.63-PS_FP_1.71
            const response = await changeVisiblityProviders(visiblityProviderChange);
            if (response?.data?.code === 200) {
                openToast("Visibility Provider changed successfully")
                getLoads()
            } else if (response?.data?.code === 422 || response?.data?.code === 400) {
                alertMessage(response?.data?.errors[0]?.message)
                getLoads()
                handleVendorSelection(vendorId || '')
            } else if (response?.data?.code !== 400) {
                onError()
                handleVendorSelection(vendorId || '')
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            onError()
            handleVendorSelection(vendorId || '')
        }
    };

    const handleDelete = (eventId: number | undefined) => {
        //PS_FP_1.38-PS_FP_1.41
        setConfirmDeleteOpen(true);
        if (eventId != undefined) {
            setEventIdToDelete(eventId);
        }


    };
    const handleEdit = (event: EventResponse) => {
        const matchingEventCode = Object.entries(TrackingEventCodes).find(
            ([status, _]) => event.event.includes(status)
        );
        //PS_FP_1.32
        const events: Events = {
            loadId: loadId || 0,
            tmsId: tmsId ? parseInt(tmsId) : 0,
            trackingeventId: event.tracking_info_id || 0,
            eventType: event.event_type,
            event: event.event,
            eventCode: matchingEventCode ? matchingEventCode[1] : "",
            stopId: event.stop_id,
            inTime: event.in_time,
            outTime: event.out_time,
            notes: event.notes || '',
            reasonCode: event.reason_code || "",
            reasonForDelay: event.reason_for_delay || '',
            addressLine1: event.address_line1 || '',
            addressLine2: event.address_line2 || '',
            isManual: true,
            city: event.city || '',
            state: event.state || '',
            onTime: event.reason_code ? false : true
        }
        setEventDetails(events)
        setEventPopup(true);
    };
    const confirmDelete = async (eventId: number | null) => {
        //PS_FP_1.42-PS_FP_1.44
        const event: any = {
            loadId: loadId?.toString(),
            tmsId: tmsId ? parseInt(tmsId) : 0,
            trackingInfoId: eventId || null,
        }
        setConfirmDeleteOpen(false);
        setLoader(true)
        try {
            const response = await deleteTrackingEvent(event);
            //PS_FP_1.52-PS_FP_1.56
            if (response.data.code === 200) {
                openToast("Event Deleted Successfully")
                getLoads()
            } else {
                onError()
            }
            setLoader(false)

        } catch (error) {
            console.log("Error fetching visibility providers:", error);
            setLoader(false)
            onError()
        }


    }
    const onClose = () => {
        setEventPopup(false);
    }
    const addEvent = () => {
        //PS_FP_1.60
        const events: Events = {
            loadId: loadId || 0,
            tmsId: tmsId ? parseInt(tmsId) : 0,
            event: EventsStatus.Note,
            eventCode: '',
            onTime: true,
            eventType: eventType.trackingEvent,
            inTime: new Date().toISOString(),
            outTime: '',
            notes: '',
            isManual: true,
            reasonCode: "",
            reasonForDelay: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: ''
        }
        setEventDetails(events)
        setEventPopup(true);
    }
    return (
        <>
            {
                loader && <Loader />
            }
            {
                eventPopup && <EventPopup events={eventDetails} onClose={onClose} updateEvent={true} getLoads={getLoads} openToast={openToast} onError={onError} openLoader={openLoader} closeLoader={closeLoader} />
            }
            {isConfirmDeleteOpen && <div
                className="modal fade show"
                id="Delete-Event"
                tabIndex={-1}
                aria-labelledby="Delete-EventLable"
                style={{
                    backgroundColor: "rgba(0, 0, 0, .5)",
                    display: "block"
                }}
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content secondary-bg-color p-2">
                        <div className="modal-header border-0 pb-0">
                            <button
                                type="button"
                                className="btn-close close-icon"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setConfirmDeleteOpen(false)}

                            />
                        </div>
                        <div className="modal-body pt-0">
                            <div className="row text-center justify-content-center">
                                <img
                                    src="/images/delete-pop-icon.svg"
                                    alt="alert-icon"
                                    className="alert-icon p-0"
                                />
                                <h1 className="primary-header mt-3" id="Delete-EventLable">
                                    Delete Event
                                </h1>
                                <p className="form-text-color font-16 mt-3 font-regular">
                                    Are you sure, you want to delete the event ?
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 pb-3 pt-0 justify-content-center">
                            <button
                                type="button"
                                className="btn text-btn  font-14 font-medium px-4"
                                data-bs-dismiss="modal"
                                onClick={() => setConfirmDeleteOpen(false)}
                            >
                                No
                            </button>
                            <button
                                type="button"
                                className="btn primary-btn font-14   font-medium px-4"
                                data-bs-dismiss="modal"
                                onClick={() => confirmDelete(eventIdToDelete)}

                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className="row">
                <div className="col-lg-12 mb-3 ">
                    <div className="content-container scroll-list-details">
                        <div className="d-lg-flex align-items-center justify-content-between mb-4 ">
                            <h3 className="font-18 font-semibold primary-text-color mb-0">
                                Tracking Events
                            </h3>
                            <div className="d-flex gap-3 flex-wrap ">
                                <div className="dropdown d-flex justify-content-center">

                                    <button
                                        id="end-point-config"
                                        className="btn button-dropdown dropdown-button-border cursor-pointer"
                                        type="button"

                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => { setDDclick(!ddclick) }}
                                    >
                                        <div className="d-flex justify-content-between align-items-center font-14 font-semibold primary-text-color gap-2 w-100">
                                            {vendorId === selectedVendorId && visibilityProviders.find((provider) => provider === vendorId) ? (
                                                `${visibilityProviders.find((provider) => provider === vendorId)} ${vendorLoadId ? (vendorLoadId.length > 8 ? `- ${vendorLoadId.substring(0, 8)}...` : `- ${vendorLoadId}`) : ''}`
                                            ) : (
                                                `${visibilityProviders.find((provider) => provider === selectedVendorId) || "Select"}`
                                            )}
                                            <img
                                                src="/images/profile-arrow.svg"
                                                alt="dropdown-icon"
                                                className='dropdown-icon'
                                            />
                                        </div>
                                    </button>
                                    <ul
                                        className={`dropdown-menu border-0 shadow-sm p-2 top-position secondary-bg-color scroll-list-tracking cust-dropdown ${ddclick ? 'show' : ''}`}
                                        data-popper-placement={`bottom-start`}
                                        style={{
                                            position: "absolute",
                                            inset: "0px auto auto 0px",
                                            margin: 0,
                                            transform: "translate(-86px, 20px)"
                                        }}
                                    >
                                        {visibilityProviders?.filter(provider => provider !== selectedVendorId && provider !==
                                            'TextLocate'
                                        ).map(provider => (
                                            <li className="font-14 font-medium color-black cursor-pointer" key={provider}>
                                                <a
                                                    className="dropdown-item py-2 custom-dropdown-li primary-text-color cursor-pointer"
                                                    onClick={() => changeVisiblityProvider(provider)}
                                                >
                                                    {provider}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button className="btn primary-grad-outline-btn font-13 font-semibold"
                                    onClick={() => addEvent()}

                                >
                                    <span className="me-2">
                                        <img src="/images/add-icon-grad.svg" alt="add-icon"
                                            className="add-icon" /> </span>
                                    <span className="primary-grad-text">New Note</span>
                                </button>
                            </div>
                        </div>
                        <div className="table-responsive theme-table less-space-table">
                            <table className="table table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Date
                                            <img className="ms-2 tooltip-img" src="/images/tooltip-icon.svg" alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Hover the date to see the year" aria-label="Hover the date to see the year"></img></th>
                                        <th className="text-nowrap">Event</th>
                                        <th className="text-nowrap">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* PS_FP_1.25 - PS_FP_1.26*/}
                                    {trackingEvents?.filter(event => event?.event_type === eventType.trackingEvent || event?.event_type === eventType.event || event?.event_type === eventType.InformationEvent || event?.event_type === eventType.driverChange || event?.event_type === eventType.Equipment).length < 1 ? (
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="d-flex w-100 justify-content-center">
                                                    <div className="text-center py-3">
                                                        <span className="no-events-found"></span>
                                                        <h5 className="font-20 font-semibold mt-2 color-black-v1">
                                                            No Events Available
                                                        </h5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        trackingEvents?.filter(event => event?.event_type === eventType.trackingEvent || event?.event_type === eventType.event || event?.event_type === eventType.driverChange || event?.event_type === eventType.InformationEvent || event?.event_type === eventType.Equipment)?.map((event, index) => (
                                            <tr key={index}>
                                                {/* PS_FP_1.26-PS_FP_1.30*/}
                                                <td>
                                                    {event?.in_time && event?.out_time ? (
                                                        `${getTimestampToDate(event.in_time, 18)} - ${getTimestampToDate(event.out_time, 18)}`
                                                    ) : event?.in_time ? (
                                                        `${getTimestampToDate(event.in_time, 18)}`
                                                    ) : event?.event_timestamp ? (
                                                        getTimestampToDate(event.event_timestamp, 18)
                                                    ) : (
                                                        "NA"
                                                    )}
                                                </td>
                                                {event.event_type == eventType.driverChange || event.event_type == eventType.Equipment || !event.is_manual ? (
                                                    <td>{event.event}{event.state || event.city ? (` - ${event.city}, ${event.state}`) : ""}</td>
                                                ) : (
                                                    <td>
                                                        {event.event === EventsStatus.Note ? (
                                                            <>
                                                                <strong>Note: </strong>
                                                                {event.notes}
                                                                {event.reason_for_delay && !event.on_time && (
                                                                    <>
                                                                        <br />
                                                                        <strong>Reason for Delay: </strong>
                                                                        <span style={{ color: 'red' }}>{event.reason_for_delay}</span>
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <strong>{event.event}</strong>
                                                                {event.state || event.city ? (` - ${event.city}, ${event.state}`) : ""}
                                                                {event.reason_for_delay && !event.on_time && (
                                                                    <>
                                                                        <br />
                                                                        <strong>Reason for Delay: </strong>
                                                                        <span style={{ color: 'red' }}>{event.reason_for_delay}</span>
                                                                    </>
                                                                )}
                                                                {event.notes && (
                                                                    <>
                                                                        <br />
                                                                        <strong>Note: </strong>
                                                                        <span style={{ color: 'grey' }}>{event.notes}</span>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </td>

                                                )}

                                                {event.is_manual && event.event_type != eventType.driverChange && event.event_type != eventType.Equipment && (
                                                    <td>
                                                        <span className="d-flex">
                                                            <span className="button-group-border-right pe-2"><a
                                                                className="link-color font-14 font-regular text-decoration-none" > < img src="/images/edit-icon.svg"
                                                                    alt="edit-icon" className="history-icon" width="20px"
                                                                    onClick={() => handleEdit(event)}
                                                                    style={{ cursor: 'pointer' }}
                                                                    height="20px" />
                                                            </a></span>
                                                            <span className="ps-1"><a
                                                                className="link-color font-14 font-regular text-decoration-none">
                                                                <img
                                                                    src="/images/delete-icon.svg"
                                                                    alt="Delete"
                                                                    title="Delete"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleDelete(event.tracking_info_id)}
                                                                /></a></span>
                                                        </span>


                                                    </td>
                                                )}

                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrackingEvents;