import { CustomerDetails } from "../../../interface/LoadInterface";

const CustomerDetail: React.FC<{ CustomerDetail: CustomerDetails }> = ({ CustomerDetail }) => {
    //PS_04 Default CustomerDetails object with empty strings
    const defaultDetails: CustomerDetails = {
        customer_name: "",
        customer_id: ""
    };

    //PS_05 Check if customerDetail is null or has the same structure as defaultDetails
    const hasValidDetails = CustomerDetail && JSON.stringify(CustomerDetail) !== JSON.stringify(defaultDetails);
    //PS_06 - 08
    return (
        <>
            <div className="col-12 col-md-6 mb-3">
                <div className="content-container scroll-list-details h-100">
                    <h3 className="font-18 font-semibold primary-text-color mb-3">Customer Details
                    </h3>
                    {hasValidDetails ?
                        <div className="h-100px">
                            <ul>
                                <li className="font-14 font-medium table-text-color mb-3">Name:<span
                                    className="font-14 font-semibold primary-text-color ms-1"> {CustomerDetail?.customer_name ? CustomerDetail?.customer_name : "-"} </span></li>
                                <li className="font-14 font-medium table-text-color">Customer ID:<span
                                    className="font-14 font-semibold primary-text-color ms-1"> {CustomerDetail?.customer_id ? CustomerDetail?.customer_id : "-"} </span></li>
                            </ul>
                        </div> : (
                            <div className="h-100px">
                                <div className="d-flex flex-row justify-content-center align-items-center h-inherit">
                                    <span className="no-records-found small me-2"></span>
                                    <p className="font-13 font-semibold color-black-v1 m-0">No Data Found</p>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>)
}

export default CustomerDetail;