import { useEffect } from "react";
import { ConfirmationPopUpProps } from "../../interface/LoadInterface";

function ConfirmationPopUp({ heading, content, onClose, oKClick }: ConfirmationPopUpProps) {
   
    /*PS_30 Using useEffect, define a function handleKeydown to check whether
    the key pressed is Escape if true, then call the function onClose() */
    useEffect(() => {
        const handleKeyDown = (event:KeyboardEvent) => {
          if (event.key === 'Escape') {
            onClose();
          }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [onClose]);
   
   return (
<div
    className="modal fade show"
    id="Delete-Event"
    tabIndex={-1}
    aria-labelledby="Delete-EventLable"
    style={{
        backgroundColor: "rgba(0, 0, 0, .1)",
        display: "block"
    }}
    aria-modal="true"
    role="dialog"
>
    <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content secondary-bg-color p-2">
            <div className="modal-header border-0">
                <button
                    type="button"
                    className="btn-close close-icon"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={onClose}

                />
            </div>
            <div className="modal-body pt-2">
                <div className="row text-center justify-content-center">
                    <img src="/images/cancel-pop-icon.svg" alt="alert-icon" className="alert-icon p-0"/>
                    <h1 className="primary-header mt-3" id="Delete-EventLable">
                        {heading}
                    </h1>
                    <p className="form-text-color font-16 mt-3 font-regular">
                        {content}
                    </p>
                </div>
            </div>
            <div className="modal-footer border-0 pb-3 pt-0 justify-content-center">
                <button
                    type="button"
                    className="btn text-btn  font-14 font-medium px-4"
                    data-bs-dismiss="modal"
                    onClick={onClose}
                >
                    No
                </button>
                <button
                    type="button"
                    className="btn primary-btn font-14   font-medium px-4"
                    data-bs-dismiss="modal"
                    onClick={oKClick}>
                    Yes
                </button>
            </div>
        </div>
    </div>
</div>)
}

export default ConfirmationPopUp;