import { InviteStatus, RoleEnum } from "../enums/enums";
import { AddUserRequest, DriverMessage, OrganizationDetails, TMSConfiguration, SignupDetails, UpdateDriverDTO, UpdateObj, UserDetailsRequest } from "../interface/Interface";
import { TMSConfiguration as Tms, TmsTestConnectionRequest } from "../interface/TmsCreationInterface";
import { CancelTracking, DriverInputRequest, loadMetricsRequest, ChangeEquipmentRequest, LoadFilter } from "../interface/LoadInterface";
import { ReportFilter } from "../interface/ReportsInterface";
import { client } from "./Client";
//page: number, pageSize: number, sortField: string, sortOrder: string, accessToken: string
export const getUsersDetails = async (tmsId: number, manageUserFilter: ReportFilter, isActive: string) => {
    const response = await client(`users/v1/user?start_index=${manageUserFilter.start_index}&page_size=${manageUserFilter.page_size}&search_data=${manageUserFilter.search_data}&sort_type=${manageUserFilter.sort_type}&sort_column=${manageUserFilter.sort_column}&tms_id=${tmsId}&is_active=${isActive}`, { method: 'GET' });
    return response;
}
/**
 * This is used post the user details to signup API
 * @param requestBody the Logistic partner 
 * @returns the reponse from signup API
 */
export const postSignup = async (signupRequest: SignupDetails) => {
    // Construct the URL 
    let url = `users/v1/tms/signup`;
    //PC_SU_26
    const response = await client(url, { method: "POST", body: signupRequest })
    // Return the response data
    return response
}

export const insertUserDetail = async (userDetails: AddUserRequest) => {
    const response = await client(`users/v1/user`, { method: 'POST', body: userDetails })
    return response;
}

//sssPS_13
export const updateDriverMessageReadStatus = async (tmsId: string, loadId: number) => {
    try {
        const response = await client(`loads/v1/tms/${tmsId}/load/${loadId}/driver-messages/read`, { method: "PUT" });
        return response;
        // PS_FP_1.43-    // Set up other required details if needed
    }
    catch (error) {
        console.log(error);
    }
};

export const updateUserDetail = async (UpdateUser: UpdateObj) => {
    const response = await client(`users/v1/user`, { method: 'PUT', body: UpdateUser })
    return response;
}

export const getUserRoles = async (tmsId: number) => {
    // Check if companyId exists in local storage
    const response = await client(`users/v1/${tmsId}/user-roles/options`, { method: "GET" });
    return response;
};

export const getCompanies = async () => {
    try {
        const response = await client(`users/v1/tms-list`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};
//PC_SI_31-Method to retrieve user details
export const retrieveUserDetails = async (tmsID?: number) => {
    try {
        const queryParam = tmsID ? `tmsId=${tmsID}` : '';
        const response = await client(`users/v1/user-detail?${queryParam}`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};
//PC_SI_31-Method to retrieve user details
export const retrieveTMSDetails = async (tmId?: string) => {
    try {
        const queryParam = tmId ? tmId : '';
        const response = await client(`users/v1/tms/${queryParam}`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};

//PC_SI_43-PC_SI_44-Method to check API key validity and conguration
export const validateUserKey = async () => {
    try {
        const response = await client(`users/v1/user/validate-key`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};

//PC_SI_48-PC_SI_49-Method to insert audit logs
export const insertLoginAudits = async () => {
    try {
        const response = await client(`users/v1/user/audit-login`, { method: "POST" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};

export const updateInvitationStatus = async (invitationStatus: InviteStatus) => {
    try {
        const response = await client(`users/v1/user/invitation-status`, { method: "PUT", body: { invitation_status: invitationStatus } });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};

//PC_SI_48-PC_SI_49-Method to update invitation status
export const updateUserTheme = async (themeType: string) => {
    try {
        const response = await client(`users/v1/user/theme`, { method: "PUT", body: { theme_type: themeType } });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};


export const retrieveConfigurationOptions = async () => {
    try {
        const response = await client(`users/v1/configurationOption`, { method: "GET" });
        return response;
        // PS_FP_1.43-    // Set up other required details if needed

    }
    catch (error) {
        console.log(error);
    }
};

export const postTMS = async (postTMSRequest: Tms) => {
    //PS_FP_1.45- Set up the configuration for the POST request
    let method:string = "POST"
    if (postTMSRequest?.tms_id){
        method = "PUT"
    }
    const response = await client(`users/v1/tms`, { method: method, body: postTMSRequest });
    return response;

};

export const retrieveOrganizationData = async (tmsID: string) => {
    // PS_FP_1.46-Set up the configuration for the GET request
    try {
        const response = await client(`users/v1/tms/${tmsID}`, { method: "GET" });
        return response;
        // PS_FP_1.43-// Set up other required details if needed
    }
    catch (error) {
        console.log(error);
    }


}

export const ConnectionTest = async (payload: TmsTestConnectionRequest) => {
    // PS_FP_1.44-Set up the configuration for the test
    try {
        const response = await client(`users/v1/tms/test-connection`, { method: "POST", body: payload });
        return response;
    }
    catch (error) {
        console.log(error);
    }
}



export const getTMSUsers = async (tmsFilter: ReportFilter, status: string, connection_status: string) => {
    try {

        // Construct the base URL 
        let url = `users/v1/tms-grid?start_index=${tmsFilter?.start_index}&search_data=${tmsFilter.search_data}&sort_type=${tmsFilter.sort_type}&sort_column=${tmsFilter.sort_column}&status=${status}&connection_statusd=${connection_status}&from_date=${tmsFilter.from_date}&to_date=${tmsFilter.to_date}`;

        // Make the HTTP request
        const response = await client(url, { method: "GET" })

        // Return the response data
        return response
    } catch (error) {

        console.error('Error fetching grid data:', error);
        return null;
    }
};
/**
 * The retreives the Status from the given endpoint
 * @returns 
 */
export const getTmsStatus = async () => {
    try {

        // Construct the base URL 
        let url = `users/v1/tms/statuses/options`;


        // Make the HTTP request
        const response = await client(url, { method: "GET" })

        // Return the response data
        return response
    } catch (error) {
        // Handle any errors that occur during the process
        console.error('Error fetching grid data:', error);
    }
};

//PS_11 - 17
export const getLoadDetails = async (tmsId: number, reportFilter: ReportFilter, loadFilter: LoadFilter) => {
    try {
        // Construct the base URL
        let url = `loads/v1/tms/${tmsId}/loads?start_index=${(reportFilter?.start_index)}&page_size=${(reportFilter?.page_size)}&load_status_code=${(loadFilter?.load_status_code)}&from_date=${(reportFilter?.from_date)}&to_date=${(reportFilter?.to_date)}&search_data=${(reportFilter?.search_data)}&tracking_status_code=${(loadFilter?.tracking_status_code)}&carrier_id=${(loadFilter?.carrier_id)}&customer_id=${(loadFilter?.customer_id)}&to_origin=${(loadFilter.to_origin)}&from_origin=${(loadFilter?.from_origin)}&from_destination=${(loadFilter?.from_destination)}&to_destination=${(loadFilter?.to_destination)}&sort_by=${reportFilter?.sort_type}&sort_column=${reportFilter?.sort_column}`;
        // Make the HTTP request
        const response = await client(url, { method: "GET" })
        return response;
    } catch (error) {
        console.log("Error Fetching Loads Grid", error)
    }
}

export const getStatus = async () => {
    try {
        let url = `loads/v1/etm/statuses`;
        // Make the HTTP request
        const response = await client(url, { method: "GET" });
        return response;
    } catch (error) {
        console.log("Error while status dropdown in API layer:", error)
    }
}

export const getCustomerOptions = async (tmsId: number) => {
    try {
        let url = `loads/v1/tms/${tmsId}/customers/options`;
        // Make the HTTP request
        const response = await client(url, { method: "GET" });
        return response;
    } catch (error) {
        console.log("Error while status dropdown in API layer:", error)
    }
}

export const getCarrierOptions = async (tmsId: number) => {
    try {
        let url = `loads/v1/tms/${tmsId}/carriers/options`;
        // Make the HTTP request
        const response = await client(url, { method: "GET" })
        return response;
    } catch (error) {
        console.log("Error while status dropdown in API layer:", error)
    }
}

export const getTrackingMethods = async () => {
    try {
        let url = `loads/v1/etm/tracking-methods`;
        // Make the HTTP request
        const response = await client(url, { method: "GET" });
        return response;
    } catch (error) {
        console.log("Error while status dropdown in API layer:", error)
    }
}

export const getLoadDetail = async (tmsId: number, loadId: number) => {
    try {
        let url = `loads/v1/tms/${tmsId}/load/${loadId}`;
        // Make the HTTP request
        const response = await client(url, { method: "GET" });
        return response;
    } catch (error) {
        console.log("Error while Gettting Specific LoadDetails for LoadDrilldown in API layer:", error)
    }
}
export const getLoadMetrics = async (loadReq: loadMetricsRequest) => {
    try {
        let url = `loads/v1/tms/${loadReq.tmsId}/load-metrics?carrierId=${loadReq.carrierId}&customerId=${loadReq.customerId}&fromDate=${loadReq.fromDate}&toDate=${loadReq.toDate}`
        // Make the HTTP request
        const response = await client(url, { method: "GET" });
        return response;
    } catch (error) {
        console.log("Error while Gettting Specific LoadDetails for LoadDrilldown in API layer:", error)
    }
}

export const getUserInfo = async (userId: string) => {
    try {
        const response = await client(`users/v1/user/profile?impersonateId=${userId}`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};


export const updateTMSDetails = async (impersonate :boolean,userRole:string,tmsConfigurationDetails: TMSConfiguration) => {
    if (impersonate && userRole == RoleEnum.ETM_ADMIN){
        try {
            const response = await client(`users/v1/tms`, { method: "PUT", body: tmsConfigurationDetails });
            return response;
        }
        catch (error) {
            console.log(error);
        }
    } 
    else if (!impersonate && userRole == RoleEnum.ETM_ADMIN) {
        try {
            const response = await client(`users/v1/etm/profile`, { method: "PUT", body: tmsConfigurationDetails });
            return response;
        }
        catch (error) {
            console.log(error);
        }
    }
    else{
    try {
        const response = await client(`users/v1/tms`, { method: "PUT", body: tmsConfigurationDetails });
        return response;
    }
    catch (error) {
        console.log(error);
    }
}
};

export const getUserDetailsAgainstCompany = async (tmsId: string) => {
    try {
        const response = await client(`users/v1/user/impersonate/${tmsId}`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};


export const changeVisiblityProviders = async (visiblityProviderChange: any) => {
    try {
        const response = await client(`loads/v1/tms/${visiblityProviderChange.tmsId}/load/${visiblityProviderChange.loadId}/change-tracking-vendor`, { method: "PUT", body: visiblityProviderChange });
        return response;
        // PS_FP_1.43-    // Set up other required details if needed
    }
    catch (error) {
        console.log(error);
    }
}

export const deleteTrackingEvent = async (event: any) => {
    try {
        //PS_FP_1.45-1.48
        const response = await client(`loads/v1/tms/${event.tmsId}/load/${event.loadId}/delete-event/${event.trackingInfoId}`, { method: "PUT" });
        return response;
        // PS_FP_1.43-    // Set up other required details if needed
    }
    catch (error) {
        console.log(error);
    }
}
export const addEvent = async (body: any,tmsId:number,loadId:number) => {
    try {
        let method:string = "POST"
        if (body?.tracking_event_id !=null && body?.tracking_event_id != 0){
            method = "PUT"
        }
        const response = await client(`loads/v1/tms/${tmsId}/load/${loadId}/event-update`, { method: method, body: body });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};

export const getDelayReasons = async (tmsId: number) => {
    try {

        // Construct the base URL 
        let url = `loads/v1/tms/${tmsId}/delay-reasons/options`;
        // Make the HTTP request
        const response = await client(url, { method: "GET" })

        // Return the response data
        return response
    } catch (error) {
        // Handle any errors that occur during the process
        console.error('Error fetching grid data:', error);
    }
};

    export const getLoadStops = async (tmsId: number, loadId: number) => {
        try {

            // Construct the base URL 
            let url = `loads/v1/tms/${tmsId}/load/${loadId}/stops/options`;
            // Make the HTTP request
            const response = await client(url, { method: "GET" })

            // Return the response data
            return response
        } catch (error) {
            // Handle any errors that occur during the process
            console.error('Error fetching grid data:', error);
        }
    };



export const getDrivers = async (tmsId: number, loadId: number, filterRequest: DriverInputRequest) => {
    try {
        const response = await client(`loads/v1/tms/${tmsId}/load/${loadId}/drivers?sort_type=${filterRequest.sort_type}&sort_column=${filterRequest.sort_column}`, { method: "GET" });
        return response;
        // PS_FP_1.43-    // Set up other required details if needed
    }
    catch (error) {
        console.log(error);
    }
};
export const editEquipDetails = async (tmsId: number, loadId: number, equipDetail: ChangeEquipmentRequest) => {
    try {
        let url = `loads/v1/tms/${tmsId}/load/${loadId}/change-equipment`;
        const response = await client(url, { method: "PUT", body: equipDetail });
        return response;
    }
    catch (error) {
        console.log("Error while getting the equipment Details", error)
    }
}

export const getCarrierDetails = async (tmsId: number, request: ReportFilter, eld_provider: string) => {
    try {
        let url = `loads/v1/tms/${tmsId}/carriers?search_data=${request.search_data}&sort_column=${request.sort_column}&sort_type=${request.sort_type}&eld_provider_code=${eld_provider}&start_index=${request.start_index}&page_size=${request.page_size}`
        const response = await client(url, { method: "GET" });
        return response;
    } catch (error) {
        console.error("Error in GetCarrierGridApi:", error);
    }
}

export const cancelTrackingAPI = async (request: CancelTracking) => {
    try {
        let url = `loads/v1/load/cancel-tracking`
        const response = await client(url, { method: "PUT", body: request });
        return response;
    } catch (error) {
        console.error("Error in GetCarrierGridApi:", error);
    }
}

export const getDriverMessagesAPI = async (tmsId: string, loadId: number) => {
    try {
        const response = await client(`loads/v1/tms/${tmsId}/load/${loadId}/driver-messages`);
        return response;
        // PS_FP_1.43-    // Set up other required details if needed
    }
    catch (error) {
        console.log(error);
    }
};

export const sendMessageAPI = async (request: DriverMessage) => {
    try {
        const response = await client(`loads/v1/load/send-message`, { method: "POST", body: request });
        return response;
        // PS_FP_1.43-    // Set up other required details if needed
    }
    catch (error) {
        console.log(error);
    }
};

export const updateDriver = async (request: UpdateDriverDTO) => {
    try {
        const response = await client(`loads/v1/tms/${request.tmsId}/load/${request.loadId}/change-driver`, { method: "PUT", body: request });
        return response;
        // PS_FP_1.43-    // Set up other required details if needed
    }
    catch (error) {
        console.log(error);
    }
};