function PageNotFound() {
    return (<>
        <div className="container-fluid">
            <div className="row ">
                <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row justify-content-center login-bg-hig align-items-center">
                        <div className="col-md-9 col-sm-12  text-center">
                            <span className=" pagenotfound-image"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default PageNotFound;