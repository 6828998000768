import * as FileSaver from 'file-saver';
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import Select, { ActionMeta } from 'react-select';
import * as XLSX from 'xlsx';
import { RoleEnum, TrackingProvider, TrackingVendorCodes } from "../../enums/enums";
import { AuditResponse, CompanyOptions, ReactSelectOptions } from "../../interface/Interface";
import { DropDownOptions } from "../../interface/LoadInterface";
import { SummaryReport, TrackingReport, TrackingReportFilter, ReportFilter } from "../../interface/ReportsInterface";
import { getCarrierOptions, getCustomerOptions, getCompanies, getTrackingMethods } from "../../service/Api";
import { getAuditLogApi, getSummaryReportDetails, getTrackingReportDetails } from "../../service/ReportsAPI";
import { getTimestampToDate } from "../../utils/Formatting";
import { initializeTooltips } from '../../utils/utils';
import Loader from "../Resuables/Loader";
import NoDataFound from "../Resuables/NoDataFound";
import NoResultsFound from "../Resuables/NoResultsFound";
import PaginationComponent from "../Resuables/PaginationComponent";
import { SomethingWentWrong } from "../Resuables/SomethingWentWrong";
import TimeFrame from "../Resuables/TimeFrame";
import AuditHistory from "./AuditHistory";
import SummaryReports from "./SummaryReport";


function Reports() {
    //
    let filterObj: ReportFilter = {
        search_data: "",
        from_date: "",
        to_date: "",
        sort_column: "",
        sort_type: "",
        start_index: 1,
        page_size: 10
    }
    let trackingObj: TrackingReportFilter = {
        customer_id: "",
        carrier_id: "",
        tracking_status: "",
        tracking_provider: "",
    }
    const [tmsId, setTMSId] = useState<number>(Number(localStorage.getItem("tmsId") || 0));
    //PS_DD_02 Declaring state variables to store dropdown values.
    const [trackingStatusDropDown, setTrackingStatusDropDown] = useState<DropDownOptions[]>([]);
    const [carrierOptions, setCarrierOptions] = useState<ReactSelectOptions[]>([]);
    const [shipperOptions, setShipperOptions] = useState<ReactSelectOptions[]>([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [company, setCompany] = useState<CompanyOptions[]>([]);
    const [carrier, setCarrier] = useState<ReactSelectOptions | null>();
    const [customer, setCustomer] = useState<ReactSelectOptions | null>();
    const [temporaryTrackingReportFilter, setTemporaryTrackingReportFilter] = useState<TrackingReportFilter>(trackingObj);

    //PS_02
    const [currentReportsTab, setCurrentReportsTab] = useState({
        trackingReports: true,
        summaryReport: false,
        auditHistory: false,
        trigger: false
    });
    const [summaryReports, setSummaryReports] = useState<SummaryReport[]>([]);
    const [loader, setLoader] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [noDataFound, setNoDataFound] = useState<Boolean>(false);
    const [noResultsFound, setNoResultsFound] = useState<Boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [reportFilter, setReportFilter] = useState<ReportFilter>(filterObj);

    //PSAF_01 - 02
    const [temporarySearch, setTemporarySearch] = useState("");
    const [trackingReportFilter, setTrackingReportFilter] = useState<TrackingReportFilter>(trackingObj);
    const [trackingReports, setTrackingReports] = useState<TrackingReport[]>([]);
    const [auditResponse, setAuditResponse] = useState<AuditResponse[]>([]);
    //PS_AC_02
    const [selectedIndex, setSelectedIndex] = useState<Boolean[]>([])
    const accessToken = localStorage.getItem("accessToken") || "";
    let decodedToken = jwtDecode(accessToken) as { [key: string]: any };
    let groups = decodedToken["cognito:groups"];

    //PS_DD_05 All the dropDowns functions are called in this function.
    useEffect(() => {
        //PS_05
        if (groups[0] == RoleEnum.ETM_ADMIN && localStorage.getItem("isImpersonate") != "true") {
            //SQ_H_1.7
            getCompany()
        }
        getTrackingStatus()
        initializeTooltips()
    }, []);

    //PS_DH_1.39-PS_DH_1.44


    useEffect(() => {
        initializeTooltips()
        if (currentReportsTab.trackingReports)
            //PS_10
            getTrackingReports()
        else if (currentReportsTab.summaryReport)
            getSummaryReports()
        else if (currentReportsTab.auditHistory)
            get3plAuditLog()
    }, [reportFilter, trackingReportFilter, currentReportsTab, tmsId]);


    //PSAF_16
    useEffect(() => {
        getCarriers()
        getCustomers()
    }, [tmsId])

    //PS_DD_05 - 19 GetCustomers for the particular company
    async function getCustomers() {

        try {
            let result = await getCustomerOptions(tmsId);
            if (result?.data?.code == 200) {
                const options = result?.data?.data.map((option: { display_text: any; code: any; }) => ({
                    label: option.display_text,
                    value: option.code,
                }));
                setShipperOptions(options);
            } else {
                setShipperOptions([])
            }
        } catch (error) {
            console.log("Error while feching Customers Dropdown :", error)
        }
    }

    const handleCustomer = (selectedOptions: any) => {
        // Update state based on selectedOptions
        setCustomer(selectedOptions);

        // Assuming setLoadFilter exists in your component
        // Update any other state based on selected options
        setTemporaryTrackingReportFilter((prevData) => ({
            ...prevData,
            customer_id: String(selectedOptions?.value || ""),
        }));
    };

    //PS_DD_05 - 19 GetTrackingStatus dropDown, which is static for all the companies.
    async function getTrackingStatus() {
        try {
            let result = await getTrackingMethods();
            if (result?.data?.code == 200) {
                setTrackingStatusDropDown(result?.data?.data)
            } else {
                setTrackingStatusDropDown([])
            }
        } catch (error) {
            console.log("Error while feching TrackingStatus Dropdown :", error)
            setErrorPopUp(true)
        }
    }

    //PS_DD_05 - 19 Get Carriers for the particular company.
    async function getCarriers() {
        try {
            let result = await getCarrierOptions(tmsId);
            if (result?.data?.code == 200) {
                const options = result?.data?.data.map((option: { display_text: any; code: any; }) => ({
                    label: option.display_text,
                    value: option.code,
                }));
                setCarrierOptions(options);
            } else {
                setCarrierOptions([]);
            }
        } catch (error) {
            console.log("Error while feching Carriers Dropdown :", error)
        }
    }
    const handleCarrier = (
        selectedOptions: ReactSelectOptions | null,
        actionMeta: ActionMeta<ReactSelectOptions>
    ) => {
        // Update state based on selectedOptions
        setCarrier(selectedOptions);
        // Assuming setLoadFilter exists in your component
        // Update any other state based on selected options
        setTemporaryTrackingReportFilter((prevData) => ({
            ...prevData,
            carrier_id: selectedOptions?.value || "",
        }));
    };

    function bindStatus() {
        return trackingStatusDropDown?.map((value, index) => (
            <>
                <option key={index} value={`${value.code}`}>
                    {value.display_text}
                </option>
            </>))
    }

    function bindVisibilityProviders() {
        return Object.entries(TrackingVendorCodes).map(([key, value]) => (
            <>
                <option value={value} key={key}>
                    {key}
                </option>
            </>))
    }

    const getTrackingReports = async () => {
        try {
            // PSLL_06 (SQ_LL_1.5)
            setLoader(true);
            // PSLL_07 (SQ_LL_1.6) to PSLL_11 (SQ_LL_1.10)
            const result = await getTrackingReportDetails(tmsId, reportFilter, trackingReportFilter);
            // PSLL_15 (SQ_LL_1.14)
            if (result?.data?.code === 200) {
                setSelectedIndex([]);
                const reports = result?.data?.data || []
                setTrackingReports(reports);
                setTotalCount(result?.data?.record_count || 0);
                if (reports?.length === 0) {
                    if (JSON.stringify(reportFilter) === JSON.stringify(filterObj)) {
                        setNoDataFound(false)
                        setNoResultsFound(true)
                    }
                    else {
                        setNoDataFound(true)
                        setNoResultsFound(false)

                    }
                }
                else {
                    setNoDataFound(false);
                    setNoResultsFound(false);
                }
            }
            else {
                setNoDataFound(true);
                setNoResultsFound(false);
                setTrackingReports([])
                setTotalCount(0)
                setErrorPopUp(true)
            }
        } catch (error) {
            // PSLL_17 (SQ_LL_1.16), PSLL_18 (SQ_LL_1.17)
            setTrackingReports([]);
            setErrorPopUp(true);
            setNoDataFound(true);
            setNoResultsFound(false);
        } finally {
            // PSLL_19 (SQ_LL_1.18)
            setLoader(false);
        }
    };

    const getSummaryReports = async () => {
        try {
            setLoader(true);
            const result = await getSummaryReportDetails(tmsId, reportFilter);

            if (result?.data?.code === 200) {
                const reports = result?.data?.data || [];
                setSummaryReports(reports);
                setTotalCount(result?.data?.record_count || 0);

                if (reports.length === 0) {
                    if (JSON.stringify(reportFilter) === JSON.stringify(filterObj)) {
                        setNoDataFound(true);
                        setNoResultsFound(false);
                    } else {
                        setNoDataFound(false);
                        setNoResultsFound(true);
                    }
                } else {
                    setNoDataFound(false);
                    setNoResultsFound(false);
                }
            } else {
                setNoDataFound(true);
                setNoResultsFound(false);
                setSummaryReports([]);
                setTotalCount(0);
                setErrorPopUp(true);
            }
        } catch (error) {
            setSummaryReports([]);
            setErrorPopUp(true);
            setNoDataFound(true);
            setNoResultsFound(false);
            setTotalCount(0);
        } finally {
            setLoader(false);
        }
    };

    const get3plAuditLog = async () => {
        try {
            setLoader(true);
            const AuditApiResponse = await getAuditLogApi(tmsId, reportFilter, false);

            if (AuditApiResponse?.data?.code === 200) {
                const reports = AuditApiResponse?.data?.data || [];
                setAuditResponse(reports);
                setTotalCount(AuditApiResponse?.data?.record_count || 0);

                if (reports.length === 0) {
                    if (JSON.stringify(reportFilter) === JSON.stringify(filterObj)) {
                        setNoDataFound(true);
                        setNoResultsFound(false);
                    } else {
                        setNoDataFound(false);
                        setNoResultsFound(true);
                    }
                } else {
                    setNoDataFound(false);
                    setNoResultsFound(false);
                }
            } else {
                setNoDataFound(true);
                setNoResultsFound(false);
                setAuditResponse([]);
                setTotalCount(0);
                setErrorPopUp(true);
            }
        } catch (error) {
            setAuditResponse([]);
            setErrorPopUp(true);
            setNoDataFound(true);
            setNoResultsFound(false);
            setTotalCount(0);
        } finally {
            setLoader(false);
        }
    };

    //PS_13-16
    const handlePageClick = (data: { selected: number }) => {
        setReportFilter(prevState => ({
            ...prevState,
            start_index: (data.selected * reportFilter.page_size) + 1,
        }));
    }
    const handleSearch = () => {
        setReportFilter(prevState => ({
            ...prevState,
            search_data: temporarySearch,
            start_index: 1,
        }));
    }

    function handleExportToExcel() {
        if (currentReportsTab.trackingReports) {
            exportTrackingReports()
        } else if (currentReportsTab.summaryReport) {
            exportSummaryReports()
        }
        else if (currentReportsTab.auditHistory) {
            exportAuditReports()
        }
    }

    //PF_18 This function is used to export the feedback of the employee in Excel format
    const exportTrackingReports = async () => {
        setLoader(true);
        try {
            // PSLL_07 (SQ_LL_1.6) to PSLL_11 (SQ_LL_1.10)
            // Create copies of the filter objects and set pagination to 0
            const modifiedReportFilter: ReportFilter = { ...reportFilter, start_index: 0, page_size: 0 };

            const result = await getTrackingReportDetails(tmsId, modifiedReportFilter, trackingReportFilter);
            if (result?.data?.code === 200) {
                let data: TrackingReport[] = result?.data?.data;
                let exportToExcel = [
                    ['ETM Load Id', 'TMS Load Number', 'Tracking Type', 'Tracking Provider', 'Planned Start Date', 'Planned End Date', 'Actual Start Date', 'Actual End Date'],
                ];

                data?.forEach((value: TrackingReport) => {
                    const formattedRow: string[] = [
                        value.load_id.toString(),
                        value.tms_load_number,
                        value.tracking_type,
                        value.tracking_provider,
                        getTimestampToDate(value.planned_start_date, 5) || '',
                        getTimestampToDate(value.planned_end_date, 5) || '',
                        getTimestampToDate(value.actual_start_date, 5) || '',
                        getTimestampToDate(value.actual_end_date, 5) || ''
                    ];
                    exportToExcel.push(formattedRow);
                });
                let filename =
                    'Tracking Reports' +
                    new Date().getFullYear() +
                    '' +
                    new Date().getMonth() +
                    '' +
                    new Date().getDate() +
                    '' +
                    new Date().getHours() +
                    '' +
                    new Date().getMinutes() +
                    '' +
                    new Date().getSeconds();
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                let ws = XLSX.utils.aoa_to_sheet(exportToExcel);
                // const exportToCSV = (csvData, fileName) => {
                // const ws = XLSX.utils.(csvData);
                const wb = { Sheets: { 'View Log': ws }, SheetNames: ['View Log'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data1 = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data1, filename + fileExtension);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    };

    //PF_18 This function is used to export the feedback of the employee in Excel format
    const exportSummaryReports = async () => {
        setLoader(true);
        try {
            const modifiedReportFilter = { ...reportFilter, start_index: 0, page_size: 0 };
            const result = await getSummaryReportDetails(tmsId, modifiedReportFilter);

            if (result?.data?.code === 200) {
                let data: SummaryReport[] = result?.data?.data || [];

                let exportToExcel: string[][] = [
                    ["Username", 'Role', 'Arrived at Customer', 'Mark as Loaded', 'In Transit', 'Arrived at Consignee', 'Delivered', 'Touches per Business Day'],
                ];

                data.forEach((value: SummaryReport) => {
                    const formattedRow: string[] = [
                        value.user_name,
                        value.role,
                        value.at_origin_count.toString(),
                        value.picked_up_count.toString(),
                        value.in_transit_count.toString(),
                        value.at_consignee_count.toString(),
                        value.delivered_count.toString(),
                        value.touches_per_business_day_count.toString()
                    ];
                    exportToExcel.push(formattedRow);
                });

                let filename = `Summary_Report_${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}`;
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                let ws = XLSX.utils.aoa_to_sheet(exportToExcel);
                const wb = { Sheets: { 'Summary Report': ws }, SheetNames: ['Summary Report'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data1 = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data1, filename + fileExtension);
            } else {
                console.error('Failed to fetch summary report data');
                // Optionally, you can show an error message to the user here
            }
        } catch (error) {
            console.error('Error exporting summary reports:', error);
            // Optionally, you can show an error message to the user here
        } finally {
            setLoader(false);
        }
    };

    const exportAuditReports = async () => {
        setLoader(true);
        try {
            const modifiedReportFilter = { ...reportFilter, start_index: 0, page_size: 0 };
            const result = await getAuditLogApi(tmsId, modifiedReportFilter, false);

            if (result?.data?.code === 200) {
                let data: AuditResponse[] = result?.data?.data || [];

                let exportToExcel: string[][] = [
                    ["Username", "Load Id", "TMS Load Number", "Time stamp", "Log"],
                ];

                data.forEach((value: AuditResponse) => {
                    const formattedRow: string[] = [
                        value.user_name,
                        value.load_id.toString(),
                        value.tms_load_number,
                        value.timestamp_utc,
                        value.log
                    ];
                    exportToExcel.push(formattedRow);
                });

                let filename = `Audit_Report_${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}`;
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';

                let ws = XLSX.utils.aoa_to_sheet(exportToExcel);
                const wb = { Sheets: { 'Audit Report': ws }, SheetNames: ['Audit Report'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data1 = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data1, filename + fileExtension);
            } else {
                console.error('Failed to fetch audit report data');
                // Optionally, you can show an error message to the user here
            }
        } catch (error) {
            console.error('Error exporting audit reports:', error);
            // Optionally, you can show an error message to the user here
        } finally {
            setLoader(false);
        }
    };

    function bindTrackingReports() {
        return trackingReports?.map((report, index) => (
            <>
                <tr className="border-0 ">
                    <td className="pe-0">
                        <span
                            onClick={() => {
                                const newIndexes = [...selectedIndex]; // Create a new array
                                newIndexes[index] = !newIndexes[index]; // Update the specific index
                                setSelectedIndex(newIndexes);
                            }} className="d-flex align-items-center gap-3">
                            <img src="images/accordion-arrow.svg" alt="accordion-arrow"
                                className={selectedIndex[index] ? "cursor-pointer table-accordion-arrow" : "cursor-pointer table-accordion-arrow collapsed"} data-bs-toggle="collapse"
                                data-bs-target={`#ReportAcc${index}`} />
                        </span>
                    </td>
                    <td className="text-nowrap ps-2">{report?.tms_load_number}</td>
                    <td className="text-nowrap">{report?.tracking_type ? report?.tracking_type : "-"}</td>
                    <td className="text-nowrap">{report?.tracking_provider ? report?.tracking_provider : "-"}</td>
                    <td className="text-nowrap">
                        <span data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title={report?.planned_start_date ? `${getTimestampToDate(report?.planned_start_date, 5)}` : ""}>
                            {getTimestampToDate(report?.planned_start_date, 11) || "-"}
                        </span>
                    </td>
                    <td>
                        <span data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title={report?.planned_end_date ? `${getTimestampToDate(report?.planned_end_date, 5)}` : ""}>
                            {getTimestampToDate(report?.planned_end_date, 11) || "-"}
                        </span>
                    </td>
                    <td>
                        <span data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title={report?.actual_start_date ? `${getTimestampToDate(report?.actual_start_date, 5)}` : ""}>
                            {getTimestampToDate(report?.actual_start_date, 11) || "-"}
                        </span>
                    </td>
                    <td>
                        <span data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title={report?.actual_end_date ? `${getTimestampToDate(report?.actual_end_date, 5)}` : ""}>
                            {getTimestampToDate(report?.actual_end_date, 11) || "-"}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td colSpan={8}
                        className={selectedIndex[index] ? "collapse show pt-0 px-5" : "collapse pt-0 px-5"} id={`#ReportAcc${index}`}>
                        <div className="table-responsive theme-table shadow-sm bg-white">
                            <table className="table table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Customer Name</th>
                                        <th className="text-nowrap">Customer ID</th>
                                        <th className="text-nowrap">Carrier Name</th>
                                        <th className="text-nowrap">Carrier ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-nowrap">{report?.customer.name ? report?.customer.name : "-"}</td>
                                        <td className="text-nowrap">{report?.customer.id ? report?.customer.id : "-"}</td>
                                        <td className="text-nowrap">{report?.carrier.name ? report?.carrier.name : "-"}</td>
                                        <td className="text-nowrap">{report?.carrier.id ? report?.carrier.id : "-"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </>)
        )
    }
    //SQ_H_1.7 - 1.15
    const getCompany = async () => {
        try {
            let response = await getCompanies()
            if (response?.data?.code == 200) {
                setCompany(response?.data?.data);
                if (response?.data?.data.length > 0) {
                    let selectedCompanyId = response?.data?.data[0]?.tms_id;
                    setTMSId(Number(selectedCompanyId));
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSortClick = (column: string) => {
        setReportFilter((prevState) => {
            if (prevState.sort_column === column) {
                // If the same column is clicked again, toggle the sort order
                return {
                    ...prevState,
                    sort_column: column,
                    sort_type: prevState.sort_type === 'asc' ? 'desc' : 'asc',
                    start_index: 1
                };
            } else {
                // If a different column is clicked, reset the sort order to 'asc'
                return {
                    ...prevState,
                    sort_column: column,
                    sort_type: 'asc',
                    start_index: 1
                };
            }
        });
    };
    const renderSortArrow = (column: string) => {
        if (reportFilter.sort_column === column) {
            return reportFilter.sort_type === 'asc' ? (
                <img src="/images/sort-dark-up-arrow (1).svg" alt="sort-arrow-up" className="cust-sort-arrow-color" />
            ) : (
                <img src="/images/sort-down-arrow-white.svg" alt="sort-arrow-down" className="cust-sort-arrow-color" />
            );
        }
        return <img src="/images/sort-down-arrow-white.svg" alt="sort-arrow-down" className="cust-sort-arrow-color" />;
    };

    const resetTrackingFilter = () => {
        setTrackingReportFilter(trackingObj)
        setTemporaryTrackingReportFilter(trackingObj)
        setTemporarySearch("")
        setIsFilterOpen(false);
        setCarrier(null);
        setCustomer(null);
    }

    //SQ_EX_2.16 - 2.17  PS_17 - 18
    const timeFrameOnChange = (startDate: Date | "", endDate: Date | "") => {
        setReportFilter((prevState) => ({
            ...prevState,
            from_date: startDate === "" ? "" : getTimestampToDate(startDate.toISOString(), 2) || "", // Ensure a string is always returned
            to_date: endDate === "" ? "" : getTimestampToDate(endDate.toISOString(), 2) || "", // Ensure a string is always returned
            start_index: 1,
        }));
    };
    return (
        <>
            {
                loader && <Loader />
            }
            {errorPopUp ? <SomethingWentWrong onClose={() => setErrorPopUp(false)} /> : null}

            <div className="col-md-12 fixedhd">
                <div className="px-4 py-4 mb-3 border-0 rounded-3">
                    <div className="d-flex align-items-center justify-content-between mb-4 ">

                        <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                            <li className="nav-item cust-nav-item" role="presentation" onClick={() => {
                                resetTrackingFilter()
                                setReportFilter(filterObj)
                                setCurrentReportsTab({
                                    trackingReports: true,
                                    summaryReport: false,
                                    auditHistory: false,
                                    trigger: currentReportsTab.trigger
                                });
                            }}>
                                <button
                                    className="nav-link cus-tab-link cus-tab-border color-grey border-0 ps-0 font-semibold font-14 nav-bg active"
                                    id="TrackingReport-tab" data-bs-toggle="tab" data-bs-target="#TrackingReport" type="button"
                                    role="tab" aria-controls="TrackingReport" aria-selected="true">Tracking Report</button>
                            </li>
                            <li className="nav-item cust-nav-item" role="presentation" onClick={() => {
                                setCurrentReportsTab({
                                    trackingReports: false,
                                    summaryReport: true,
                                    auditHistory: false,
                                    trigger: currentReportsTab.trigger
                                });
                                resetTrackingFilter()
                                filterObj.sort_column = "touches_per_business_day_count"
                                filterObj.sort_type = "desc"
                                setReportFilter(filterObj)
                            }}>
                                <button
                                    className="nav-link cus-tab-link cus-tab-border border-0 color-grey font-semibold font-14 nav-bg"
                                    id="SummaryReport-tab" data-bs-toggle="tab" data-bs-target="#SummaryReport"
                                    type="button" role="tab" aria-controls="SummaryReport" aria-selected="false">Summary Report</button>
                            </li>
                            {(groups[0] == RoleEnum.ETM_ADMIN && localStorage.getItem("isImpersonate") != "true") ?
                                <li className="nav-item cust-nav-item" role="presentation" onClick={() => {
                                    setCurrentReportsTab({
                                        trackingReports: false,
                                        summaryReport: false,
                                        auditHistory: true,
                                        trigger: currentReportsTab.trigger
                                    });
                                    resetTrackingFilter()
                                    setReportFilter(filterObj)
                                }}>
                                    <button
                                        className="nav-link cus-tab-link cus-tab-border border-0 color-grey font-semibold font-14 nav-bg"
                                        id="audithistory-tab" data-bs-toggle="tab" data-bs-target="#audithistory"
                                        type="button" role="tab" aria-controls="audithistory" aria-selected="false">Audit
                                        History</button>
                                </li> : <></>}

                        </ul>

                        <div className="d-flex gap-3">

                            {groups[0] == RoleEnum.ETM_ADMIN && localStorage.getItem("isImpersonate") != "true" ?
                                <div className="form-floating">
                                    <select className="form-select custom-form-input select-filter custom-select-arrow font-12"
                                        id="floatingSelect" aria-label="Floating label select example" value={tmsId}
                                        onChange={(e) => setTMSId(Number(e.target.value))}>
                                        {company && company?.length === 0 ? (
                                            <option value={"0"}>Select</option>
                                        ) : (
                                            company?.map((companyItem) => (
                                                <option key={companyItem?.tms_id} value={companyItem?.tms_id}>
                                                    {companyItem?.name}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                    <label className="font-11 top-nav-label-color pt-3 pb-0 font-regular text-nowrp"
                                        htmlFor="floatingSelect">Company name</label>
                                </div> : <></>}

                            {<TimeFrame onChange={timeFrameOnChange} />}

                            <div className="input-group input-search custom-reports-search">
                                <input type="search"
                                    //PSAF_04
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") handleSearch();
                                    }}
                                    className="form-control border-0 font-14 font-regular primary-bg-color"
                                    placeholder="Search"
                                    //PSAF_15
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (temporarySearch === '' && inputValue.trim() === '') {
                                            // If the input is empty and the new value is only spaces, don't update
                                            return;
                                        }
                                        setTemporarySearch(e.target.value)
                                    }}
                                    value={temporarySearch}
                                />
                                <button className="btn border-0 search-input-btn primary-bg-color" type="button"><img
                                    src="images/search-icon.svg" alt="search" onClick={handleSearch} /></button>
                            </div>
                            <div className="dropdown">
                                {currentReportsTab.trackingReports ? <button className={isFilterOpen ? "btn filter-btn border-0 custom-border-box-shadow primary-bg-color show py-2" : "btn filter-btn border-0 custom-border-box-shadow primary-bg-color"}
                                    onClick={() => {
                                        setIsFilterOpen(!isFilterOpen);
                                    }}>
                                    <img src="images/filter-icon.svg" alt="filter-icon" />
                                </button> : <></>}
                                <div className={isFilterOpen ? "dropdown-menu dropdown-menu-end border-0 shadow p-3 custom-filter-width primary-bg-color show top-50px" : "dropdown-menu dropdown-menu-end border-0 shadow dd-position p-3 custom-filter-width primary-bg-color"}
                                    aria-labelledby="dropdownMenuLink" data-popper-placement="bottom-end">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h5 className="font-bold font-20 mb-0 custom-header-color">Advanced Filter</h5>
                                        <span><img className="cursor-pointer close-img" src="images/cross-icon-black.svg"
                                            alt="close"
                                            onClick={() => {
                                                setIsFilterOpen(!isFilterOpen);
                                            }} /></span>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <label className="form-label font-14 font-semibold label-color " htmlFor="TrackingType">Tracking Type</label>
                                            <select id="TrackingProvider"
                                                value={temporaryTrackingReportFilter.tracking_status}
                                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                    //PSAF_09
                                                    setTemporaryTrackingReportFilter((prevState) => ({
                                                        ...prevState,
                                                        tracking_status: e.target.value,
                                                    }))
                                                }}>
                                                <option selected value="">Select</option>
                                                {bindStatus()}
                                            </select>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <label className="form-label font-14 font-semibold label-color" htmlFor="TrackingProvider">Tracking Provider</label>
                                            <select id="TrackingProvider" value={temporaryTrackingReportFilter.tracking_provider}
                                                className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                                                aria-label="Default select example"
                                                onChange={(e) => {
                                                    //PSAF_09
                                                    setTemporaryTrackingReportFilter((prevState) => ({
                                                        ...prevState,
                                                        tracking_provider: e.target.value,
                                                    }))
                                                }}>
                                                <option selected value="">Select</option>
                                                {bindVisibilityProviders()}
                                            </select>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <label className="form-label font-14 font-semibold label-color " htmlFor="CustomerName">Customer Name</label>
                                            <Select
                                                className="dropdown custom-form-input"
                                                name="customer"
                                                maxMenuHeight={150}
                                                placeholder="Select"
                                                value={customer}
                                                options={shipperOptions}
                                                onChange={handleCustomer}
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <label className="form-label font-14 font-semibold label-color " htmlFor="CarrierName">Carrier Name</label>
                                            {/*/PS_NV_05*/}
                                            <Select
                                                className="dropdown custom-form-input"
                                                name="carrier"
                                                maxMenuHeight={150}
                                                placeholder="Select"
                                                value={carrier}
                                                options={carrierOptions}
                                                onChange={handleCarrier}
                                                isClearable
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-end align-items-center py-3">
                                                <button onClick={() => {
                                                    //PSAF_13
                                                    resetTrackingFilter()
                                                }}
                                                    className="btn text-nowrap w-md-100 font-medium color-black-v1 font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4">Cancel</button>
                                                <button onClick={() => {
                                                    //PSAF_07
                                                    setTrackingReportFilter(temporaryTrackingReportFilter)
                                                    setIsFilterOpen(!isFilterOpen)
                                                }}
                                                    className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" disabled={temporaryTrackingReportFilter.carrier_id === "" &&
                                                        temporaryTrackingReportFilter.customer_id === "" &&
                                                        temporaryTrackingReportFilter.tracking_provider === "" &&
                                                        temporaryTrackingReportFilter.tracking_status === ""}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button disabled={!(totalCount > 0)} className="btn export-btn border-0 custom-border-box-shadow primary-bg-color px-3" onClick={() => { handleExportToExcel() }}>
                                <span className="text-nowrap export-button" ><img src="images/export-image.svg" alt="export-img" className="me-2 mb-1" />Export</span>
                            </button>

                        </div>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="TrackingReport" role="tabpanel"
                            aria-labelledby="TrackingReport-tab">
                            <div className="table-responsive theme-table bg-white ">
                                <table className="table table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th className="pe-0"></th>
                                            <th className="text-nowrap ps-2">Load ID<span onClick={() => handleSortClick('tms_load_number')}
                                                className="ms-1 cursor-pointer">
                                                {renderSortArrow("tms_load_number")}
                                            </span></th>
                                            <th className="text-nowrap">Tracking Type<span onClick={() => handleSortClick('tracking_type')}
                                                className="ms-1 cursor-pointer">{renderSortArrow("tracking_type")}</span></th>
                                            <th className="text-nowrap">Tracking Provider<span onClick={() => handleSortClick('tracking_provider')}
                                                className="ms-1 cursor-pointer">{renderSortArrow("tracking_provider")}</span></th>
                                            <th className="text-nowrap">Planned Start Date
                                                <img className="ms-2 tooltip-img" src="images/tooltip-icon.svg"
                                                    alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Hover the date to see year" />
                                                <span className="ms-1 cursor-pointer" onClick={() => handleSortClick('planned_start_date')}>{renderSortArrow("planned_start_date")}</span>
                                            </th>
                                            <th className="text-nowrap">Planned End date
                                                <img className="ms-2 tooltip-img" src="images/tooltip-icon.svg"
                                                    alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Hover the date to see year" />
                                                <span className="ms-1 cursor-pointer" onClick={() => handleSortClick('planned_end_date')}>{renderSortArrow("planned_end_date")}</span>
                                            </th>

                                            <th className="text-nowrap">Actual Start date<img className="ms-2 tooltip-img" src="images/tooltip-icon.svg"
                                                alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Hover the date to see year" />
                                                <span className="ms-1 cursor-pointer" onClick={() => handleSortClick('actual_start_date')}>{renderSortArrow("actual_start_date")}</span></th>

                                            <th className="text-nowrap">Actual End date
                                                <img className="ms-2 tooltip-img" src="images/tooltip-icon.svg"
                                                    alt="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Hover the date to see year" />
                                                <span className="ms-1 cursor-pointer" onClick={() => handleSortClick('actual_end_date')}>{renderSortArrow("actual_end_date")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bindTrackingReports()}
                                        {noDataFound && <NoDataFound />}
                                        {noResultsFound && <NoResultsFound />}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="SummaryReport" role="tabpanel" aria-labelledby="SummaryReport-tab">
                            {currentReportsTab.summaryReport &&
                                <SummaryReports Data={summaryReports} renderSortArrow={renderSortArrow} handleSortClick={handleSortClick} Nodata={noDataFound} NoResults={noResultsFound} />}
                        </div>
                        <div className="tab-pane fade" id="audithistory" role="tabpanel" aria-labelledby="audithistory-tab">
                            {currentReportsTab.auditHistory &&
                                <AuditHistory Data={auditResponse} renderSortArrow={renderSortArrow} handleSortClick={handleSortClick} NoData={noDataFound} NoResults={noResultsFound} />}
                        </div>
                    </div>
                    <div className="mt-3 d-md-flex justify-content-between">
                        <p className="mb-0 font-14 font-regular color-grey-v1 "># of Records :
                            <span className="color-black-v1 font-semibold primary-header-v1 font-18">{((reportFilter.start_index - 1) + Number(reportFilter.page_size)) < totalCount ? ` ${((reportFilter.start_index - 1) + Number(reportFilter.page_size))} ` : ` ${totalCount} `} </span>out of
                            <span className="color-black-v1 font-semibold primary-header-v1 font-18">{` ${totalCount}`}</span>
                        </p>
                        {totalCount > reportFilter?.page_size ?
                            <PaginationComponent currentPage={(reportFilter.start_index - 1) / reportFilter.page_size} viewCount={reportFilter.page_size} totalCount={totalCount} onPageChange={handlePageClick} /> : <></>}
                    </div>

                </div>
            </div>
        </>
    )
}

export default Reports;