import { SummaryReportProps } from "../../interface/ReportsInterface";
import { userRole } from "../../utils/utils";
import NoDataFound from "../Resuables/NoDataFound";
import NoResultsFound from "../Resuables/NoResultsFound";

const SummaryReports: React.FC<SummaryReportProps> = ({ Data, handleSortClick, renderSortArrow, NoResults, Nodata }) => {
    function bindSummaryReports() {
        return Data?.map((report, index) => (
            <>
                <tr>
                    <td className="text-nowrap py-3 px-4">{report?.user_name}<p
                        className="m-0 table-text-color pt-1">{userRole(report?.role)}</p>
                    </td>
                    <td className="text-nowrap text-end">{report?.at_origin_count}</td>
                    <td className="text-nowrap text-end">{report?.picked_up_count}</td>
                    <td className="text-nowrap text-end">{report?.in_transit_count}</td>
                    <td className="text-nowrap text-end">{report?.at_consignee_count}</td>
                    <td className="text-nowrap text-end">{report?.delivered_count}</td>
                    <td className="text-nowrap text-end">{report?.touches_per_business_day_count}</td>
                </tr>
            </>)
        )
    }

    return (
        <>

            <div className="table-responsive theme-table bg-white">
                <table className="table table-borderless mb-0">
                    <thead>
                        <tr>
                            <th className="text-nowrap">Username<span onClick={() => handleSortClick('user_name')}
                                className="ms-1 cursor-pointer">
                                {renderSortArrow("user_name")}
                            </span></th>
                            <th className="text-nowrap text-end">At Origin<span onClick={() => handleSortClick('at_origin_count')}
                                className="ms-1 cursor-pointer">
                                {renderSortArrow("at_origin_count")}
                            </span></th>
                            <th className="text-nowrap text-end">Picked Up<span onClick={() => handleSortClick('picked_up_count')}
                                className="ms-1 cursor-pointer">
                                {renderSortArrow("picked_up_count")}
                            </span></th>
                            <th className="text-nowrap text-end">In Transit<span onClick={() => handleSortClick('in_transit_count')}
                                className="ms-1 cursor-pointer">
                                {renderSortArrow("in_transit_count")}
                            </span></th>
                            <th className="text-nowrap text-end">At Consignee<span onClick={() => handleSortClick('at_consignee_count')}
                                className="ms-1 cursor-pointer">
                                {renderSortArrow("at_consignee_count")}
                            </span></th>
                            <th className="text-nowrap text-end">Delivered<span onClick={() => handleSortClick('delivered_count')}
                                className="ms-1 cursor-pointer">
                                {renderSortArrow("delivered_count")}
                            </span></th>
                            <th className="text-nowrap text-end">Touches per Business Day<span onClick={() => handleSortClick('touches_per_business_day_count')}
                                className="ms-1 cursor-pointer">
                                {renderSortArrow("touches_per_business_day_count")}
                            </span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {bindSummaryReports()}
                        {Nodata ? <NoDataFound /> : <></>}
                        {NoResults ? <NoResultsFound /> : <></>}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default SummaryReports;