//PSTL_1.0
import React, { useRef, useState } from "react";
import { TimeLine } from "../../../interface/LoadInterface";

//PSTL_1.1
const TrackingTimeLine: React.FC<{ timeLine: TimeLine[] }> = ({ timeLine }) => {
    //PSTL_1.2
    const containerRef = useRef<HTMLDivElement>(null);
    //PSTL_1.3
    const stepperRefs = useRef<{ [key: string]: HTMLLIElement | null }>({});
    //PSTL_1.4
    const [currentPage, setCurrentPage] = useState(0);
    //PSTL_1.5
    const ITEMS_PER_PAGE : number = 4;

    // Filter the timeLine array to only include items with both trackingVendor and trackingMethod
    //PSTL_1.6
    const filterConsecutiveDuplicates = (timeLines: TimeLine[]) => {
        if(!timeLines){
            return []
        }
        return timeLines.filter((item, index) => {
            if (!(item.tracking_vendor || item.tracking_method)) { return false};
            if (index === 0) return true; // Always display the first item
            const prevItem:TimeLine = timeLines[index - 1];
            // Check if trackingVendor and trackingMethod are the same as the previous item
            return !(item.tracking_vendor === prevItem.tracking_vendor && item.tracking_method === prevItem.tracking_method);
        });
    }
    const filteredTimeLine = filterConsecutiveDuplicates(timeLine);
    const stepperIds = filteredTimeLine?.map((_, index) => `stepper${index + 1}`);
    //PSTL_1.7 - 1.12
    //Using the dynamic reference created during the time of mapping the next set of data can be scrolled smoothly.
    const scrollToStepper = (stepperId: string) => {
        //PSTL_1.8
        const stepper = stepperRefs.current[stepperId];
        //PSTL_1.9 - 1.12
        if (containerRef.current && stepper) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const stepperRect = stepper.getBoundingClientRect();
            const scrollX = stepperRect.left - containerRect.left + containerRef.current.scrollLeft;
            containerRef.current.scrollTo({
                left: scrollX,
                behavior: 'smooth',
            });
        }
    };

    //PSTL_1.13 - 1.14
    const handleNext = () => {
        const nextPage = currentPage + 1;
        const nextStepperIndex = nextPage * ITEMS_PER_PAGE;
        if (nextStepperIndex < stepperIds.length) {
            const nextStepperId = stepperIds[nextStepperIndex];
            scrollToStepper(nextStepperId);
            setCurrentPage(nextPage);
        }
    };

    //PSTL_1.15 - 1.17
    const handlePrev = () => {
        const prevPage = currentPage - 1;
        const prevStepperIndex = prevPage * ITEMS_PER_PAGE;
        if (prevPage >= 0) {
            const prevStepperId = stepperIds[prevStepperIndex];
            scrollToStepper(prevStepperId);
            setCurrentPage(prevPage);
        }
    };

    //PSTL_1.19 - 1.24
    const bindTimeLine = () => {
        return filteredTimeLine?.map((value, index) => {
            const id = `stepper${index + 1}`;
            return (
                <li key={id} id={id} ref={(el) => (stepperRefs.current[id] = el)}
                    className={`stepper-sty text-center completed ${index != 0 ? "position-relative":""}`}>
                    <span className={`stepper-icon-sty align-items-center font-semibold ${index != 0 ? "stepper-line":""}`}>{index + 1}</span>
                    <p className={`stepper-text mt-3 mb-0 font-medium primary-text-color`}>
                        {value.tracking_vendor ? value.tracking_vendor : ''} 
                        {value.tracking_vendor && value.tracking_method ? ' - ' : ''}
                        {value.tracking_method ? value.tracking_method : ''}
                    </p>
                </li>
            );
        });
    };

    //PSTL_1.20 - 1.24
    return (
        <div className="col-12 col-lg-6 mb-3">
            {filteredTimeLine.length > 0 ? (
                <div className="content-container h-100 position-relative __web-inspector-hide-shortcut__">
                    <h3 className="font-18 font-semibold primary-text-color">Tracking Timeline</h3>
                    <div className="custom-stepper mt-5 w-100" ref={containerRef}>
                        <ol className="list-unstyled d-inline-flex align-items-center">
                            {bindTimeLine()}
                        </ol>
                    </div>
                    {currentPage > 0 && (
                        <button
                            type="button"
                            onClick={handlePrev}
                            className="shadow-sm navigate-prew-arrow navigate-arrow position-absolute d-flex align-items-center"
                        >
                            <img src="/images/tarcking-navigation-arrow.svg" alt="tracking-navigation-arrow" />
                        </button>
                    )}
                    {(currentPage + 1) * ITEMS_PER_PAGE < stepperIds.length && (
                        <button
                            type="button"
                            onClick={handleNext}
                            className="shadow-sm navigate-next-arrow navigate-arrow position-absolute d-flex align-items-center"
                        >
                            <img src="/images/tarcking-navigation-arrow.svg" alt="tracking-navigation-arrow" />
                        </button>
                    )}
                </div>
            ) : (
                <div className="content-container h-100 position-relative">
                    <h3 className="font-18 font-semibold primary-text-color mb-3">Tracking Timeline</h3>
                    <div className="tracking-timeline empty-state h-145">
                        <div className="d-flex flex-row justify-content-center align-items-center h-inherit">
                            <span className="no-timeline-events-found me-2"></span>
                            <p className="font-13 font-regular primary-text-color m-0">No Tracking Timeline</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TrackingTimeLine;