import all from 'country-calling-code';
//import * as bootstrap from 'bootstrap';
import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { TMSConfiguration, TMSErrorConfiguration, TmsTestConnectionRequest } from '../../interface/TmsCreationInterface';
import { ConnectionTest, postTMS, retrieveOrganizationData } from '../../service/Api';
import { decryptData, encryptData, encryptUserId, initializeTooltips } from '../../utils/utils';
import Loader from '../Resuables/Loader';
import { SomethingWentWrong } from '../Resuables/SomethingWentWrong';
import { AuthenticationTypeDisplaytext, AuthenticationMethod, CommunicationPlatform, CompanyStatus, DriverApplicationHours, EldDisconnectionHours, LocationProviderDisconnectionHours, NotificationDetail, SecondTierTrackingProviders, SupportEscalationHours, TokenType, TrackingMethod, TrackingProvider, TrackingMethodDisplaytext, CommunicationPlatformDisplaytext, CompanyStatusDisplaytext, ConnectionStatus } from '../../enums/enums';
import ConfirmationPopUp from '../Resuables/ConfirmationPopUp';
import { cloneDeep } from 'lodash';
import moment from 'moment';

function TMSCreation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loader, setLoader] = useState<boolean>(false)
    const [exception, setException] = useState<boolean>(false)
    const [confirmationPopup, setConfirmationPopup] = useState<boolean>(false);
    const [tokenType, setTokenType] = useState<string>('');

    const defaultTMSConfiguration: TMSConfiguration = {
        name: '',
        status: CompanyStatus.ACTIVE,
        access_token_type: TokenType.EXPIRED,
        system_configuration: {
            preferred_vendor: '',
            preferred_tracking_method: TrackingMethod.TRUCK,
            preferred_communication_platform: CommunicationPlatform.EMAIL,
            tracking_event_notify_hours: 0,
            second_tier_notify_hours: 0,
            support_escalation_hours: 0,
            eld_check_interval_hours: 0,
            driver_app_check_interval_hours: 0,
            location_provider_check_interval: 0,
            location_provider_quiet_hrs_start_time: '',
            location_provider_quiet_hrs_end_time: '',
        },
        webhook_configuration: {
            auth_type: AuthenticationMethod.BASIC_AUTHENTICATION,
            identifier: '',
            secret: '',
            token: '',
            refresh_token: '',
            token_expiry: '',
            token_endpoint: '',
            load_event_update_url: '',
            driver_message_update_url: '',
            test_connection_url: '',
        },
        users:
        {
            user_name: '',
            user_type: '',
            email_address: '',
            phone_number: '',
            country_code: '1'
        }
    };
    const defaultTMSErrorConfiguration: TMSErrorConfiguration = {
        name: '',
        status: '',
        access_token_type: '',
        api_validity_utc: '',
        system_configuration: {
            preferred_vendor: '',
            preferred_tracking_method: '',
            preferred_communication_platform: '',
            tracking_event_notify_hours: '',
            second_tier_notify_hours: '',
            support_escalation_hours: '',
            eld_check_interval_hours: '',
            driver_app_check_interval_hours: '',
            location_provider_check_interval: '',
            location_provider_quiet_hrs_start_time: '',
            location_provider_quiet_hrs_end_time: '',
        },
        webhook_configuration: {
            auth_type: '',
            identifier: '',
            secret: '',
            token: '',
            refresh_token: '',
            token_expiry: '',
            token_endpoint: '',
            load_event_update_url: '',
            driver_message_update_url: '',
            test_connection_url: '',
        },
        users:
        {
            user_name: '',
            user_type: '',
            email_address: '',
            phone_number: '',
            country_code: ''
        },
        delay_reasons: {
            error_code: '',
            reason: ''
        }
    };
    const [tmsConfiguration, setTMSConfiguration] = useState<TMSConfiguration>(defaultTMSConfiguration);
    const [offlineConnectionStatus, setOfflineConnectionStatus] = useState<boolean>(false);
    const tmsId: string = location?.state?.companyId
    const tmsName: string = location?.state?.companyName
    let [tmsErrorDetails, setTmsErrorDetails] = useState<TMSErrorConfiguration>(defaultTMSErrorConfiguration)
    const [showSecret, setShowSecret] = useState<boolean>(false);
    const sectionFieldMap = {
        'AccountInformation': ['name', 'users.user_name', 'users.email_address', 'users.phone_number', 'status'],
        'APIKeyValidity': ['api_validity_utc'],
        'Configuration': ['system_configuration.preferred_vendor', 'system_configuration.preferred_tracking_method', 'system_configuration.preferred_communication_platform'],
        'Authentication': ['webhook_configuration.auth_type', 'webhook_configuration.load_event_update_url', 'webhook_configuration.driver_message_update_url', 'webhook_configuration.test_connection_url'],
        'TrackingTimelineEscalation': ['system_configuration.tracking_event_notify_hours', 'system_configuration.second_tier_notify_hours', 'system_configuration.support_escalation_hours', 'system_configuration.location_provider_quiet_hrs_start_time', 'system_configuration.location_provider_quiet_hrs_end_time'],
        'TrackingDisconnectionEscalation': ['system_configuration.eld_check_interval_hours', 'system_configuration.driver_app_check_interval_hours', 'system_configuration.location_provider_check_interval']
    };
    const authenticationMethods = Object.values(AuthenticationMethod);
    const tmsStatuses = Object.values(CompanyStatus);
    const visibilityProviders = Object.values(TrackingProvider);
    const trackingMethods = Object.values(TrackingMethod);
    const communicationPlatforms = Object.values(CommunicationPlatform);
    const notificationDetailHrs = Object.values(NotificationDetail).filter(value => typeof value === 'number');
    const secondTierTrackingProviderHrs = Object.values(SecondTierTrackingProviders).filter(value => typeof value === 'number');
    const supportEscalationHrs = Object.values(SupportEscalationHours).filter(value => typeof value === 'number');
    const eldDisconnectionHrs = Object.values(EldDisconnectionHours).filter(value => typeof value === 'number');
    const driverApplicationHrs = Object.values(DriverApplicationHours).filter(value => typeof value === 'number');
    const locationProviderDisconnectHrs = Object.values(LocationProviderDisconnectionHours).filter(value => typeof value === 'number');


    useEffect(() => {
        if (tmsId) {
            getOrganizationData();
        }
    }, []);

    const getOrganizationData = async () => {
        try {
            setLoader(true)
            const response: any = await retrieveOrganizationData(tmsId)
            if (response.data.code == 200) {
                const fetchedTMSConfig: TMSConfiguration = response.data.data;
                setTokenType(fetchedTMSConfig.access_token_type)
                const decryptedData = decryptData(fetchedTMSConfig.webhook_configuration.secret)
                const tmsStatuscheck = fetchedTMSConfig?.status == CompanyStatus.ACTIVE || fetchedTMSConfig?.status == CompanyStatus.INACTIVE
                setTMSConfiguration({
                    ...fetchedTMSConfig,
                    status: tmsStatuscheck ? fetchedTMSConfig.status : CompanyStatus.ACTIVE,
                    system_configuration: {
                        ...fetchedTMSConfig.system_configuration,
                        preferred_communication_platform: fetchedTMSConfig?.system_configuration?.preferred_communication_platform ? fetchedTMSConfig?.system_configuration?.preferred_communication_platform : CommunicationPlatform.EMAIL,
                        preferred_tracking_method: fetchedTMSConfig?.system_configuration?.preferred_tracking_method ? fetchedTMSConfig?.system_configuration?.preferred_tracking_method : TrackingMethod.TRUCK
                    },
                    webhook_configuration: {
                        ...fetchedTMSConfig.webhook_configuration,
                        auth_type: fetchedTMSConfig.webhook_configuration.auth_type ? fetchedTMSConfig.webhook_configuration.auth_type : AuthenticationMethod.BASIC_AUTHENTICATION,
                        secret: decryptedData || "",
                    }
                })
            }
            else {
                setException(true)
            }
            setLoader(false)
            initializeTooltips()
        }
        catch (error) {
            console.log("'The retrieve error", error)
            setException(true)
            setLoader(false)
        }
    }

    const onTimeChange = (timeField: string, date: Date | null) => {
        if (date) {
            const formattedTime = formatTimeString(date);
            if (formattedTime) {
                if (timeField === 'location_provider_quiet_hrs_end_time') {
                    const startTime = parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_start_time);
                    if (startTime && date < startTime) {
                        handleTimeChange(timeField, ''); // Clear the end time if it's earlier than the start time
                        return;
                    }
                }
                handleTimeChange(timeField, formattedTime);
            }
        }
    };

    const handleTimeChange = (name: string, value: string) => {
        // Update state with the new valid time
        setTMSConfiguration((prevState) => ({
            ...prevState,
            system_configuration: {
                ...prevState.system_configuration,
                [name]: value, // Parse and set to 0 if parsing fails
            }
        }))
    };

    const parseTimeString = (timeString?: string): Date | undefined => {
        if (!timeString) return undefined;
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        return date;
    };

    // Function to get the start of the day
    const getStartOfDay = (): Date => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    };

    // Function to get the end of the day
    const getEndOfDay = (): Date => {
        const date = new Date();
        date.setHours(23, 59, 59, 999);
        return date;
    };

    // Utility function to format Date object to "HH:mm" string
    const formatTimeString = (date?: Date): string => {
        if (!date) return '';
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleTMSInputChange = () => {
        tmsErrorDetails = defaultTMSErrorConfiguration;
        if (!tmsConfiguration.name) {
            tmsErrorDetails.name = 'Enter a value'
        }
        if (!tmsConfiguration.webhook_configuration.identifier) {
            tmsErrorDetails.webhook_configuration.identifier = 'Enter a value'
        }
        if (!tmsConfiguration.webhook_configuration.secret) {
            tmsErrorDetails.webhook_configuration.secret = 'Enter a value'
        }
        if (tmsConfiguration.webhook_configuration.auth_type === authenticationMethods.find(authenticationMethod => authenticationMethod === 'OAUTH')) {
            if (!tmsConfiguration.webhook_configuration.token_endpoint) {
                tmsErrorDetails.webhook_configuration.token_endpoint = 'Enter a value'
            }
            else if (!tmsConfiguration.webhook_configuration.token_endpoint.startsWith('https://')) {
                tmsErrorDetails.webhook_configuration.token_endpoint = 'Please enter a valid URL'
            }
        }
        let tmsUserErrorConfiguration = tmsErrorDetails.users;
        const tmsUserConfiguration = tmsConfiguration.users;
        if (!tmsUserConfiguration.user_name) {
            tmsUserErrorConfiguration.user_name = 'Enter a value'
        }
        if (!tmsUserConfiguration.email_address) {
            tmsUserErrorConfiguration.email_address = 'Enter a value'
        } else if (!/^[^\s@]+@[a-zA-Z0-9.-]+$/.test(tmsUserConfiguration.email_address.trim())) {
            tmsUserErrorConfiguration.email_address = 'Please enter a valid email address'
        }
        if (!tmsUserConfiguration.phone_number) {
            tmsUserErrorConfiguration.phone_number = 'Enter a value'
        } else if (tmsUserConfiguration.phone_number.length < 8) {
            tmsUserErrorConfiguration.phone_number = 'Phone number must be at least 8 characters long'
        }

        if (!tmsConfiguration.status) {
            tmsErrorDetails.status = 'Select a value'
        }
        if (tmsConfiguration.status == CompanyStatus.ACTIVE) {
            if (!tmsConfiguration.api_validity_utc || tmsConfiguration.api_validity_utc === 'Invalid date') {
                tmsErrorDetails.api_validity_utc = 'Enter a value'
            }
        }
        if (!tmsConfiguration.webhook_configuration.auth_type) {
            tmsErrorDetails.webhook_configuration.auth_type = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.preferred_communication_platform) {
            tmsErrorDetails.system_configuration.preferred_communication_platform = 'Select a value'
        }
        if (!tmsConfiguration.webhook_configuration.test_connection_url) {
            tmsErrorDetails.webhook_configuration.test_connection_url = 'Enter a value'
        } else if (!tmsConfiguration.webhook_configuration.test_connection_url.startsWith('https://')) {
            tmsErrorDetails.webhook_configuration.test_connection_url = 'Please enter a valid URL'
        }
        if (!tmsConfiguration.system_configuration.driver_app_check_interval_hours) {
            tmsErrorDetails.system_configuration.driver_app_check_interval_hours = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.eld_check_interval_hours) {
            tmsErrorDetails.system_configuration.eld_check_interval_hours = 'Select a value'
        }
        if (!tmsConfiguration.webhook_configuration.load_event_update_url) {
            tmsErrorDetails.webhook_configuration.load_event_update_url = 'Enter a value'
        } else if (!tmsConfiguration.webhook_configuration.load_event_update_url.startsWith('https://')) {
            tmsErrorDetails.webhook_configuration.load_event_update_url = 'Please enter a valid URL'
        }
        if (!tmsConfiguration.system_configuration.location_provider_check_interval) {
            tmsErrorDetails.system_configuration.location_provider_check_interval = 'Select a value'
        }
        if (!tmsConfiguration.webhook_configuration.driver_message_update_url) {
            tmsErrorDetails.webhook_configuration.driver_message_update_url = 'Enter a value'
        } else if (!tmsConfiguration.webhook_configuration.driver_message_update_url.startsWith('https://')) {
            tmsErrorDetails.webhook_configuration.driver_message_update_url = 'Please enter a valid URL'
        }
        if (!tmsConfiguration.system_configuration.tracking_event_notify_hours) {
            tmsErrorDetails.system_configuration.tracking_event_notify_hours = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.preferred_vendor) {
            tmsErrorDetails.system_configuration.preferred_vendor = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.second_tier_notify_hours) {
            tmsErrorDetails.system_configuration.second_tier_notify_hours = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.support_escalation_hours) {
            tmsErrorDetails.system_configuration.support_escalation_hours = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.location_provider_quiet_hrs_start_time) {
            tmsErrorDetails.system_configuration.location_provider_quiet_hrs_start_time = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.location_provider_quiet_hrs_end_time) {
            tmsErrorDetails.system_configuration.location_provider_quiet_hrs_end_time = 'Select a value'
        }
        if (!tmsConfiguration.system_configuration.preferred_tracking_method) {
            tmsErrorDetails.system_configuration.preferred_tracking_method = 'Select a value'
        }
        setTmsErrorDetails({
            ...tmsErrorDetails,
            users: tmsUserErrorConfiguration
        });
        // PS_FP_1.24-PS_FP_1.25
    };


    const handleTMSCancel = () => {
        setTMSConfiguration(defaultTMSConfiguration);
        setTmsErrorDetails(defaultTMSErrorConfiguration);
        navigate('/tms-details');
    };

    const findFirstErrorSection = () => {
        for (const [sectionId, fields] of Object.entries(sectionFieldMap)) {
            if (fields.some(field => tmsErrorDetails[field as keyof TMSErrorConfiguration] !== '')) {
                return sectionId;
            }
        }
        return null;
    };
    const hasAnyNonEmptyString = (obj: any): boolean => {
        if (typeof obj === 'string') {
            return obj !== '';
        }
        if (Array.isArray(obj)) {
            return obj.some(item => hasAnyNonEmptyString(item));
        }
        if (typeof obj === 'object' && obj !== null) {
            return Object.values(obj).some(value => hasAnyNonEmptyString(value));
        }
        return false;
    };

    const handleTMSSubmit = async () => {
        handleTMSInputChange()
        const hasAnyError = hasAnyNonEmptyString(tmsErrorDetails);
        if (hasAnyError) {
            const firstErrorSection = findFirstErrorSection();
            if (firstErrorSection) {
                const element = document.getElementById(firstErrorSection);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
            return;
        }
        const OrganizationPayload = cloneDeep(tmsConfiguration);
        const encryptedFormData = encryptData(OrganizationPayload.webhook_configuration.secret);
        OrganizationPayload.webhook_configuration.identifier = tmsConfiguration.webhook_configuration.identifier.trim()
        OrganizationPayload.webhook_configuration.secret = encryptedFormData || ""

        if (OrganizationPayload.api_validity_utc === "" || OrganizationPayload.api_validity_utc === "Invalid date") {
            delete OrganizationPayload.api_validity_utc;
        }
        try {
            setLoader(true)
            const response: any = await postTMS(OrganizationPayload);
            // PS_FP_1.29-PS_FP_1.33 checkfor the response
            if (response.data.code === 201 || response.data.code === 200) {
                const action = OrganizationPayload.tms_id ? "update" : "create";
                navigate('/tms-details', { state: { openToastMessage: true, action } });
            } else if (response.data.code === 400 && response.data.errors && response.data.errors.length > 0) {
                const error = response.data.errors[0];

                switch (error.field) {
                    case 'TmsName':
                        setTmsErrorDetails(prevError => ({
                            ...prevError,
                            name: error.message
                        }));
                        const errorSection = findFirstErrorSection();
                        if (errorSection) {
                            const element = document.getElementById(errorSection);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                            }
                        }
                        break;
                    case 'Email':
                        setTmsErrorDetails(prevError => ({
                            ...prevError,
                            users: {
                                ...prevError.users,
                                email_address: error.message
                            }
                        }));
                        const firstErrorSection = findFirstErrorSection();
                        if (firstErrorSection) {
                            const element = document.getElementById(firstErrorSection);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                            }
                        }
                        break;
                    default:
                        setException(true);
                }
            } else {
                setException(true);
            }
            setLoader(false)

            // Check response and navigate or display appropriate error/warning
        } catch (error) {
            setException(true)
            console.log("the error logging", error)
            setLoader(false)
        }
    };

    const handleEndpointBlur = async (connectionTestURl: string) => {
        // Handling onBlur event for Endpoint URL Validation
        // PS_FP_1.19-PS_FP_1.21
        if (!connectionTestURl) {
            return; // Exit the function if the URL is empty
        }
        if (!connectionTestURl.startsWith('https://')) {
            return
        }
        try {
            setLoader(true)

            const payload: TmsTestConnectionRequest = {
                identifier: tmsConfiguration.webhook_configuration.identifier,
                secret: encryptUserId(tmsConfiguration.webhook_configuration.secret),
                token_endpoint_url: tmsConfiguration.webhook_configuration.token_endpoint,
                test_connection_url: connectionTestURl,
                authentication_type: tmsConfiguration.webhook_configuration.auth_type,
            }


            // PS_FP_1.22-PS_FP_1.24
            const response = await ConnectionTest(payload);
            if (response.status == 200) {
                setOfflineConnectionStatus(false)
                setTMSConfiguration((prevState) => ({
                    ...prevState,
                    connection_status: ConnectionStatus.ONLINE
                }))

            } else {
                setOfflineConnectionStatus(true)
                setTMSConfiguration((prevState) => ({
                    ...prevState,
                    connection_status: ConnectionStatus.OFFLINE
                }))

            }
            setLoader(false)

        }
        catch (error) {
            setOfflineConnectionStatus(true)

        }
        setLoader(false)
    };


    return (
        <>
            {
                loader && <Loader />
            }
            {
                exception && <SomethingWentWrong onClose={() => setException(false)} />
            }
            {confirmationPopup && (
                <ConfirmationPopUp
                    heading="Alert"
                    content={
                        tmsConfiguration.access_token_type === TokenType.EXPIRED
                            ? "Changing the access token preference will immediately EXPIRE the non-expiring token. Are you sure you want to proceed?"
                            : "Changing the access token preference will immediately EXPIRE the existing token. Are you sure you want to proceed?"
                    }
                    onClose={() => {
                        setTMSConfiguration((prevState) => ({
                            ...prevState,
                            access_token_type: tmsConfiguration.access_token_type == TokenType.EXPIRED ? TokenType.NONEXPIRED : TokenType.EXPIRED
                        }))
                        setConfirmationPopup(false)
                    }}
                    oKClick={() => {
                        if (tmsConfiguration.access_token_type) {
                            setConfirmationPopup(false);
                        }
                    }}
                />
            )}
            {offlineConnectionStatus && (
                <div
                    className="modal fade show"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "block", paddingLeft: 0, backgroundColor: "rgba(0, 0, 0, .5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content secondary-bg-color">
                            <div className="modal-body pt-4">
                                <div className="row text-center justify-content-center">
                                    <img
                                        src="images/alert-icon.svg"
                                        alt="alert-icon"
                                        className="alert-icon p-0"
                                    />
                                    <h1 className="primary-header mt-3" id="exampleModalLabel">
                                        Alert
                                    </h1>
                                    <p className="form-text-color font-16 font-regular">
                                        Connection test failed. Please verify the provided URL and system configuration.                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center pb-4 pt-0 border-0">
                                <button
                                    onClick={() => setOfflineConnectionStatus(false)}
                                    type="button"
                                    className="btn primary-btn font-semibold px-4"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-12 py-4 px-5 fixedhd">
                    <h5 className="font-13 font-regular top-nav-label-dark mb-4">
                        <span className="top-nav-label" >
                            <span style={{ cursor: 'pointer' }} onClick={handleTMSCancel}>
                                3PL
                            </span>
                            <img
                                src="images/next-icon-grey.svg"
                                alt="next-icon-grey"
                                className="pb-1 mx-2"
                            />
                        </span>
                        {tmsId ? tmsName : 'New Logistic Partner'}
                    </h5>
                    <div className="p-4 primary-shadow primary-bg-color rounded-3">
                        <div className="pb-4 mb-2">
                            <h5 className="color-black-v1 font-22 font-bold m-0 d-flex align-items-center">
                                <a className="text-decoration-none custom-label back-btn">
                                    <img
                                        src="images/left-arrow.svg"
                                        alt="left-arrow"
                                        className="me-3 close-img"
                                        style={{ cursor: 'pointer' }} onClick={handleTMSCancel}
                                    />
                                </a>
                                {tmsId ? tmsName : 'New Logistic Partner'}
                            </h5>
                        </div>
                        <div className="row">
                            <h5 id="AccountInformation" className="font-18 font-semibold custom-label pb-2">
                                Account Information
                            </h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="CompanyName"
                                >
                                    Company Name
                                </label>
                                {!tmsId && <span className="required-color"> *</span>}
                                <input
                                    type="text"
                                    className="form-control custom-form-input primary-bg-color primary-border-color"
                                    id="CompanyName"
                                    maxLength={64}
                                    // PS_FP_1.40- disable the button
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    disabled={tmsId !== undefined}
                                    placeholder="Enter Company Name"
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            name: e.target.value.trimStart()
                                        }))
                                    }
                                    value={tmsConfiguration.name}
                                />
                                {tmsErrorDetails.name && (
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{tmsErrorDetails.name}</label>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="Username"
                                >
                                    Username
                                </label>
                                {!tmsId && <span className="required-color"> *</span>}
                                <input
                                    type="text"
                                    className="form-control custom-form-input primary-bg-color primary-border-color "
                                    id="Username"
                                    maxLength={64}
                                    // PS_FP_1.40- disable the button
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    disabled={tmsId !== undefined}
                                    placeholder="Enter Username"
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            users: {
                                                ...prevState.users,
                                                user_name: e.target.value.trimStart()
                                            }
                                        }))
                                    }
                                    value={tmsConfiguration.users.user_name}
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.users.user_name}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="EmailAddress"
                                >
                                    Business Email Address
                                </label>
                                {!tmsId && <span className="required-color"> *</span>}
                                <input
                                    type="text"
                                    className="form-control custom-form-input primary-bg-color primary-border-color custom-input"
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    id="EmailAddress"
                                    disabled={tmsId !== undefined}
                                    placeholder="Enter Business Email Address"
                                    maxLength={64}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            users: {
                                                ...prevState.users,
                                                email_address: e.target.value.trimStart()
                                            }
                                        }))
                                    }
                                    value={tmsConfiguration.users.email_address}
                                />
                                {tmsErrorDetails.users.email_address && (
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{tmsErrorDetails.users.email_address}</label>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="PhoneNumber"
                                >
                                    Phone Number
                                    <span className="required-color"> *</span>
                                </label>
                                <div className="input-group">
                                    <select
                                        className="form-select pe-3 custom-form-input custom-select-number primary-bg-color primary-border-color"
                                        aria-label="Default select example"
                                        value={tmsConfiguration.users.country_code}
                                        onChange={(e) =>
                                            setTMSConfiguration((prevState) => ({
                                                ...prevState,
                                                users: {
                                                    ...prevState.users,
                                                    country_code: (e.target.value)
                                                }
                                            }))
                                        }                  >
                                        {/* PS_FP_1.11-set the US values to the phoneExt */}
                                        {all.filter(country => country.country === 'United States').map((country, index) => (
                                            <option key={index} value={country.countryCodes}>
                                                {country.countryCodes}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="text"
                                        className="form-control custom-form-input primary-bg-color primary-border-color"
                                        id="PhoneNumber"
                                        placeholder="Enter Phone Number"
                                        maxLength={15}
                                        onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                        onChange={(e) =>
                                            setTMSConfiguration((prevState) => ({
                                                ...prevState,
                                                users: {
                                                    ...prevState.users,
                                                    phone_number: e.target.value.trimStart()
                                                }
                                            }))
                                        }
                                        value={tmsConfiguration.users.phone_number}
                                    />
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.users.phone_number}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="Status"
                                >
                                    Status
                                </label>
                                <div className="d-flex align-items-center gap-4 py-2">
                                    {tmsStatuses.filter(status => status === CompanyStatus.ACTIVE || status === CompanyStatus.INACTIVE)
                                        .map(status => (
                                            <div key={status} className="form-check d-flex align-items-center gap-2 p-0">
                                                <input
                                                    className="form-check-input primary-bg-color m-0 form-checked primary-border-color"
                                                    type="radio"
                                                    style={{ cursor: 'pointer' }}
                                                    name="StatuRadio"
                                                    id={`${status}`}
                                                    defaultChecked={status === CompanyStatus.ACTIVE}
                                                    checked={tmsConfiguration.status === status}

                                                    onChange={(e) =>
                                                        setTMSConfiguration((prevState) => ({
                                                            ...prevState,
                                                            status: status,
                                                        }))
                                                    }
                                                    value={tmsConfiguration.status}
                                                // checked={organization3plDetails.accountInformation.companyStatus.statusId === status.id}
                                                />
                                                <label
                                                    className="form-check-label font-regular form-text-color font-14"
                                                    htmlFor={`${status}`}
                                                >
                                                    {CompanyStatusDisplaytext[status] || status}
                                                </label>

                                            </div>
                                        ))}

                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.status}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <h5 id="APIKeyValidity" className="font-18 font-semibold custom-label pb-2">
                                API Key Validity
                            </h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="ExpiresOn"
                                >
                                    Expires On
                                    {tmsConfiguration.status !== CompanyStatus.INACTIVE && <span className="required-color"> *</span>}
                                </label>
                                <div className="position-relative">
                                    <input
                                        type="date"
                                        className="form-control custom-form-input primary-bg-color custom-form  primary-border-color font-regular py-2 helper-text"
                                        id="ExpiresOn"
                                        max="9999-12-31"
                                        placeholder="Enter New Password"
                                        onChange={(e) =>
                                            setTMSConfiguration((prevState) => ({
                                                ...prevState,
                                                api_validity_utc: moment(e.target.value).utc().format('YYYY-MM-DDTHH:mm:ssZ')
                                            }))
                                        }
                                        value={tmsConfiguration.api_validity_utc ? moment(tmsConfiguration.api_validity_utc).format('YYYY-MM-DD') : ''}
                                    />
                                    <img className="position-absolute custom-input-icon cursor-pointer" src="/images/calander-icon.svg" alt="calander-icon"></img>

                                    {/* <img
                                        className="position-absolute custom-input-icon"
                                        src="images/calander-icon.svg"
                                        alt="calander-icon"
                                    /> */}
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.api_validity_utc}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="Status"
                                >
                                    Access Token Type
                                </label>
                                <div className="d-flex align-items-center gap-4 py-2">
                                    <div className="form-check d-flex align-items-center gap-2 p-0">
                                        <input
                                            className="form-check-input primary-bg-color m-0 form-checked primary-border-color cursor-pointer"
                                            type="radio"
                                            name="StatusRadio"
                                            id="Expired"
                                            checked={tmsConfiguration.access_token_type === TokenType.EXPIRED}
                                            defaultChecked={tmsConfiguration.access_token_type === TokenType.EXPIRED}
                                            disabled={tmsConfiguration.status === CompanyStatus.INACTIVE}
                                            onClick={() => {
                                                if (tmsConfiguration.access_token_type !== TokenType.EXPIRED) {
                                                    setConfirmationPopup(true);
                                                    setTMSConfiguration((prevState) => ({
                                                        ...prevState,
                                                        access_token_type: TokenType.EXPIRED
                                                    }))
                                                }
                                            }}
                                        />
                                        <label className="form-check-label font-regular form-text-color font-14 cursor-pointer" htmlFor="Expired">
                                            Expiring
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center gap-2 p-0">
                                        <input
                                            className="form-check-input primary-bg-color m-0 form-checked primary-border-color cursor-pointer"
                                            type="radio"
                                            name="StatusRadio"
                                            id="Non-Expired"
                                            checked={tmsConfiguration.access_token_type === TokenType.NONEXPIRED}
                                            disabled={tmsConfiguration.status === CompanyStatus.INACTIVE}
                                            onClick={() => {
                                                if (tmsConfiguration.access_token_type !== TokenType.NONEXPIRED) {
                                                    setConfirmationPopup(true);
                                                    setTMSConfiguration((prevState) => ({
                                                        ...prevState,
                                                        access_token_type: TokenType.NONEXPIRED
                                                    }))
                                                }
                                            }}
                                        />
                                        <label className="form-check-label font-regular form-text-color font-14 cursor-pointer" htmlFor="Non-Expired">
                                            Non-Expiring
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row mb-3">
                            <h5 id='Configuration' className="font-18 font-semibold custom-label pb-2">
                                Configuration
                            </h5>

                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label className="font-semibold form-text-color font-14 pb-1" htmlFor="PreferredVendor">
                                    Preferred Tracking Provider <span className="required-color"> *</span>
                                </label>
                                <select style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={tmsConfiguration.system_configuration.preferred_vendor}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            system_configuration: {
                                                ...prevState.system_configuration,
                                                preferred_vendor: e.target.value || '', // Parse and set to 0 if parsing fails
                                            },
                                        }))
                                    }
                                >
                                    <option >Select</option>
                                    {visibilityProviders.filter(provider => provider !==
                                        'TextLocate'
                                    ).map(provider => (<option key={provider} value={provider}>
                                        {provider}
                                    </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.preferred_vendor}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="TrackingMethod"
                                >
                                    Tracking Method
                                </label>
                                <div className="d-flex align-items-center gap-4 py-2">
                                    {trackingMethods.map(method => (
                                        <div key={`${method}`} className="form-check d-flex align-items-center gap-2 p-0">
                                            <input
                                                className="form-check-input primary-bg-color m-0 form-checked primary-border-color"
                                                type="radio"
                                                style={{ cursor: 'pointer' }}
                                                name="TrackingRadio"
                                                id={`${method}`}
                                                checked={tmsConfiguration.system_configuration.preferred_tracking_method === method}
                                                defaultChecked={method === 'TRUCK'}
                                                onChange={() =>
                                                    setTMSConfiguration((prevState) => ({
                                                        ...prevState,
                                                        system_configuration: {
                                                            ...prevState.system_configuration,
                                                            preferred_tracking_method: method, // Set tracking method ID to the selected method's ID
                                                            // Other configuration properties remain unchanged
                                                        },
                                                    }))
                                                }
                                            />
                                            <label
                                                className="form-check-label   font-regular form-text-color font-14"
                                                htmlFor={`${method}`}
                                            >
                                                {TrackingMethodDisplaytext[method] || method}

                                            </label>

                                        </div>
                                    ))}
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.preferred_tracking_method}</label>

                                </div>
                            </div>
                            {/* PS_FP_1.9-set the communicatinoPlatform as defautl */}
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="CommunicationPlatform"
                                >
                                    Communication Platform <span className="required-color"> *</span>
                                </label>
                                <div className="py-2">
                                    {/* <div class="d-flex align-items-center gap-4 py-2 pe-4"> */}
                                    <div>
                                        {communicationPlatforms.map((platform: CommunicationPlatform) => (
                                            <div
                                                key={platform}
                                                className="form-check form-check-inline me-3 me-md-4 align-middle"
                                                {...(platform !== 'EMAIL' && {
                                                    "data-bs-toggle": "tooltip",
                                                    "data-bs-placement": "top",
                                                    title: "Coming Soon",
                                                    "data-bs-original-title": "",
                                                    "aria-label": "Coming Soon"
                                                })}
                                            >
                                                <input
                                                    style={{ cursor: 'pointer' }}
                                                    className="form-check-input primary-bg-color form-checkbox primary-border-color"
                                                    type="checkbox"
                                                    id={`${platform}`}
                                                    defaultChecked={platform == 'EMAIL'}// Adjust defaultChecked as per your requirement
                                                    // defaultChecked={platform.communicationPlatform =='Email'?true:false}
                                                    disabled={platform !== 'EMAIL'}
                                                    onChange={(e: any) =>
                                                        setTMSConfiguration((prevState) => ({
                                                            ...prevState,
                                                            system_configuration: {
                                                                ...prevState.system_configuration,
                                                                preferred_communication_platform: e.target.checked ? platform : '', // Set to empty string if unchecked
                                                                // Other configuration properties remain unchanged
                                                            },
                                                        }))
                                                    }
                                                    value={tmsConfiguration.system_configuration.preferred_communication_platform}

                                                />
                                                <label
                                                    className="form-check-label font-regular form-text-color font-14"
                                                    htmlFor={`${platform}`}
                                                >
                                                    {CommunicationPlatformDisplaytext[platform] || platform}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.preferred_communication_platform}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <h5 id="Authentication" className="font-18 font-semibold custom-label pb-2">
                                Authentication
                            </h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="PreferredVendor"
                                >
                                    Authentication Method <span className="required-color"> *</span>
                                </label>
                                <select
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    style={{ cursor: 'pointer' }}
                                    value={tmsConfiguration.webhook_configuration.auth_type}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState: any) => {
                                            const selectedMethodId = e.target.value || '';
                                            const selectedMethod = authenticationMethods.find(method => method === selectedMethodId);
                                            const authentication = selectedMethod ? selectedMethod : ''; // Default to empty string if method not found

                                            return {
                                                ...prevState,
                                                webhook_configuration: {
                                                    ...prevState.webhook_configuration,
                                                    auth_type: authentication,
                                                },
                                            };
                                        })
                                    }
                                >
                                    {authenticationMethods.map((method) => (
                                        <option key={method} value={method}>
                                            {AuthenticationTypeDisplaytext[method] || method}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.auth_type}</label>
                                </div>
                            </div>
                            {/* PS_FP_1.10-authenticationMethod ternary check */}
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label className="font-semibold form-text-color font-14 pb-1" htmlFor="Username">
                                    {tmsConfiguration.webhook_configuration.auth_type == AuthenticationMethod.OAUTH_AUTHENTICATION ? "Client ID" : "Username"}<span className="required-color"> *</span>
                                </label>

                                <input
                                    type="text"
                                    className="form-control custom-form-input primary-bg-color primary-border-color"
                                    id="identi"
                                    placeholder={tmsConfiguration.webhook_configuration.auth_type == AuthenticationMethod.OAUTH_AUTHENTICATION ? "Enter Client ID" : "Enter Username"}
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    name="clientId"
                                    onChange={(e) => {
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            webhook_configuration: {
                                                ...prevState.webhook_configuration,
                                                identifier: e.target.value.trimStart(), // Set to empty string if unchecked
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }}
                                    value={tmsConfiguration.webhook_configuration.identifier}
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.identifier}</label>
                                </div>
                            </div><div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    htmlFor="password"
                                    className="form-label font-semibold form-text-color font-14 mb-1"
                                >
                                    {tmsConfiguration.webhook_configuration.auth_type == AuthenticationMethod.OAUTH_AUTHENTICATION ? "Client Secret" : "Password"} <span className="required-color"> *</span>
                                </label>
                                <div className="position-relative">
                                    <input
                                        type={showSecret ? 'text' : 'password'} className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                        id="clientSecret"
                                        onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                        placeholder={tmsConfiguration.webhook_configuration.auth_type == AuthenticationMethod.OAUTH_AUTHENTICATION ? "Enter Client Secret" : "Enter Password"}
                                        name="clientSecret"
                                        onChange={(e) => {
                                            setTMSConfiguration((prevState) => ({
                                                ...prevState,
                                                webhook_configuration: {
                                                    ...prevState.webhook_configuration,
                                                    secret: e.target.value.trimStart(), // Set to empty string if unchecked
                                                    // Other configuration properties remain unchanged
                                                },
                                            }))
                                        }}
                                        value={tmsConfiguration.webhook_configuration.secret}
                                    />
                                    {!showSecret ? (<span style={{ cursor: 'pointer' }} className="position-absolute custom-input-eye-postion custom-input-eye-color eye-close" onClick={() => { setShowSecret(true); }} />
                                    ) : (<span style={{ cursor: 'pointer' }} className="position-absolute eye-open-icon custom-input-eye-color eye-open" onClick={() => { setShowSecret(false); }} />)}
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.secret}</label>
                                </div>
                            </div>
                            {tmsConfiguration.webhook_configuration.auth_type === authenticationMethods.find(authenticationMethod => authenticationMethod === 'OAUTH') ? (
                                <>
                                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                        <label
                                            className="font-semibold form-text-color font-14 pb-1"
                                            htmlFor="TokenEndpointUrl"
                                        >
                                            Token Endpoint URL
                                        </label>

                                        <img
                                            className="ms-2 tooltip-img"
                                            src="images/tooltip-icon.svg"
                                            alt="tooltip-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-original-title="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                            aria-label="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                        />
                                        <span className="required-color"> *</span>
                                        <input
                                            type="url"
                                            className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                            id="EventUpdateURL"
                                            onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                            placeholder="Enter Event Update URL"
                                            value={tmsConfiguration.webhook_configuration.token_endpoint}
                                            onChange={(e) =>
                                                setTMSConfiguration((prevState) => ({
                                                    ...prevState,
                                                    webhook_configuration: {
                                                        ...prevState.webhook_configuration,
                                                        token_endpoint: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                        // Other configuration properties remain unchanged
                                                    },
                                                }))
                                            }
                                        />
                                        <div className="pt-1">
                                            <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.token_endpoint}</label>
                                        </div>
                                    </div>
                                </>
                            ) : null}


                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="EventUpdateURL"
                                >
                                    Event Update URL
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip" // Enable Bootstrap tooltip
                                    data-bs-placement="top" // Set tooltip placement
                                    title="Specify the Webhook URL where event updates on loads should be sent." // Set tooltip message
                                    aria-label="Tooltip icon"
                                    aria-describedby="tooltip89429" // Optional: Provide an ID if you want to associate it with a specific element
                                />
                                <span className="required-color"> *</span>
                                <input
                                    type="url"
                                    className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                    id="EventUpdateURL"
                                    placeholder="Enter Event Update URL"
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    value={tmsConfiguration.webhook_configuration.load_event_update_url}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            webhook_configuration: {
                                                ...prevState.webhook_configuration,
                                                load_event_update_url: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.load_event_update_url}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="EventUpdateURL"
                                >
                                    Message Update URL
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the Webhook URL where message updates from the driver should be sent."
                                    aria-label="Specify the Webhook URL where message updates from the driver should be sent."
                                />
                                <span className="required-color"> *</span>
                                <input
                                    type="url"
                                    className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                    id="EventUpdateURL"
                                    placeholder="Enter Event Update URL"
                                    value={tmsConfiguration.webhook_configuration.driver_message_update_url}
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            webhook_configuration: {
                                                ...prevState.webhook_configuration,
                                                driver_message_update_url: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.driver_message_update_url}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="ConnectionTestURL"
                                >
                                    Connection Test URL
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the URL that the system will access to perform a connection test or health check."
                                    aria-label="Specify the URL that the system will access to perform a connection test or health check."
                                />
                                <span className="required-color"> *</span>
                                <input
                                    type="url"
                                    className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                    id="ConnectionTestURL"
                                    placeholder="Enter Connection Test URL"
                                    onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                    value={tmsConfiguration.webhook_configuration.test_connection_url}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            webhook_configuration: {
                                                ...prevState.webhook_configuration,
                                                test_connection_url: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }
                                    onBlur={() => handleEndpointBlur(tmsConfiguration.webhook_configuration.test_connection_url)}
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.webhook_configuration.test_connection_url}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <h5 id="TrackingTimelineEscalation" className="font-18 font-semibold custom-label pb-4 m-0">
                                Tracking Timeline Escalation
                            </h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="NotificationDetail"
                                >
                                    Notification Detail Hrs
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the number of hours before pick-up to notify if tracking details are unavailable."
                                    aria-label="Specify the number of hours before pick-up to notify if tracking details are unavailable."
                                />
                                <span className="required-color"> *</span>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={tmsConfiguration.system_configuration.tracking_event_notify_hours}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            system_configuration: {
                                                ...prevState.system_configuration,
                                                tracking_event_notify_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {notificationDetailHrs.map((hour) => (
                                        <option value={`${hour}`}>
                                            {hour}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.tracking_event_notify_hours}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="SecondTierTracking"
                                >
                                    Second Tier Tracking Provider Hrs
                                    <img
                                        className="ms-2 tooltip-img"
                                        src="images/tooltip-icon.svg"
                                        alt="tooltip-icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                        aria-label="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                    />{" "}
                                    <span className="required-color"> *</span>
                                </label>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={tmsConfiguration.system_configuration.second_tier_notify_hours}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            system_configuration: {
                                                ...prevState.system_configuration,
                                                second_tier_notify_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            }
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {secondTierTrackingProviderHrs.map((hour, index) => (
                                        <option key={index} value={`${hour}`}>
                                            {hour}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.second_tier_notify_hours}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="SupportEscalation"
                                >
                                    Support Escalation Hrs
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the number of hours after
                              the pickup time to escalate to support staff if updates from tracking providers are not received."
                                    aria-label="Specify the number of hours after
                              the pickup time to escalate to support staff if updates from tracking providers are not received."
                                />
                                <span className="required-color"> *</span>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={tmsConfiguration.system_configuration.support_escalation_hours}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            system_configuration: {
                                                ...prevState.system_configuration,
                                                support_escalation_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            }
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {supportEscalationHrs.map((hour, index) => (
                                        <option key={index} value={`${hour}`}>
                                            {hour}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.support_escalation_hours}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-2"
                                    htmlFor="TextLocate"
                                >
                                    Text Locate Quiet Hrs
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the quiet hours for drivers by defining the start and end times during which bulk messages won't be sent."
                                    aria-label="Specify the quiet hours for drivers by defining the start and end times during which bulk messages won't be sent."
                                />
                                <span className="required-color"> *</span>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-4 pe-4">
                                        <label
                                            className="font-semibold form-text-color font-14 pb-1"
                                            htmlFor="StartTime"
                                        >
                                            Start Time
                                        </label>
                                        <div className="position-relative cust-time-picker">
                                            <ReactDatePicker
                                                selected={parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_start_time)}
                                                onChange={(date: Date) => onTimeChange('location_provider_quiet_hrs_start_time', date)}
                                                // maxTime={organization3plDetails.configuration.textLocateEndTimeId ? new Date(organization3plDetails.configuration.textLocateEndTimeId) : undefined} // Set maxTime dynamically
                                                minTime={getStartOfDay()}  // Always have a start of day time
                                                maxTime={parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_end_time) ?? getEndOfDay()}  // Conditional start time or start of day
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                                id="StartTime"
                                                timeFormat="HH:mm"
                                                placeholderText="--:--"
                                                className='form-control custom-form-input primary-bg-color primary-border-color'
                                            />


                                            {/* <input
                                                type="time"
                                                className="form-control custom-form-input primary-bg-color custom-form primary-border-color font-regular py-2 helper-text"
                                                id="StartTime"
                                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                                name='textLocateStartTimeId'
                                                value={organization3plDetails.configuration.textLocateStartTimeId}
                                                onChange={(e) => handleDateChange(e)}
                                            /> */}
                                            <label htmlFor="StartTime">

                                                <img
                                                    className="position-absolute custom-input-icon"
                                                    src="images/clock-icon.svg"
                                                    alt="clock-icon"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </label>
                                        </div>
                                        <div className="pt-1">
                                            <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.location_provider_quiet_hrs_start_time}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-4 pe-4">
                                        <label
                                            className="font-semibold form-text-color font-14 pb-1"
                                            htmlFor="EndTime"
                                        >
                                            End Time
                                        </label>
                                        <div className="position-relative cust-time-picker">

                                            <ReactDatePicker
                                                selected={parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_end_time)}
                                                onChange={(date: Date) => onTimeChange('location_provider_quiet_hrs_end_time', date)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                minTime={parseTimeString(tmsConfiguration.system_configuration.location_provider_quiet_hrs_end_time) ?? getStartOfDay()}
                                                maxTime={getEndOfDay()}  // Always have an end of day time
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                                id="EndTime"
                                                timeFormat="HH:mm"
                                                placeholderText="--:--"
                                                className='form-control custom-form-input primary-bg-color primary-border-color'
                                            />

                                            {/* <input
                                                type="time"
                                                name="textLocateEndTimeId"
                                                className="form-control custom-form-input primary-bg-color custom-form  primary-border-color font-regular py-2 helper-text"
                                                id="EndTime"
                                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                                value={organization3plDetails.configuration.textLocateEndTimeId}
                                                onChange={(e) => handleDateChange(e)}
                                            /> */}
                                            <label htmlFor="EndTime">
                                                <img
                                                    className="position-absolute custom-input-icon"
                                                    src="images/clock-icon.svg"
                                                    alt="clock-icon"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </label>
                                            <div className="pt-1">
                                                <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.location_provider_quiet_hrs_end_time}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <h5 id="TrackingDisconnectionEscalation" className="font-18 font-semibold custom-label">
                                Tracking Disconnection Escalation
                            </h5>
                            <p className="font-regular font-14 helper-text">
                                Specify the interval for escalating the source when tracking is
                                disconnected
                            </p>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="ELDHrs"
                                >
                                    ELD Hrs <span className="required-color"> *</span>
                                </label>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={tmsConfiguration.system_configuration.eld_check_interval_hours}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            system_configuration: {
                                                ...prevState.system_configuration,
                                                eld_check_interval_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            }
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {eldDisconnectionHrs.map((hour, index) => (
                                        <option key={index} value={`${hour}`}>
                                            {hour}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.eld_check_interval_hours}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="  font-semibold form-text-color font-14 pb-1"
                                    htmlFor="DriverApplicationHrs"
                                >
                                    Driver Application Hrs <span className="required-color"> *</span>
                                </label>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={tmsConfiguration.system_configuration.driver_app_check_interval_hours}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            system_configuration: {
                                                ...prevState.system_configuration,
                                                driver_app_check_interval_hours: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            }
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {driverApplicationHrs.map((hour, index) => (
                                        <option key={index} value={`${hour}`}>
                                            {hour}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.driver_app_check_interval_hours}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="  font-semibold form-text-color font-14 pb-1"
                                    htmlFor="LocationProviderHrs"
                                >
                                    Location Provider Hrs <span className="required-color"> *</span>
                                </label>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={tmsConfiguration.system_configuration.location_provider_check_interval}
                                    onChange={(e) =>
                                        setTMSConfiguration((prevState) => ({
                                            ...prevState,
                                            system_configuration: {
                                                ...prevState.system_configuration,
                                                location_provider_check_interval: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            }
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {locationProviderDisconnectHrs.map((hour, index) => (
                                        <option key={index} value={`${hour}`}>
                                            {hour}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{tmsErrorDetails.system_configuration.location_provider_check_interval}</label>
                                </div>
                            </div>

                        </div>
                        <div className="text-end mb-3 pe-2">
                            <button type="button" className="btn text-btn font-semibold me-3" onClick={handleTMSCancel}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn primary-btn font-semibold px-3"
                                onClick={handleTMSSubmit}
                                onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default TMSCreation;

