import { StopLocations } from "../interface/LoadInterface";
export const getLatLng = (address: string, city: string, state: string, country: string): Promise<{ latitude: number; longitude: number }> => {
    return new Promise((resolve, reject) => {
      //PS_SP_1.10
      const geocoder = new google.maps.Geocoder();
      //PS_SP_1.11
      geocoder.geocode({ address: `${address},${city},${state},${country}` }, (results, status) => {
        //PS_SP_1.12
        if (status === 'OK' && results) {
          //PS_SP_1.13
          const location = results[0]?.geometry?.location;
          resolve({ latitude: location.lat(), longitude: location.lng() });
        } else {
          console.error("Geocode error:", status, results);
          //PS_SP_1.14-PS_SP_1.15
          reject(`Could not find coordinates for ${address}, ${city}, ${state}, ${country}`);
        }
      });
    });
};

export const getDistances = async (stopLocations: StopLocations[]): Promise<{ distance: string; duration: number }[]> => {
  try {
    //PS_SP_1.18
    const distanceMatrixService = new google.maps.DistanceMatrixService();
    //PS_SP_1.19
    const origin = stopLocations?.slice(0, -1)?.map((stop) => `${stop?.latitude},${stop?.longitude}`);
    //PS_SP_1.20
    const destination = stopLocations?.slice(1)?.map((stop) => `${stop?.latitude},${stop?.longitude}`);
    //PS_SP_1.21
    const request = {
      origins: origin,
      destinations: destination,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      avoidHighways: false,
      avoidTolls: false,
    };
    //PS_SP_1.22-PS_SP_1.24
    const response = await new Promise<{ distance: string; duration: number }[]>((resolve, reject) => {
      distanceMatrixService.getDistanceMatrix(request, (response: any, status: string) => {
        if (status === 'OK') {
          const distances = response.rows.map((row: any, index: number) => ({
            distance: row?.elements[index]?.distance?.text,
            duration: row?.elements[index]?.duration?.value,
          }));
          resolve(distances);
        } else {
          reject(`Error: ${status}`);
        }
      });
    });
    return response;
  } catch (error) {
    console.error('Error calculating distances:', error);
    return []
  }
};
