function NoDataFound() {
    return (
        <tr>
            <td colSpan={8} >
                <div className="d-flex w-100 justify-content-center">
                    <div className="text-center my-5 py-5">
                        <span className="no-records-found"></span>
                        <h5 className="font-20 font-semibold mt-2 color-black-v1">No Data Found</h5>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default NoDataFound;