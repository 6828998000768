import all from 'country-calling-code';
import { useEffect, useState, } from 'react';
import { AuthenticationMethod, RoleEnum, TokenType } from '../../enums/enums';
import { TMSConfiguration, TMSErrorConfiguration } from '../../interface/Interface';
import { retrieveTMSDetails, updateTMSDetails } from '../../service/Api';
import Loader from '../Resuables/Loader';
import { SomethingWentWrong } from '../Resuables/SomethingWentWrong';
import { useNavigate } from 'react-router-dom'
import ConfirmationPopUp from '../Resuables/ConfirmationPopUp';
import { decryptData, encryptData, initializeTooltips, parseToken } from '../../utils/utils';

const TMSAccountInformation: React.FC<{ userRole: string, tmsId: string, openToast: any }> = ({ userRole, tmsId, openToast }) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(false)
    const [exception, setException] = useState<boolean>(false)
    const [confirmationPopup, setConfirmationPopup] = useState<boolean>(false);
    const sectionFieldMap = {
        'AccountInformation': ['name', 'users[0].user_name', 'users[0].email_address', 'users[0].phone_number', 'status'],
        'APIKeyValidity': ['api_validity_utc'],
        'Configuration': ['system_configuration.preferred_vendor', 'system_configuration.preferred_tracking_method', 'system_configuration.preferred_communication_platform'],
        'Authentication': ['webhook_configuration.auth_type', 'webhook_configuration.load_event_update_url', 'webhook_configuration.driver_message_update_url', 'webhook_configuration.test_connection_url'],
        'TrackingTimelineEscalation': ['system_configuration.tracking_event_notify_hours', 'system_configuration.second_tier_notify_hours', 'system_configuration.support_escalation_hours', 'system_configuration.location_provider_quiet_hrs_start_time', 'system_configuration.location_provider_quiet_hrs_end_time'],
        'TrackingDisconnectionEscalation': ['system_configuration.eld_check_interval_hours', 'system_configuration.driver_app_check_interval_hours', 'system_configuration.location_provider_check_interval']
    };
    const defaultTMSConfiguration: TMSConfiguration = {
        tms_id: parseInt(tmsId),
        name: '',
        status: '',
        connection_status:'',
        access_token_type: '',
        token: '',
        api_validity_utc: '',
        api_client_id: '',
        api_client_secret: '',
        system_configuration: {
            preferred_vendor: '',
            preferred_tracking_method: '',
            preferred_communication_platform: '',
            tracking_event_notify_hours: 0,
            second_tier_notify_hours: 0,
            support_escalation_hours: 0,
            eld_check_interval_hours: 0,
            driver_app_check_interval_hours: 0,
            location_provider_check_interval: 0,
            location_provider_quiet_hrs_start_time: '',
            location_provider_quiet_hrs_end_time: '',
        },
        webhook_configuration: {
            auth_type: '',
            identifier: '',
            secret: '',
            token: '',
            refresh_token: '',
            token_expiry: '',
            token_endpoint: '',
            load_event_update_url: '',
            driver_message_update_url: '',
            test_connection_url: '',
        },
        users:
        {
            user_name: '',
            user_type: '',
            email_address: '',
            phone_number: '',
            country_code: '1'
        },        
        delay_reasons: [],


    };
    const defaultTMSErrorConfiguration: TMSErrorConfiguration = {
        name: '',
        status: '',
        access_token_type: '',
        api_validity_utc: '',
        system_configuration: {
            preferred_vendor: '',
            preferred_tracking_method: '',
            preferred_communication_platform: '',
            tracking_event_notify_hours: '',
            second_tier_notify_hours: '',
            support_escalation_hours: '',
            eld_check_interval_hours: '',
            driver_app_check_interval_hours: '',
            location_provider_check_interval: '',
            location_provider_quiet_hrs_start_time: '',
            location_provider_quiet_hrs_end_time: '',
        },
        webhook_configuration: {
            auth_type: '',
            identifier: '',
            secret: '',
            token: '',
            refresh_token: '',
            token_expiry: '',
            token_endpoint: '',
            load_event_update_url: '',
            driver_message_update_url: '',
            test_connection_url: '',
        },
        users:
        {
            user_name: '',
            user_type: '',
            email_address: '',
            phone_number: '',
            country_code: ''
        }
        ,
        delay_reasons: {
            error_code: '',
            reason: ''
        }
    };
    const [tmsConfiguration, setTMSConfiguration] = useState<TMSConfiguration>(defaultTMSConfiguration);
    let [tmsErrorDetails, setTmsErrorDetails] = useState<TMSErrorConfiguration>(defaultTMSErrorConfiguration)
    const [showSecret, setShowSecret] = useState<boolean>(false);
    const impersonate = localStorage.getItem('isImpersonate');
    const token = localStorage.getItem('accessToken');
    const payload = parseToken(token || "");
    useEffect(() => {

        getUserOrganizationData(tmsId); // Call getUserinfo without userId

    }, []);

    const handleTMSInputChange = () => {
        tmsErrorDetails = defaultTMSErrorConfiguration;

        let tmsUserErrorConfiguration = tmsErrorDetails.users;
        let tmsUserConfiguration = tmsConfiguration.users;
        if (!tmsUserConfiguration.phone_number) {
            tmsUserErrorConfiguration.phone_number = 'Enter a value'
        } else if (tmsUserConfiguration.phone_number.length < 8) {
            tmsUserErrorConfiguration.phone_number = 'Phone number must be at least 8 characters long'
        }
        setTmsErrorDetails({
            ...tmsErrorDetails,
            users: tmsUserErrorConfiguration
        });
        // PS_FP_1.24-PS_FP_1.25
    };

    const getUserOrganizationData = async (tmsId?: string | undefined) => {
        try {
            setLoader(true)
            const id = tmsId ? tmsId : ""
            const response: any = await retrieveTMSDetails(id)
            if (response.data.code == 200) {
                const fetchedTMSConfig: TMSConfiguration = response.data.data;
                setTMSConfiguration({
                    ...fetchedTMSConfig
                })
            }
            else {
                setException(true)
            }
            setLoader(false)
            initializeTooltips()
        }
        catch (error) {
            console.log("'The retrieve error", error)
            setException(true)
            setLoader(false)
        }
    }
    const findFirstErrorSection = () => {
        for (const [sectionId, fields] of Object.entries(sectionFieldMap)) {
            if (fields.some(field => tmsErrorDetails[field as keyof TMSErrorConfiguration] !== '')) {
                return sectionId;
            }
        }
        return null;
    };
    const hasAnyNonEmptyString = (obj: any): boolean => {
        if (typeof obj === 'string') {
            return obj !== '';
        }
        if (Array.isArray(obj)) {
            return obj.some(item => hasAnyNonEmptyString(item));
        }
        if (typeof obj === 'object' && obj !== null) {
            return Object.values(obj).some(value => hasAnyNonEmptyString(value));
        }
        return false;
    };
    const handleTMSSubmit = async () => {
        handleTMSInputChange()
        const hasAnyError = hasAnyNonEmptyString(tmsErrorDetails);
        if (hasAnyError) {
            const firstErrorSection = findFirstErrorSection();
            if (firstErrorSection) {
                const element = document.getElementById(firstErrorSection);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
            return;
        }
        const { api_client_id, api_client_secret, ...filteredTMSConfiguration } = tmsConfiguration;
        const OrganizationPayload = { ...filteredTMSConfiguration };
                try {
            setLoader(true)
            let userRole:string =''
            let isImpersonate:boolean = false
            if (impersonate  == 'true' && payload.userRole == RoleEnum.ETM_ADMIN){
                userRole = RoleEnum.ETM_ADMIN
                isImpersonate= true
            }
            else if(!impersonate && payload.userRole == RoleEnum.ETM_ADMIN || payload.userRole == RoleEnum.ETM_STAFF ) {
                userRole = RoleEnum.ETM_ADMIN
                isImpersonate= false
            }
            else if(payload.userRole == RoleEnum.THIRD_PARTY_ADMIN ||payload.userRole == RoleEnum.THIRD_PARTY_STAFF  ) {
                userRole = RoleEnum.THIRD_PARTY_ADMIN
                isImpersonate= false
            }
            const response: any = await updateTMSDetails(isImpersonate,userRole,OrganizationPayload);
            // PS_FP_1.29-PS_FP_1.33 checkfor the response
            if (response.data.code == 200) {
                await getUserOrganizationData(tmsId ? tmsId : "0")
                openToast("Profile information updated successfully.")
            }
            else {
                setException(true)
            }
            setLoader(false)

            // Check response and navigate or display appropriate error/warning
        } catch (error) {
            setException(true)
            console.log("the error logging", error)
            setLoader(false)
        }
    };

    const handleTMSCancel = () => {
        setTMSConfiguration(defaultTMSConfiguration);
        setTmsErrorDetails(defaultTMSErrorConfiguration);
        navigate(-1);
    };

    const handleCopyToClipboard = () => {
        // Create a temporary textarea element
        const textArea = document.createElement("textarea");
        textArea.value = tmsConfiguration.token || "";

        // Append the textarea to the document
        document.body.appendChild(textArea);

        // Select the text
        textArea.select();
        textArea.setSelectionRange(0, 99999); // For mobile devices

        // Execute the copy command
        document.execCommand("copy");

        // Remove the textarea from the document
        document.body.removeChild(textArea);

    };


    return (
        <>
            {
                loader && <Loader />
            }
            {
                exception && <SomethingWentWrong onClose={() => setException(false)} />
            }
            {confirmationPopup && (
                <ConfirmationPopUp
                    heading="Alert"
                    content={
                        tmsConfiguration.access_token_type === TokenType.EXPIRED
                            ? "Changing the access token preference will immediately EXPIRE the non-expiring token. Are you sure you want to proceed?"
                            : "Changing the access token preference will immediately EXPIRE the existing token. Are you sure you want to proceed?"
                    }
                    onClose={() => {
                        setTMSConfiguration((prevState) => ({
                            ...prevState,
                            access_token_type: tmsConfiguration.access_token_type == TokenType.EXPIRED ? TokenType.NONEXPIRED : TokenType.EXPIRED
                        }))
                        setConfirmationPopup(false)
                    }}
                    oKClick={() => {
                        if (tmsConfiguration.access_token_type) {
                            setConfirmationPopup(false);
                        }
                    }}
                />
            )}

            <div
                className="tab-pane fade active show"
                id="accountinfo"
                role="tabpanel"
                aria-labelledby="accountinfo-tab"
            >
                <div className="row">
                    <h5 className="font-18 font-semibold pb-2">Account Information</h5>
                    {(userRole == RoleEnum.THIRD_PARTY_ADMIN || userRole == RoleEnum.THIRD_PARTY_STAFF) && <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            className="font-semibold form-text-color font-14 pb-1"
                            htmlFor="CompanyName"
                        >
                            Company Name
                        </label>

                        <input
                            type="text"
                            className="form-control custom-form-input primary-bg-color primary-border-color"
                            id="CompanyName"
                            disabled={true}
                            placeholder="Enter Company Name"
                            value={tmsConfiguration.name}
                        />

                    </div>
                    }
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            className="font-semibold form-text-color font-14 pb-1"
                            htmlFor="Username"
                        >
                            Username
                        </label>
                        <input
                            type="text"
                            className="form-control custom-form-input primary-bg-color primary-border-color "
                            id="Username"
                            disabled={true}
                            placeholder="Enter Username"
                            value={tmsConfiguration.users.user_name}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            className="font-semibold form-text-color font-14 pb-1"
                            htmlFor="EmailAddress"
                        >
                            Business Email Address
                        </label>
                        <input
                            type="text"
                            className="form-control custom-form-input primary-bg-color primary-border-color custom-input"
                            id="EmailAddress"
                            disabled={true}
                            placeholder="Enter Business Email Address"
                            value={tmsConfiguration.users.email_address}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                        <label
                            className="font-semibold form-text-color font-14 pb-1"
                            htmlFor="PhoneNumber"
                        >
                            Phone Number
                        </label>
                        <div className="input-group">
                            <select
                                className="form-select pe-3 custom-form-input custom-select-number primary-bg-color primary-border-color"
                                aria-label="Default select example"
                                value={tmsConfiguration.users.country_code}
                                onChange={(e) =>
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        users: {
                                            ...prevState.users,
                                            country_code: "1", // Parse and set to 0 if parsing fails
                                        }
                                    }))
                                }
                            >
                                {all.filter(country => country.country === 'United States').map((country, index) => (
                                    <option key={index} value={country.countryCodes}>
                                        {country.countryCodes}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                className="form-control custom-form-input primary-bg-color primary-border-color"
                                onKeyDown={e => { if (e.key === "Enter") handleTMSSubmit() }}
                                id="PhoneNumber"
                                maxLength={15}
                                placeholder="Enter Phone Number"
                                value={tmsConfiguration.users.phone_number}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (tmsConfiguration.users.phone_number === '' && inputValue.trim() === '') {
                                        return;
                                    }
                                    setTMSConfiguration((prevState) => ({
                                        ...prevState,
                                        users: {
                                            ...prevState.users,
                                            phone_number: inputValue.trim(),
                                            country_code: "1", // Parse and set to 0 if parsing fails
                                            // Parse and set to 0 if parsing fails
                                        }
                                    }))
                                }}

                            />
                        </div>
                        {tmsErrorDetails.users && <div className="pt-1">
                            <label className="required-color font-12 font-regular">{tmsErrorDetails.users.phone_number}</label>
                        </div>}
                    </div>
                </div>
                {(userRole == RoleEnum.THIRD_PARTY_ADMIN || userRole == RoleEnum.THIRD_PARTY_STAFF) &&
                    <>
                        <div className="row mb-3">
                            <h5 className="font-18 font-semibold pb-2">API Credentials</h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="Status"
                                >
                                    Access Token Type
                                </label>
                                <div className="d-flex align-items-center gap-4 py-2">
                                    <div className="form-check d-flex align-items-center gap-2 p-0">
                                        <input
                                            className="form-check-input primary-bg-color m-0 form-checked primary-border-color cursor-pointer"
                                            type="radio"
                                            name="StatusRadio"
                                            id="Expired"
                                            checked={tmsConfiguration.access_token_type === TokenType.EXPIRED}
                                            defaultChecked={tmsConfiguration.access_token_type === TokenType.EXPIRED}
                                            onClick={() => {
                                                if (tmsConfiguration.access_token_type !== TokenType.EXPIRED) {
                                                    setConfirmationPopup(true);
                                                    setTMSConfiguration((prevState) => ({
                                                        ...prevState,
                                                        access_token_type: TokenType.EXPIRED
                                                    }))
                                                }
                                            }}
                                        />
                                        <label className="form-check-label font-regular form-text-color font-14 cursor-pointer" htmlFor="Expired">
                                            Expiring
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center gap-2 p-0">
                                        <input
                                            className="form-check-input primary-bg-color m-0 form-checked primary-border-color cursor-pointer "
                                            type="radio"
                                            name="StatusRadio"
                                            id="Non-Expired"
                                            checked={tmsConfiguration.access_token_type === TokenType.NONEXPIRED}
                                            onClick={() => {
                                                if (tmsConfiguration.access_token_type !== TokenType.NONEXPIRED) {
                                                    setConfirmationPopup(true);
                                                    setTMSConfiguration((prevState) => ({
                                                        ...prevState,
                                                        access_token_type: TokenType.NONEXPIRED
                                                    }))
                                                }
                                            }}
                                        />
                                        <label className="form-check-label font-regular form-text-color font-14 cursor-pointer" htmlFor="Non-Expired">
                                            Non-Expiring
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="Username"
                                >
                                    Client ID
                                </label>
                                <input
                                    type="text"
                                    className="form-control custom-form-input primary-bg-color primary-border-color"
                                    id="Username"
                                    disabled={true}
                                    placeholder="Enter Username"
                                    value={tmsConfiguration?.api_client_id}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    htmlFor="password"
                                    className="form-label font-semibold form-text-color font-14 mb-1"
                                >
                                    Client Secret
                                </label>
                                <div className="position-relative">
                                    <input
                                        type={showSecret ? 'text' : 'password'} className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                        id="password"
                                        disabled={true}
                                        placeholder="Enter Password"
                                        value={tmsConfiguration?.api_client_secret}
                                    />
                                    {!showSecret ? (<span style={{ cursor: 'pointer' }} className="position-absolute custom-input-eye-postion custom-input-eye-color eye-close" onClick={() => { setShowSecret(true); }} />
                                    ) : (<span style={{ cursor: 'pointer' }} className="position-absolute eye-open-icon custom-input-eye-color eye-open" onClick={() => { setShowSecret(false); }} />)}
                                </div>
                            </div>
                            {tmsConfiguration.access_token_type == TokenType.NONEXPIRED && tmsConfiguration.token &&
                                <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                    <label
                                        className="font-semibold form-text-color font-14 pb-1"
                                        htmlFor="Username"
                                    >
                                        Access Token
                                    </label>
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control custom-form-input primary-bg-color primary-border-color py-2"
                                            id="token"
                                            disabled={true}
                                            placeholder="Access Token"
                                            value={tmsConfiguration.token.substring(0, 55)}
                                        />
                                        <span className="position-absolute custom-copy custom-input-eye-color cursor-pointer ">
                                            <img src="images/copy-img.svg" alt="copy"
                                                onClick={handleCopyToClipboard}
                                            />
                                        </span>
                                    </div>
                                </div>}

                        </div>
                        <div className="row mb-3">
                            <h5 className="font-18 font-semibold pb-2">API Validity</h5>
                            <p className="font-regular font-16">
                                Your API key expires on{" "}
                                <span className="font-18 font-semibold custom-label">
                                    {new Date(tmsConfiguration.api_validity_utc).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}.
                                </span>
                            </p>
                        </div>
                    </>
                }
                <div className="text-end mb-3 pe-2">
                    <button type="button" className="btn text-btn font-semibold me-3" onClick={handleTMSCancel}
                    >
                        Cancel

                    </button>
                    <button
                        type="button"
                        className="btn primary-btn font-semibold px-4"
                        onClick={handleTMSSubmit}
                    >
                        Save
                    </button>
                </div>
            </div>
        </>
    )
}

export default TMSAccountInformation;