import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ChildComponentProps } from "../../interface/Interface";
import { postLoginAPIs } from '../../utils/utils';
import { RoleEnum } from '../../enums/enums';
import { SomethingWentWrong } from '../Resuables/SomethingWentWrong';



export const SigninComponent: React.FC<ChildComponentProps> = ({ showLoader, hideLoader }) => {

    const [isSomethingWentWrong, setisSomethingWentWrong] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    //PC_SI_09-PC_SI_14 - Declaration and initialization of state variables 
    const [loginDetails, setLoginDetails] = useState({
        emailId: "",
        password: ""
    });
    const [loginError, setLoginError] = useState({
        errEmailId: "",
        errPassword: "",
        err: ""
    });
    //PC_SI_16-PC_SI_17 - Handle onchange values of fields
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value.trim() });
    }
    const navigate = useNavigate();
    const location = useLocation();

    let navigateTo: string;

    useEffect(() => {
        let expireTime = localStorage.getItem("accessTokenExpiration")
                if (expireTime) {
            CheckAuthRedirect();
            var CurrentTime = new Date();
            var currentexpireTime = new Date(expireTime);
            if (CurrentTime < currentexpireTime) {
                navigate(navigateTo)
            }
        }
    }, []);

    //PC_SI_23-PC_SI_30-Method to authenticate the user in cognito and invoke Post Login APIs
    const signIn = () => {

        //PC_SI_18-PC_SI_21-Method to validate the input fields  
        let isValid = validateFields();
        if (isValid) {
            showLoader();
            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
                Username: loginDetails.emailId.trim(),
                Password: loginDetails.password.trim(),
            });

            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
                ClientId: process.env.REACT_APP_CLIENT_ID || "",
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: loginDetails.emailId,
                Pool: userPool,
            });

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async function (result) {
                    var idToken = result.getIdToken().getJwtToken();
                    var accessToken = result.getAccessToken().getJwtToken();
                    //PC_REF_38- Store the refresh token
                    var refreshToken = result.getRefreshToken().getToken()

                    localStorage.setItem("accessToken", accessToken)
                    localStorage.setItem("refreshToken", refreshToken)
                    localStorage.setItem("idToken", idToken)
                    if (accessToken) {
                        let decodedToken = jwtDecode(accessToken) as { [key: string]: any };
                        // Get the username from the token
                        const username = decodedToken["username"];
                        const expirationTime = decodedToken["exp"]; // Extract the expiration time from the decoded token
                        var groups = decodedToken["cognito:groups"];
                        if (!groups){
                            setisSomethingWentWrong(true)
                            hideLoader()
                            return
                        }
                        if (username) {
                            localStorage.setItem("emailId", username)
                        }
                        if (expirationTime) {
                            const expirationDate = new Date(expirationTime * 1000); // Convert expiration time to milliseconds
                            localStorage.setItem("accessTokenExpiration", expirationDate.toISOString()); // Store expiration time as a date string in session storage
                        } else {
                            console.error("Access token expiration time not found in decoded token.");
                            // Handle the absence of expiration time, set a default value, or throw an error as appropriate
                        }
                        if (location.state && location.state.from && location.state.from.pathname) {
                            navigateTo = location.state.from.pathname;
                        }else if(groups[0] === RoleEnum.ETM_ADMIN){
                            navigateTo = "/tms-details"
                        }else{
                            navigateTo = "/loadmetrics"
                        }
                    }
                    await postLoginAPIs(groups)
                    hideLoader()
                    navigate(navigateTo)
                },
                onFailure: function (err) {
                    // Handle failure
                    hideLoader()
                    handleAuthFailure(err);
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    hideLoader()
                    navigate('/resetPassword', { state: { emailId: loginDetails.emailId, password: loginDetails.password } });
                    // Get these details and call
                }
            });
        }
    }



    //PC_SI_18-PC_SI_21-Method to validate the input fields  
    const validateFields = () => {
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!loginDetails.emailId.trim() || !emailRegex.test(loginDetails.emailId)) {
            setLoginError(prevState => ({
                ...prevState,
                errEmailId: "Please enter a valid email address",
            }));
            isValid = false;
        } else {
            setLoginError(prevState => ({
                ...prevState,
                errEmailId: "",
            }));
        }
        if (!loginDetails.password.trim()) {
            setLoginError(prevState => ({
                ...prevState,
                errPassword: "Please enter your password",
            }));
            isValid = false;
        } else {
            setLoginError(prevState => ({
                ...prevState,
                errPassword: "",
            }));
        }
        return isValid;
    }

    //PC_SI_55-PC_SI_58 - Method to check error and display the error message to the user
    const handleAuthFailure = (err: any) => {
        if (err.code === "UserNotFoundException" || err.message.includes("Incorrect username or password.")) {
            setLoginError({ ...loginError, errEmailId: "", errPassword: "", err: "Incorrect Email Address or Password" });
        } else if (err.code === "NotAuthorizedException") {
            setLoginError({ ...loginError, errEmailId: "", errPassword: "", err: "Sorry, you are unauthorized to access this application" });
        } else {
            setLoginError({ ...loginError, err: err.message });
        }
    }

    const handleForgotPassword = () => {
        navigate('/forgotPassword', { state: { emailId: loginDetails.emailId, pathname: window.location.pathname } });
    }

    const navigateSignup = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        navigate('/signup');
    }

    const CheckAuthRedirect = () => {
        const accessToken = localStorage.getItem('accessToken') || "";

        let decodedToken = jwtDecode(accessToken) as { [key: string]: any };
        const groups = decodedToken['cognito:groups'] || [];
        if (groups[0] === RoleEnum.ETM_ADMIN) {
            const isImpersonating = localStorage.getItem('isImpersonate') === 'true';
            if (isImpersonating) {
                navigateTo = "/loadmetrics"
            }
            else {
                navigateTo = "/tms-details"
            }
        } else {
            navigateTo = "/loadmetrics"
        }
        navigate(navigateTo)
    }
    return (
        <div className="row justify-content-center pt-5">
            <div className="col-md-10 col-sm-12 pt-4">
                <h5 className="font-26 font-bold primary-text-color mb-2">Sign In</h5>
                <p className="font-16 font-regular secondary-text-color mb-5">Please enter your details to sign in.</p>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="EmailAddress" className="form-label custom-label mb-1">Business Email Address</label>
                            <input type="text" name="emailId" className="form-control custom-form-input" id="EmailAddress" value={loginDetails.emailId} placeholder="Enter Business Email Address" onChange={handleOnChange} onKeyDown={e => { if (e.key === "Enter") signIn() }} />
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{loginError.errEmailId}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label custom-label mb-1">Password</label>
                            <div className="position-relative">
                                <input type={showPassword ? "text" : "password"} name="password" className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2" id="password" value={loginDetails.password} placeholder="Enter Password" onChange={handleOnChange} onKeyDown={e => { if (e.key === "Enter") signIn() }}/>
                                <span className={showPassword ? "position-absolute eye-open-icon custom-input-eye-color eye-open" : "position-absolute custom-input-eye-postion custom-input-eye-color eye-close"} onClick={() => setShowPassword(!showPassword)}></span>
                            </div>
                            <div className="mb-3 pt-2 d-flex justify-content-between align-items-center">
                                <div className="pt-1 d-flex align-items-center">
                                    <label className="required-color font-12 font-regular">{loginError.errPassword}</label>
                                </div>
                                <a href="" className="text-decoration-none link-color font-14 text-end font-medium" onClick={handleForgotPassword}>Forgot Password?</a>
                            </div>
                            {loginError.err ?
                                <div className="alert-msg p-2">
                                    <span className="required-color font-12 font-regular">{loginError.err}</span>
                                </div>
                                : <></>}
                        </div>
                    </div>
                </div>
                <button type="button" className="btn w-100 primary-btn mt-4 mb-2" onClick={signIn}>Sign In</button>
                {location?.pathname === "/admin" ?
                    <p className="text-center font-14 font-regular primary-text-color mt-3 mb-lg-0 mb-4">Need an account? <a href="" className="text-decoration-none link-color font-14 font-medium" onClick={(e) => navigateSignup(e)}>Sign Up</a></p> : <></>}
            </div>
            {isSomethingWentWrong ? <SomethingWentWrong onClose={() => setisSomethingWentWrong(false)} /> : <></>}
        </div>
    );
}