import axios from 'axios';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router'; 

//PC_SI_32-/PC_SI_26-Method to connect API using axios 
export async function client(endpoint: String, { requestType, body, ...customConfig }: any = {},) {
  try {
    const headers: any = { 'Content-Type': process.env.REACT_APP_HEADER_CONTENT_TYPE, }; 
    
    //PC_REF_02-PC_REF_10 
    let expirationSeconds: any;
    if (localStorage.getItem('accessTokenExpiration') != null) {
      expirationSeconds = localStorage.getItem('accessTokenExpiration')
    }

    var CurrentTime = new Date();
    const expirationDate = new Date(expirationSeconds * 1000); // Multiply by 1000 to convert seconds to milliseconds 

    if (CurrentTime < expirationDate) {
      headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    } else {
      const newAccessToken = await refreshAccessToken();
      headers.Authorization = `Bearer ${newAccessToken}`;
    }

    const requestConfig: any = { method: requestType, ...customConfig, headers: { ...headers, ...customConfig.headers } };
    if (body) {
      requestConfig.data = JSON.stringify(body);
    }
    const url = process.env.REACT_APP_BASE_URL + `${endpoint}`;
    const apiResponse = await axios(url, requestConfig).catch((error: any) => {
      console.log(error);
      if (error?.response?.status != 200) {
        console.log(error); return error?.response
      }
    }); return apiResponse;
  } catch (error) { console.log(error) }
}

 //PC_REF_11-PC_REF_37 
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    console.error('Refresh token not found');
    return;
  }
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const userPoolId = process.env.REACT_APP_USER_POOL_ID;
  const username = localStorage.getItem("emailId")
  if (!clientId || !userPoolId || !username) {
    console.error('Cognito client ID, user pool ID, or username not found');
    return;
  }
  const authData = { ClientId: clientId, UserPoolId: userPoolId, };
  const refreshSession = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: refreshToken, });
  const userData = { Username: username, Pool: new AmazonCognitoIdentity.CognitoUserPool(authData), };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(refreshSession, (err, session) => {
      if (err) {
        if (err.code === 'NotAuthorizedException' || err.message.includes('Refresh Token has expired')) {
          console.error('Refresh token has expired');
          cognitoUser.signOut(); localStorage.clear()
          const navigate = useNavigate(); // Get the navigate function from React Router 
          navigate('/'); // Replace '/signin' with the actual path to your sign-in screen
        } else {
          console.error('Error refreshing session:', err);
        }
        reject(err);
      }
      else {
        const newAccessToken = session.getAccessToken().getJwtToken();
        const newExpiration = session.getAccessToken().getExpiration();
        const refreshToken = session.getRefreshToken().getToken()
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('accessTokenExpiration', newExpiration.toString());
        localStorage.setItem("refreshToken", refreshToken)
        resolve(newAccessToken);
      }
    });
  });
};