
//PS_SP_1.0-importing the pacakge
import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { EventsStatus, eventType, StopType, TrackingEventCodes } from "../../../enums/enums";
import { Events, ShippmentProps, distanceData, StopLocations } from "../../../interface/LoadInterface";
import { getDistances, getLatLng } from "../../../utils/map_utils";
import { calculateTimeDifference, formatDuration, getTimestampToDate, getTimeZoneAbbreviation } from "../../../utils/Formatting";
import EventPopup from "../../Resuables/EventPopUp";
import moment from "moment-timezone";
import { initializeTooltips } from "../../../utils/utils";
//PS_SP_1.4-SQ_SP_1.6 -pass the props value
const Shippment: React.FC<ShippmentProps> = ({ shippmentPayload, trackingEvents, openToast, loadId, currentStopId, onError, getLoads, openLoader, closeLoader }) => {
    //PS_SP_1.1-PS_SP_1.3 -variable declarition
    const [distances, setDistances] = useState<distanceData[]>([]);
    let [totalMile, setTotalMiles] = useState<string>();
    let [remainingMile, setRemainingMiles] = useState<string>();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
        libraries: ["places"]
    })
    const [eventDetails, setEventDetails] = useState<Events>({
        loadId: 0,
        tmsId: 0,
        trackingeventId: 0,
        eventType: '',
        event: '',
        eventCode:'',
        inTime: '',
        outTime: '',
        notes: '',
        isManual: true,
        reasonCode: '',
        reasonForDelay: '',
        addressLine1: '',
        city: '',
        state: '',
        onTime: true
    })
    let shippementDetails = shippmentPayload?.sort((a, b) => {
        // PS_SP_1.7 Assuming `stopSequenceNumber` is a property of the `DistanceData` type to sort by
        return a.stop_sequence_number - b.stop_sequence_number;
    });
    const [stopLocations, setStopLocation] = useState<StopLocations[]>(shippementDetails)
    const [eventPopup, setEventPopup] = useState<boolean>(false);

    // Assign the `shippementDetails` variable from the props
    // Sort the `stopLocations` array by `stopSequenceNumber` in ascending order


    useEffect(() => {
        const handleEscape = (event: any) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        document.addEventListener("keydown", handleEscape);

        return () => {
            document.removeEventListener("keydown", handleEscape);
        };
    }, []);

    useEffect(() => {
        //PS_SP_1.8
        if (shippementDetails?.length > 0 && isLoaded) {

            calculateDistances(shippementDetails);
        }
        initializeTooltips();
    }, [shippementDetails]);

    // Define a calculateDistances method (not explicitly mentioned in pseudocode, but implied)  
    const calculateDistances = async (shippementDetails: StopLocations[]) => {
        try {
            if (!trackingEvents || trackingEvents.length === 0) {
                // If no tracking events, proceed with the original logic
                const enrichedStops = await Promise.all(shippementDetails?.map(async shippementDetails => {
                    const { address_line1, city, state, country } = shippementDetails;
                    const { latitude, longitude } = await getLatLng(address_line1, city, state, country);
                    return { ...shippementDetails, latitude, longitude };
                }));

                shippementDetails.forEach((detail, index) => {
                    detail.latitude = enrichedStops[index]?.latitude;
                    detail.longitude = enrichedStops[index]?.longitude;
                });
            } else {
                // If tracking events are present
                for (let i = 0; i < shippementDetails?.length; i++) {
                    const stop = shippementDetails[i];
                    const trackingEvent = trackingEvents
                        .filter(event => event.stop_id === stop.stop_id && event.event_type === eventType.LocationEvent)
                        .sort((a, b) => new Date(b.event_timestamp).getTime() - new Date(a.event_timestamp).getTime())[0];
                    if (trackingEvent) {
                        stop.latitude = parseFloat(trackingEvent.latitude);
                        stop.longitude = parseFloat(trackingEvent.longitude);
                    } else {
                        const { latitude, longitude } = await getLatLng(stop.address_line1, stop.city, stop.state, stop.country);
                        stop.latitude = latitude;
                        stop.longitude = longitude;
                    }
                }
            }

            setStopLocation(shippementDetails);
            const distances = await getDistances(shippementDetails);
            setDistances(distances);

            const totalMile = calculateTotalMiles(distances);
            setTotalMiles(totalMile);

            calculateAndSetRemainingMiles(shippementDetails, distances, totalMile);
        } catch (error) {
            onError()
        }
    };

    const calculateTotalMiles = (distances: distanceData[]): string => {
        let totalMiles = 0;
        distances.forEach((item) => {
            const distanceStr = item?.distance.replace(/,/g, '');
            const distance = parseFloat(distanceStr.split(' ')[0]);
            totalMiles += distance;
        });

        let totalMile = (totalMiles || 0 * 10).toFixed(1);
        return totalMile !== "0.0" ? totalMile : "";
    };

    const calculateAndSetRemainingMiles = (shippementDetails: StopLocations[], distances: distanceData[], totalMile: string) => {
        const currentStopIndex = shippementDetails.findIndex(stop => stop.stop_id === currentStopId);

        if (currentStopIndex === -1) {
            setRemainingMiles(totalMile);
        } else {
            let remainingMiles = 0;
            for (let i = currentStopIndex; i < distances?.length; i++) {
                const distanceStr = distances[i]?.distance?.replace(/,/g, '');
                const distance = parseFloat(distanceStr?.split(" ")[0]);
                if (!isNaN(distance)) {
                    remainingMiles += distance;
                }
            }
            const remainingMile = (remainingMiles || 0 * 10).toFixed(1);
            if (remainingMile !== "0.0") {
                setRemainingMiles(remainingMile);
            } else {
                setRemainingMiles("");
            }
        }
    };

    const handleEventPop = (index: number, event: string) => {

        //PS_SP_1.30-PS_SP_1.31
        const stopDetails = stopLocations[index]
        const matchingEventCode = Object.entries(TrackingEventCodes).find(
            ([status, _]) => event.includes(status)
        );
        const tmsID = localStorage.getItem('tmsId');
        const events: Events = {
            loadId: loadId || 0,
            tmsId: tmsID ? parseInt(tmsID) : 0,
            eventType: eventType.trackingEvent,
            event: event,
            eventCode:  matchingEventCode ? matchingEventCode[1] : event,
            stopId: stopDetails.stop_id,
            inTime: '',
            outTime: '',
            notes: '',
            reasonCode: '',
            reasonForDelay: '',
            addressLine1: stopDetails.address_line1,
            addressLine2: stopDetails.address_line2,
            isManual: true,
            city: stopDetails.city || '',
            state: stopDetails.state || '',
            onTime: true
        }
        setEventDetails(events)
        setEventPopup(true);
    }
    const onClose = () => {
        setEventPopup(false);
    }

    const shouldApplyDeliveredClass = (stop: StopLocations, index: number) => {
        const lastCompletedStopIndex = stopLocations.reduceRight((acc, curr, idx) => {
            if (acc === -1 && (curr.stop_in_time_utc || curr.stop_out_time_utc)) {
                return idx;
            }
            return acc;
        }, -1);

        return index <= lastCompletedStopIndex;
    };

    return (
        //PS_SP_1.32-PS_SP_1.45 - return statement's

        <>
            {
                eventPopup && <EventPopup openLoader={openLoader} closeLoader={closeLoader} events={eventDetails} updateEvent={false} onClose={onClose} openToast={openToast} getLoads={getLoads} onError={onError} />
            }
            <div className="col-md-12 mb-3">
                <div className="content-container tracking-details">
                    <div
                        className="d-flex align-items-center justify-content-between pb-3 button-group-border mb-4">
                        <div className="d-flex tracking-details-item">
                            <span className="me-2"><img src="/images/navigation-icon.svg"
                                alt="navigation-icon" /></span>
                            <div>
                                <h6 className="font-10 font-semibold mb-1 primary-text-color">Current
                                    Location</h6>
                                <p className="font-10 font-regular mb-0 primary-text-color">
                                    {currentStopId && stopLocations.find(stop => stop.stop_id === currentStopId)
                                        ? `${stopLocations.find(stop => stop.stop_id === currentStopId)?.city}, ${stopLocations.find(stop => stop.stop_id === currentStopId)?.state}`
                                        : "N/A"}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex tracking-details-item">
                            <span className="me-2"><img src="/images/total-miles-icon.svg"
                                alt="navigation-icon" /></span>
                            <div>
                                <h6 className="font-10 font-semibold mb-1 primary-text-color">Total
                                    Miles</h6>
                                <p className="font-10 font-regular mb-0 primary-text-color">  {totalMile ? (
                                    `${totalMile} mi`
                                ) : (
                                    "N/A"
                                )}</p>
                            </div>
                        </div>
                        <div className="d-flex tracking-details-item">
                            <span className="me-2"><img src="/images/remaining-miles-icon.svg"
                                alt="navigation-icon" /></span>
                            <div>
                                <h6 className="font-10 font-semibold mb-1 primary-text-color">Remaining
                                    Miles</h6>
                                <p className="font-10 font-regular mb-0 primary-text-color">
                                    {remainingMile ? (
                                        `${remainingMile} mi`
                                    ) : (
                                        "N/A"
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 audit-popup-scroll">
                        {!shippementDetails || shippementDetails?.length <= 1 ? (<div className="d-flex w-100 justify-content-center">
                            <div className="text-center py-3 mt-3">
                                <span className="no-records-found" />
                                <h5 className="font-13 font-semibold mt-2 color-black-v1">No Data Found</h5>
                            </div>
                        </div>) : (
                            <><div className="col-3 col-md-1 p-0">
                                <div className="d-flex flex-column justify-content-around h-100">
                                    {stopLocations.slice(0, -1).map((stop, index) => (
                                        <div key={index} className="d-flex flex-column">
                                            <span className="font-12 font-semibold primary-text-color d-inline-flex align-items-baseline">
                                                <img src="/images/flag-icon.svg" alt="flag-icon" className="me-1" />
                                                {distances[index]?.distance || "N/A"}
                                            </span>
                                            <span className="font-12 font-regular table-text-color d-inline-flex align-items-baseline">
                                                <img src="/images/clock-small-icon.svg" alt="clock-icon" className="me-1" />
                                                {stop.stop_eta_utc
                                                    ? formatDuration(calculateTimeDifference(stop.stop_eta_utc))
                                                    : distances[index]?.duration
                                                        ? formatDuration(distances[index].duration)
                                                        : "N/A"
                                                }
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div><div className="col-md-10 p-0">
                                    <div className="d-flex flex-column gap-5 justify-content-center ms-2">
                                        {
                                            stopLocations.map((stop, index) => (
                                                <div
                                                    key={index}
                                                    className="position-relative d-flex justify-content-between align-items-start"
                                                >
                                                    <div className={`d-flex align-items-start gap-2 ${index === stopLocations.length - 1 ? '' : 'thread'
                                                        }`}> {/*remove thread*/}
                                                        {stop.stop_type.toUpperCase() === StopType.DROP ? (
                                                            <span className="tracking-status drop d-inline-flex mt-1">
                                                                <img
                                                                    src="/images/location-icon.svg"
                                                                    alt="location"
                                                                    className={shouldApplyDeliveredClass(stop, index) ? 'item-delivered' : ''}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className={`tracking-status ${shouldApplyDeliveredClass(stop, index) ? 'success' : 'default'
                                                                    } completed d-inline-block mt-1`}
                                                            ></span>
                                                        )}
                                                        <div className="d-flex flex-column align-items-start">
                                                            <div className="d-flex justify-content-center align-items-baseline">
                                                                <span className="d-inline-flex justify-content-center align-items-center">
                                                                    {stop.stop_type.toUpperCase() == StopType.DROP ? (
                                                                        <p className="font-14 font-semibold primary-text-color pe-2 mb-0">
                                                                            Drop
                                                                        </p>
                                                                    ) : (
                                                                        <p className="font-14 font-semibold primary-text-color pe-2 mb-0">
                                                                            Pick
                                                                        </p>
                                                                    )
                                                                    }
                                                                    <span className="element-seperator me-2"></span>
                                                                </span>
                                                                <span className="d-inline-flex justify-content-center align-items-center">
                                                                    <p className="font-12 font-regular primary-text-color pe-2 mb-0">
                                                                        {stop?.time_zone ? `${moment.utc(stop?.earliest_stop_time).tz(stop?.time_zone).format('HH:mm')}` : `${moment.utc(stop?.earliest_stop_time).format('HH:mm')}`}
                                                                    </p>
                                                                    <span className="element-seperator me-2"></span>
                                                                </span>
                                                                <p className="font-12 font-regular table-text-color pe-2 button-group-border-right mb-0" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title={
                                                                    stop?.time_zone
                                                                        ? `${moment.utc(stop?.earliest_stop_time).tz(stop?.time_zone).format('MM/DD/YYYY HH:mm')} ${getTimeZoneAbbreviation(stop?.time_zone)}`
                                                                        : `${moment.utc(stop?.earliest_stop_time).format('MM/DD/YYYY HH:mm')}`}>
                                                                    {stop?.time_zone ? `${moment.utc(stop?.earliest_stop_time).tz(stop?.time_zone).format('HH:mm')}` : `${moment.utc(stop?.earliest_stop_time).format('MM/DD')}`}
                                                                    {stop?.time_zone ? ` ${getTimeZoneAbbreviation(stop?.time_zone)}` : ""}
                                                                </p>
                                                                <p className="font-12 font-regular form-text-color ps-2 mb-0 ">
                                                                    Reference ID :{" "}
                                                                    <span className="font-12 font-regular primary-text-color ps-1" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title={stop?.stop_reference_number.length > 14 ? `${stop?.stop_reference_number}` : ""}>
                                                                        {stop?.stop_reference_number.length > 14
                                                                            ? `${stop.stop_reference_number.substring(0, 14)}...`
                                                                            : `${stop.stop_reference_number}`}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <p className="font-12 font-regular primary-text-color pe-2 mb-0">
                                                                {stop?.address_line1}, {stop?.city}, {stop?.state} {stop?.postal_code}.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {index === 0 ? (
                                                        <>
                                                            <span className="custom-shippment-button">
                                                                <span className="button-group-border-right pe-1">
                                                                    <a

                                                                        className="link-color font-14 font-regular text-decoration-none cursor-pointer"
                                                                        onClick={() => handleEventPop(index, EventsStatus.ArriverdAtCustomer)}
                                                                    >
                                                                        Arrived
                                                                    </a>
                                                                </span>
                                                                <span className="ps-1">
                                                                    <a

                                                                        className="link-color font-14 font-regular text-decoration-none cursor-pointer"
                                                                        onClick={() => handleEventPop(index, EventsStatus.MarkAsLoaded)}
                                                                    >
                                                                        Loaded
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </>
                                                    ) : index === stopLocations?.length - 1 ? (
                                                        <>
                                                            <span>
                                                                <span className="button-group-border-right pe-1">
                                                                    <a

                                                                        className="link-color font-14 font-regular text-decoration-none cursor-pointer"
                                                                        onClick={() => handleEventPop(index, EventsStatus.ArrivedAtConsignee)}
                                                                    >
                                                                        Arrived
                                                                    </a>
                                                                </span>
                                                                <span className="ps-1">
                                                                    <a

                                                                        className="link-color font-14 font-regular text-decoration-none cursor-pointer "
                                                                        onClick={() => handleEventPop(index, EventsStatus.Delivered)}
                                                                    >
                                                                        Delivered
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="custom-shippment-button">

                                                                <span className="button-group-border-right pe-1">
                                                                    <a

                                                                        className="link-color font-14 font-regular text-decoration-none cursor-pointer"
                                                                        onClick={() => handleEventPop(index, EventsStatus.ArrivedAtStop)}
                                                                    >
                                                                        Arrived
                                                                    </a>
                                                                </span>
                                                                <span className="ps-1">
                                                                    <a

                                                                        className="link-color font-14 font-regular text-decoration-none cursor-pointer"
                                                                        onClick={() => handleEventPop(index, EventsStatus.MarkAsLoaded)}
                                                                    >
                                                                        Loaded
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                </div></>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Shippment;